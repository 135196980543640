let userId = localStorage.getItem('userId')

export const startLoading = () => {
    return {
        type: 'startLoading'
    }
}

export const successLoading = () => {
    return {
        type: 'successLoading'
    }
}

const successSignUp = (payload) => {
    return {
        type: 'successSignUp',
        payload,
    }
}

const successSignIn = (payload) => {
    return {
        type: 'successSignIn',
        payload,
    }
}

const failSignIn = (payload) => {
    return {
        type: 'failSignIn',
        payload,
    }
}

const restore = (payload) => {
    return {
        type: 'restore',
        payload,
    }
}

const failRegister = () => {
    return {
        type: 'failRegister',
    }
}

const failEmail = () => {
    return {
        type: 'failEmail',
    }
}

const failCode = () => {
    return {
        type: 'failCode',
    }
}

export const failPassword = () => {
    return {
        type: 'failPassword',
    }
}

const code = (payload) => {
    return {
        type: 'code',
        payload,
    }
}

const password = (payload) => {
    return {
        type: 'password',
        payload,
    }
}

const handleSignUp = (payload) => {
    return {
        type: 'handleSignUp',
        payload
    }
}

const repeat = (payload) => {
    return {
        type: 'repeat',
        payload
    }
}

export const signUp = (data) => {
    console.log('DATA --->>>', data);

    if (data.email) {
        localStorage.setItem('email', data.email)
        localStorage.removeItem('number')
    } else {
        localStorage.setItem('number', data.number)
        localStorage.removeItem('email')
    }
    
    return async (dispatch) => {
        dispatch(startLoading());
        await fetch(`${process.env.REACT_APP_HOSTNAME}/registration`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data),
        })
            .then((data) => data.json())
            .then(res => {
                dispatch(successLoading())
                dispatch(successSignUp(res))
            })
            .catch(err => {
                dispatch(successLoading())
                console.log('ERROR --->>>', err.message)
            })
    }
}

export const confirmSignupForm = (data) => {
    let email = localStorage.getItem('email')
    let number = localStorage.getItem('number')
    console.log("DATA --->>>", data);
    return async (dispatch) => {
        dispatch(startLoading());
        console.log('confirmSignupForm', JSON.stringify(email ? { verified_code: data, email } : { verified_code: data, number }));
        await fetch(`${process.env.REACT_APP_HOSTNAME}/verify`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(email ? { verified_code: data, email } : { verified_code: data, number })
        })
            .then((data) => data.json())
            .then(res => {
                dispatch(successLoading())
                dispatch(handleSignUp(res))
            })
            .catch(err => {
                dispatch(successLoading())
                dispatch(failRegister())
                console.log('ERROR --->>>', err.message)
            })
    }
}

export const restorePassword = (data) => {
    return async (dispatch) => {
        dispatch(startLoading())
        await fetch(`${process.env.REACT_APP_HOSTNAME}/code-sending`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: data.includes('@') ? JSON.stringify({ email: data }) : JSON.stringify({ number: data })
        })
            .then((data) => data.json())
            .then(res => {
                dispatch(successLoading())
                dispatch(restore(res))
            })
            .catch(err => {
                dispatch(successLoading())
                dispatch(failEmail())
                console.log('ERROR --->>>', err.message)
            })
    }
}

export const sendCode = (data) => {
    console.log("DATA --->>>", data)
    return async (dispatch) => {
        dispatch(startLoading())
        await fetch(`${process.env.REACT_APP_HOSTNAME}/restore-password`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ random_int: Number(data) }),
        })
            .then((data) => data.json())
            .then(res => {
                dispatch(successLoading())
                dispatch(code(res))
            })
            .catch(err => {
                dispatch(successLoading())
                dispatch(failCode())
                console.log('ERROR --->>>', err.message)
            })
    }
}

export const updatePassword = (data) => {
    console.log("DATA --->>>", data)
    return async (dispatch) => {
        dispatch(startLoading())
        await fetch(`${process.env.REACT_APP_HOSTNAME}/update-password`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ user_id: userId, password: data }),
        })
            .then((data) => data.json())
            .then(res => {
                dispatch(successLoading())
                dispatch(password(res))
            })
            .catch(err => {
                dispatch(successLoading())
                dispatch(failPassword())
                console.log('ERROR --->>>', err.message)
            })
    }
}

export const signIn = (data) => {
    console.log('DATA --->>>', data);
    return async (dispatch) => {
        dispatch(startLoading());
        await fetch(`${process.env.REACT_APP_HOSTNAME}/login`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data),
        })
            .then((data) => data.json())
            .then(res => {
                dispatch(successLoading())
                if (res.token) {
                    dispatch(successSignIn(res))
                } else {
                    dispatch(failSignIn(res))
                }
            })
            .catch(err => {
                dispatch(successLoading())
                console.log('ERROR --->>>', err.message)
            })
    }
}

export const resetCode = () => {
    let email = localStorage.getItem('email')
    let number = localStorage.getItem('number')
    // console.log(number, email);

    return async (dispatch) => {
        dispatch(startLoading());
        console.log('resetCode', JSON.stringify(email ? { email } : { number }))
        await fetch(`${process.env.REACT_APP_HOSTNAME}/SendCodeTwo`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(email ? { email } : { number })
        })
            .then((data) => data.json())
            .then(res => {
                dispatch(successLoading())
                dispatch(repeat(res))
            })
            .catch(err => {
                dispatch(successLoading())
                console.log('ERROR --->>>', err.message)
            })
    }
}