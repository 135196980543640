import './style.css'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import { Popup } from '../../components/popup'

export const GroupMembers = () => {

    const members = [
        {
            img: require('../../public/friend1.png'),
            name: 'Мельникова Ксения',
            isAdmin: true,
            isModerator: false,
        },
        {
            img: require('../../public/friend2.png'),
            name: 'Богословский Артем',
            isAdmin: false,
            isModerator: true,
        },
        {
            img: require('../../public/friend3.png'),
            name: 'Самбикина Юлия',
            isAdmin: false,
            isModerator: true,
        },
        {
            img: require('../../public/friend4.png'),
            name: 'Иванов Алексей',
            isAdmin: false,
            isModerator: true,
        },
        {
            img: require('../../public/friend5.png'),
            name: 'Буракшаева Елизавета',
            isAdmin: false,
            isModerator: false,
        },
        {
            img: require('../../public/friend6.png'),
            name: 'Казанцева Мария',
            isAdmin: false,
            isModerator: false,
        },
        {
            img: require('../../public/friend7.png'),
            name: 'Пименов Максим',
            isAdmin: false,
            isModerator: false,
        },
        {
            img: require('../../public/friend8.png'),
            name: 'Лебедева Анастасия',
            isAdmin: false,
            isModerator: false,
        },
        {
            img: require('../../public/friend1.png'),
            name: 'Мельникова Ксения',
            isAdmin: false,
            isModerator: false,
        },
        {
            img: require('../../public/friend2.png'),
            name: 'Богословский Артем',
            isAdmin: false,
            isModerator: false,
        },
        {
            img: require('../../public/friend3.png'),
            name: 'Самбикина Юлия',
            isAdmin: false,
            isModerator: false,
        },
        {
            img: require('../../public/friend4.png'),
            name: 'Иванов Алексей',
            isAdmin: false,
            isModerator: false,
        },
        {
            img: require('../../public/friend5.png'),
            name: 'Буракшаева Елизавета',
            isAdmin: false,
            isModerator: false,
        },
        {
            img: require('../../public/friend6.png'),
            name: 'Казанцева Мария',
            isAdmin: false,
            isModerator: false,
        },
        {
            img: require('../../public/friend7.png'),
            name: 'Пименов Максим',
            isAdmin: false,
            isModerator: false,
        },
        {
            img: require('../../public/friend8.png'),
            name: 'Лебедева Анастасия',
            isAdmin: false,
            isModerator: false,
        },

    ]

   

    const [moreIcons, setMoreIcons] = useState(null)
    const [confirmMakeModerator, setConfirmMakeModerator] = useState(false)
    const [successConfirmMakeModerator, setSuccessConfirmMakeModerator] = useState(false)

    const [removeFromGroup, setRemoveFromGroup] = useState(false)
    const [successRemoveFromGroup, setSuccessRemoveFromGroup] = useState(false)

    const [banUser, setBanUser] = useState(false)
    const [successBan, setSuccessBan] = useState(false)

    const [removeModerartor, setRemoveModerator] = useState(false)
    const [successRemoveModerator, setSuccessRemoveModerator] = useState(false)


    document.body.onclick = (e) => {
        if (e.target.className !== 'isAdmin') {
            setMoreIcons(false)
        }
    }


    function confirmModerator() {
        setConfirmMakeModerator(false)
        setSuccessConfirmMakeModerator(true)
    }
    function cancle() {
        setConfirmMakeModerator(false)
    }
    function closePopup() {
        setSuccessConfirmMakeModerator(false)
        setSuccessBan(false)
        setRemoveModerator(false)
    }
    function confDelete() {
        setRemoveFromGroup(false)
        setSuccessRemoveFromGroup(true)
    }
    function cancDel() {
        setRemoveFromGroup(false)
    }
    function sucDel() {
        setSuccessRemoveFromGroup(false)
    }
    function sucBan() {
        setBanUser(false)
        setSuccessBan(true)
    }
    function canBan() {
        setBanUser(false)
    }

    function remMod() {
        setRemoveModerator(false)
        setSuccessRemoveModerator(true)
    }

    function sucRemMod() {
        setSuccessRemoveModerator(false)
    }

    return (
        <div>
            <Popup
                title='Вы действительно хотите назначить пользователя модератором?'
                button='Подтвердить'
                transparentButton='Отменить'
                transBg='none'
                onClick={confirmModerator}
                onClick2={cancle}
                showPopup={confirmMakeModerator}
                setShowPopup={setConfirmMakeModerator}
            />
            <Popup
                title='Пользователь успешно назначен модератором'
                button='Закрыть'
                onClick={closePopup}
                showPopup={successConfirmMakeModerator}
                setShowPopup={setSuccessConfirmMakeModerator}
            />

            <Popup
                title='Вы действительно хотите удалить пользователя из группы?'
                button='Удалить'
                buttonBg='#CE2028'
                transparentButton='Отменить'
                transBg='none'
                onClick={confDelete}
                onClick2={cancDel}
                showPopup={removeFromGroup}
                setShowPopup={setRemoveFromGroup}
            />

            <Popup
                title='Пользователь удалён из группы'
                button='Закрыть'
                onClick={sucDel}
                showPopup={successRemoveFromGroup}
                setShowPopup={setSuccessRemoveFromGroup}
            />

            <Popup
                title='Вы действительно хотите заблокировать участника?'
                button='Заблокировать'
                buttonBg='#CE2028'
                transparentButton='Отменить'
                transBg='none'
                onClick={sucBan}
                onClick2={canBan}
                showPopup={banUser}
                setShowPopup={setBanUser}
            />

            <Popup
                title='Пользователь заблокирован'
                button='Закрыть'
                onClick={closePopup}
                showPopup={successBan}
                setShowPopup={setSuccessBan}
            />

            <Popup
                title='Вы действительно хотите удалить пользователья из списка модераторов?'
                button='Удалить'
                buttonBg='#CE2028'
                transparentButton='Отменить'
                transBg='none'
                onClick={remMod}
                onClick2={closePopup}
                showPopup={removeModerartor}
                setShowPopup={setRemoveModerator}
            />

            <Popup
                title='Пользователь успешно удалён из списка модераторов'
                button='Закрыть'
                onClick={sucRemMod}
                showPopup={successRemoveModerator}
                setShowPopup={setSuccessRemoveModerator}
            />

            <div className='top'>
                <h1>Участники группы</h1>
            </div>
            <div className='groupMembers'>
                {
                    members.map((e, i) => (
                        <div className='eachMember' key={i}>
                            <img src={e.img} alt={e.name} style={{ width: '100%' }} />
                            <span>{e.name}</span>
                            {
                                e.isAdmin && <img src={require('../../public/isAdmin.png')} alt='admin' className='isAdmin' onClick={() => {
                                    if (moreIcons !== i) {
                                        setMoreIcons(i)
                                    } else {
                                        setMoreIcons(null)
                                    }
                                }} />
                            }
                            { 
                                e.isModerator && <img src={require('../../public/isModerator.png')} alt='Moderator' className='isModerator' />
                            }
                            <div className={moreIcons === i ? 'moreActive' : 'moreDisabled'}>
                                <ul className='ul'> 
                                    <Link to='#'><li className='li' onClick={() => setConfirmMakeModerator(true)}>Назначить модератором</li></Link>
                                    <Link to='#'><li className='li' onClick={() => setBanUser(true)}>Заблокировать</li></Link>
                                    <Link to='#'><li className='li' onClick={() => setRemoveFromGroup(true)}>Удалить из группы</li></Link>
                                    <Link to='#'><li className='li' onClick={() => setRemoveModerator(true)}>Удалить модератора</li></Link>
                                </ul>
                            </div>
                        </div>
                    ))
                }
            </div>
        </div>
    )
}