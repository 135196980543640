import './style.css';
import Context from '../../components/context/context';
import CustomizedSwitches from '../../components/switcher';
import { Link } from 'react-router-dom';
import { useContext, useState } from 'react';
import { Popup } from '../../components/popup';
import {
    addEmail,
    addPhone,
    confirmEditEmail,
    confirmEditPhone,
    editEmail,
    editPhone,
    changePassword,
    deleteAccount,
    hidenAccount,
    confirmPhoneCodes,
    confirmAddEmail,
    changeUsername
} from '../../Redux/action/settingsAction';
import { CircleLoader } from 'react-spinners'
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';

export const Settings = () => {
    const dispatch = useDispatch()
    const context = useContext(Context)
    const tablet = context.tablet

    const loading = useSelector(st => st.loginRegister.loading)
    const failures = useSelector(st => st.settingsReducer.settingsValidation)

    const [svgColor, setSvgColor] = useState(false)
    const [err, setErr] = useState(false)
    const [user, setUser] = useState({})

    // user data request
    const getUser = async () => { 
        let token = localStorage.getItem('token')

        await fetch(`${process.env.REACT_APP_HOSTNAME}/user-data`, {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json'
            }
        })
            .then(data => data.json())
            .then(res => {
                setUser(res[0])
            })
            .catch(err => console.log('ERROR --->>>', err.message))
    }

    // ADD NEW EMAIL ADDRESS
    const [email, setEmail] = useState(false)
    const [emailCode, setEmailCode] = useState(false)
    const [successEmail, setSuccessEmail] = useState(false)

    // EDIT EMAIL ADDRESS
    const [updateEmail, setUpdateEmail] = useState(false)
    const [updateEmailCode, setUpdateEmailCode] = useState(false)
    const [successUpdateEmail, setSuccessUpdateEmail] = useState(false)

    // ADD NEW PHONE NUMBER
    const [phoneNumber, setPhoneNumber] = useState(false)
    const [confirmPhoneCode, setConfirmPhoneCode] = useState(false)
    const [successPhone, setSuccessPhone] = useState(false)

    // EDIT PHONE NUMBER
    const [updatePhoneNumber, setUpdatePhoneNumber] = useState(false)
    const [updatePhoneCode, setUpdatePhoneCode] = useState(false)
    const [successPhoneCode, setSuccessPhoneCode] = useState(false)

    // EDIT PASSWORD
    const [editPassword, setEditPassword] = useState(false)
    const [successEditPassword, setSuccessEditPassword] = useState(false)

    // EDIT NAME
    const [editName, setEditName] = useState(false)
    const [successEditName, setSuccessEditName] = useState(false)

    // EDIT/HIDE PROFILE
    const [editProfile, setEditProfile] = useState(false)

    // HIDE PROFILE
    const [confirmHideProfile, setConfirmHideProfile] = useState(false)
    const [hidePassword, setHidePassword] = useState(false)
    const [successHideProfile, setSuccessHideProfile] = useState(false)

    // DELETE PROFILE
    const [confirmDFeleteProfile, setConfirmDeleteProfile] = useState(false)
    const [deletePassword, setDeletePassword] = useState(false)
    const [successDeleteProfile, setSuccessDeleteProfile] = useState(false)

    // LOGOUT
    const [logout, setLogout] = useState(false)

    useEffect(() => {
        getUser();
    }, [])

    // ADD NEW PHONE NUMBER
    async function handleClick1() {
        await dispatch(addPhone(context.phoneNumber))
        if (!failures.addPhoneNumber) {
            setPhoneNumber(false)
            setConfirmPhoneCode(true)
        }
    }
    async function handleClick2() {
        await dispatch(confirmPhoneCodes(context.confirmCode))
        if (!failures.confirmAddPhone) {
            setConfirmPhoneCode(false)
            setSuccessPhone(true)
        }
    }
    function handleClick3() {
        setSuccessPhone(false)
        window.location.reload(true)
    }

    // EDIT PHONE NUMBER
    async function handleClick4() {
        await dispatch(editPhone(context.phoneNumber))
        if (!failures.editPhoneNumber) {
            setUpdatePhoneNumber(false)
            setUpdatePhoneCode(true)
        }
    }
    async function handleClick5() {
        await dispatch(confirmEditPhone(context.confirmCode))
        if (!failures.confirmEditPhone) {
            setUpdatePhoneCode(false)
            setSuccessPhoneCode(true)
        }
    }
    function handleClick6() {
        setSuccessPhoneCode(false)
        window.location.reload(true)
    }

    // ADD NEW EMAIL ADDRESS
    async function handleClick7() {
        await dispatch(addEmail(context.newEmail))
        if (!failures.addEmail) {
            setEmailCode(true)
            setEmail(false)
        }
    }
    async function handleClick8() {
        await dispatch(confirmAddEmail(context.newEmailCode))
        if (!failures.confirmEmailCode) {
            setEmailCode(false)
            setSuccessEmail(true)
        }
    }
    function handleClick9() {
        setSuccessEmail(false)
        window.location.reload(true)
    }

    // EDIT EMAIL ADDRESS
    async function handleClick10() {
        await dispatch(editEmail(context.newEmail))
        if (!failures.confirmEmailAddress) {
            setUpdateEmail(false)
            setUpdateEmailCode(true)
        }
    }
    async function handleClick11() {
        await dispatch(confirmEditEmail(context.confirmCode))
        if (!failures.confirmEmailAddressCode) {
            setUpdateEmailCode(false)
            setSuccessUpdateEmail(true)
        }
    }
    function handleClick12() {
        setSuccessUpdateEmail(false)
        window.location.reload(true)
    }

    // EDIT USERNAME
    async function handleClick15() {
        await dispatch(changeUsername(context.userName))
        if (!failures.changeUserName) {
            setEditName(false)
            setSuccessEditName(true)
        }
    }
    function handleClick16() {
        setSuccessEditName(false)
        window.location.reload(true)
    }

    // EDIT PASSWORD
    async function handleClick13() {
        if (context.newPassword === context.confirmPassword && context.confirmPassword.length > 5) {
            await dispatch(changePassword(context.newPassword, context.oldPassword))
            setErr(false)
            if (!failures.editPasswords) {
                setEditPassword(false)
                setSuccessEditPassword(true)
            }
        } else {
            setErr(true)
        }
    }
    function handleClick14() {
        setSuccessEditPassword(false)
        window.location.reload(true)
    }

    // EDIT/DELETE PROFILE
    function handleClick17() {
        setEditProfile(false)
        setConfirmHideProfile(true)
    }
    function handleClick18() {
        setConfirmHideProfile(false)
        setHidePassword(true)
    }
    async function handleClick19() {
        await dispatch(hidenAccount(context.hideAccountPassword))
        if (!failures.hideProfile) {
            setHidePassword(false)
            setSuccessHideProfile(true)
        }
    }
    function handleClick20() {
        setSuccessHideProfile(false)
        window.location.reload(true)
    }
    function handleClick21() {
        setEditProfile(false)
        setConfirmDeleteProfile(true)
    }
    function handleClick22() {
        setConfirmDeleteProfile(false)
        setDeletePassword(true)
    }
    async function handleClick23() {
        await dispatch(deleteAccount(context.deleteAccountPassword))
        if (!failures.deleteProfile) {
            setDeletePassword(false)
            setSuccessDeleteProfile(true)
        }
    }

    function handleClick24() {
        setSuccessDeleteProfile(false)
        localStorage.clear()
        window.location = '/'
    }
    function handleClick25() {
        setConfirmHideProfile(false)
        window.location.reload(true)
    }
    function handleClick26() {
        setConfirmDeleteProfile(false)
        window.location.reload(true)
    }
    function handleClick27() {
        localStorage.clear()
        window.location = '/'
    }
    function handleClick28() {
        setLogout(false)
        window.location.reload(true)
    }

    if (loading) {
        return (
            <div className='loading'>
                <CircleLoader color="rgb(4, 142, 57)" />
            </div>
        )
    } else {
        return (
            <div>
                {/* ADD NEW EMAIL ADDRESS */}
                <Popup
                    title='Добавление электронной почты'
                    text='Мы отправим код для подтверждения личности'
                    inputFirst='Эл. почта'
                    inputFirstType='email'
                    button='Далее'
                    onClick={handleClick7}
                    showPopup={email}
                    setShowPopup={setEmail}
                    error={failures.addEmail}
                />
                <Popup
                    title='Добавление электронной почты'
                    text='Введите код подтверждения'
                    code
                    resendCode
                    onResendCode={handleClick7}
                    button='Далее'
                    onClick={handleClick8}
                    showPopup={emailCode}
                    setShowPopup={setEmailCode}
                    error={failures.confirmEmailCode}
                />
                <Popup
                    title='Ваша эл. почта успешно привязана к вашему аккаунту'
                    button='Закрыть'
                    onClick={handleClick9}
                    showPopup={successEmail}
                    setShowPopup={setSuccessEmail}
                />
                {/* EDIT EMAIL ADDRESS */}
                <Popup
                    title='Изменение привязанной эл. почты'
                    text='Мы отправим код для подтверждения'
                    inputFirst='Эл. почта'
                    inputFirstType='email'
                    button='Далее'
                    onClick={handleClick10}
                    showPopup={updateEmail}
                    setShowPopup={setUpdateEmail}
                    error={failures.confirmEmailAddress}
                />
                <Popup
                    title='Изменение привязанной эл. почты'
                    text='Введите код подтверждения'
                    code
                    resendCode
                    onResendCode={handleClick10}
                    button='Далее'
                    onClick={handleClick11}
                    showPopup={updateEmailCode}
                    setShowPopup={setUpdateEmailCode}
                    error={failures.confirmEmailAddressCode}
                />
                <Popup
                    title='Ваша эл. почта успешно изменена'
                    button='Закрыть'
                    onClick={handleClick12}
                    showPopup={successUpdateEmail}
                    setShowPopup={setSuccessUpdateEmail}
                />
                {/* ADD NEW PHONE NUMBER */}
                <Popup
                    title='Добавление телефонного номера'
                    text='Мы отправим 6-и значный код на ваш тел. номер для подтверждения личности'
                    phoneInput
                    button='Далее'
                    onClick={handleClick1}
                    showPopup={phoneNumber}
                    setShowPopup={setPhoneNumber}
                    error={failures.addPhoneNumber}
                />
                <Popup
                    title='Добавление телефонного номера'
                    text='Введите код подтверждения'
                    code
                    resendCode
                    onResendCode={handleClick1}
                    button='Далее'
                    onClick={handleClick2}
                    showPopup={confirmPhoneCode}
                    setShowPopup={setConfirmPhoneCode}
                    error={failures.confirmAddPhone}
                />
                <Popup
                    title='Ваш телефонный номер успешно привязан к вашему аккаунту'
                    button='Закрыть'
                    onClick={handleClick3}
                    showPopup={successPhone}
                    setShowPopup={setSuccessPhone}
                />
                {/* EDIT PHONE NUMBER */}
                <Popup
                    title='Изменение привязанного тел. номера'
                    text='Мы отправим код для подтверждения'
                    phoneInput
                    button='Далее'
                    onClick={handleClick4}
                    showPopup={updatePhoneNumber}
                    setShowPopup={setUpdatePhoneNumber}
                    error={failures.editPhoneNumber}
                />
                <Popup
                    title='Изменение привязанного тел. номера'
                    text='Введите код подтверждения'
                    code
                    resendCode
                    onResendCode={handleClick4}
                    button='Далее'
                    onClick={handleClick5}
                    showPopup={updatePhoneCode}
                    setShowPopup={setUpdatePhoneCode}
                    error={failures.confirmEditPhone}
                />
                <Popup
                    title='Ваш телефонный номер успешно изменен'
                    button='Закрыть'
                    onClick={handleClick6}
                    showPopup={successPhoneCode}
                    setShowPopup={setSuccessPhoneCode}
                />

                {/* EDIT PASSWORD */}
                <Popup
                    title='Изменение пароля'
                    oldPass
                    pass
                    button='Сохранить'
                    onClick={handleClick13}
                    showPopup={editPassword}
                    setShowPopup={setEditPassword}
                    error={err || failures.editPasswords}
                />
                <Popup
                    title='Ваша пароль успешно изменён'
                    button='Закрыть'
                    onClick={handleClick14}
                    showPopup={successEditPassword}
                    setShowPopup={setSuccessEditPassword}
                />

                {/* EDIT USERNAME */}
                <Popup
                    title='Изменение имени пользователя'
                    text='Введите новое имя пользователя'
                    inputFirst='Имя пользователя'
                    button='Сохранить'
                    onClick={handleClick15}
                    showPopup={editName}
                    setShowPopup={setEditName}
                    error={failures.changeUserName}
                />
                <Popup
                    title='Ваше имя пользователя успешно изменена'
                    button='Закрыть'
                    onClick={handleClick16}
                    showPopup={successEditName}
                    setShowPopup={setSuccessEditName}
                />
                {/* HIDE PROFILE */}
                <Popup
                    title='Удаление/Скрытие аккаунта'
                    text='К сожалению, сейчас мы не можем полностью удалить ваш профиль 
                по законам Российской Федерации. Ещё 180 дней мы будем бережно сохранять 
                ваш профиль на случай, если вы передумаете. В любой момент этого времени 
                вы можете вернуться обратно на портал.'
                    button='Скрыть аккаунт'
                    transparentButton='Удалить аккаунт'
                    transBorder='2px solid #CE2028'
                    transBg='#CE2028'
                    transColor='#fff'
                    onClick={handleClick17}
                    onClick2={handleClick21}
                    showPopup={editProfile}
                    setShowPopup={setEditProfile}
                />
                <Popup
                    title='Вы действительно хотите скрыть ваш аккаунт?'
                    button='Скрыть'
                    transparentButton='Отменить'
                    transBorder='2px solid #048E39'
                    transBg='none'
                    transColor='#333333'
                    onClick={handleClick18}
                    onClick2={handleClick25}
                    showPopup={confirmHideProfile}
                    setShowPopup={setConfirmHideProfile}
                />
                <Popup
                    title='Введите пароль?'
                    text='После скрытия ваш аккаунт не будет доступен другим пользователям. Вы можете вернуть ваш аккаунт в любой момент'
                    inputFirst='Пароль'
                    button='Подтвердить'
                    onClick={handleClick19}
                    showPopup={hidePassword}
                    setShowPopup={setHidePassword}
                    error={failures.hideProfile}
                />
                <Popup
                    title='Ваш аккаунт успешно скрыт'
                    button='На главную'
                    onClick={handleClick20}
                    showPopup={successHideProfile}
                    setShowPopup={setSuccessHideProfile}
                />

                {/* DELETE PROFILE */}
                <Popup
                    title='Вы действительно хотите удалить ваш аккаунт?'
                    button='Удалить'
                    transparentButton='Отменить'
                    transBorder='2px solid #333333'
                    transBg='none'
                    transColor='#333333'
                    onClick={handleClick22}
                    onClick2={handleClick26}
                    showPopup={confirmDFeleteProfile}
                    setShowPopup={setConfirmDeleteProfile}
                />
                <Popup
                    title='Введите пароль?'
                    text='После удаления ваш аккаунт не будет доступен другим пользователям. Вы не сможете вернуть ваш аккаунт'
                    delPass
                    button='Удалить'
                    onClick={handleClick23}
                    showPopup={deletePassword}
                    setShowPopup={setDeletePassword}
                    error={failures.deleteProfile}
                />
                <Popup
                    title='Ваш аккаунт успешно удалён'
                    button='На главную'
                    onClick={handleClick24}
                    showPopup={successDeleteProfile}
                    setShowPopup={setSuccessDeleteProfile}
                />
                <Popup
                    title='Вы действительно хотите выйти из аккаунта?'
                    button='Выйти'
                    transparentButton='Отменить'
                    transBorder='2px solid #048E39'
                    transBg='none'
                    transColor='#333333'
                    onClick={handleClick27}
                    onClick2={handleClick28}
                    showPopup={logout}
                    setShowPopup={setLogout}
                />

                <div className='top'>
                    <h1>Настройки Профиля</h1>
                    <div className='webProfile'>
                        <Link to='/myProfile'>Посмотреть профиль</Link>
                    </div>
                    <div className='mobileProfile'>
                        <Link to='/myProfile'>
                            <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M17.3228 12.2603C18.3367 11.2464 18.9062 9.87133 18.9062 8.4375C18.9062 7.00367 18.3367 5.62857 17.3228 4.6147C16.3089 3.60084 14.9338 3.03125 13.5 3.03125C12.0662 3.03125 10.6911 3.60084 9.6772 4.6147C8.66334 5.62857 8.09375 7.00367 8.09375 8.4375C8.09375 9.87133 8.66334 11.2464 9.6772 12.2603C10.6911 13.2742 12.0662 13.8438 13.5 13.8438C14.9338 13.8438 16.3089 13.2742 17.3228 12.2603ZM5.0625 23.9688H22.7812C22.8724 23.9688 22.9599 23.9325 23.0243 23.8681C23.0888 23.8036 23.125 23.7162 23.125 23.625V21.0938C23.125 20.1075 22.7332 19.1616 22.0358 18.4642C21.3384 17.7668 20.3925 17.375 19.4062 17.375H7.59375C6.60748 17.375 5.6616 17.7668 4.9642 18.4642C4.2668 19.1616 3.875 20.1075 3.875 21.0938V23.625C3.875 23.7162 3.91122 23.8036 3.97568 23.8681C4.04015 23.9325 4.12758 23.9688 4.21875 23.9688H5.0625Z" stroke="#333333" />
                            </svg>
                        </Link>
                    </div>
                </div>

                <div className='mainSettingsPage'>

                    <div className='firstDiv'>
                        <div className='profilePicture'>
                            <img src={require('../../public/prof_pic.png')} alt='Profle Pic' />
                        </div>
                        <div className='personalInfo'>
                            <label>Имя</label>
                            <input className='settingsInput' value={user?.name} disabled />
                            <label>Фамилия</label>
                            <input className='settingsInput' value={user?.surname} disabled />
                            <label>Отчество</label>
                            <input className='settingsInput' value={user?.patronymic} disabled />
                        </div>
                    </div>

                    <div className='secondaryDiv'>
                        <h1>Сведения профиля</h1>
                        <div className='settingsInputs'>
                            <label>Дата Рождения</label>
                            <input className='settingsLongInput mrg' value={user?.date_of_birth?.split('T')[0].split('-').reverse()[0] + '-' + user?.date_of_birth?.split('T')[0].split('-').reverse()[1] + '-' + user?.date_of_birth?.split('T')[0].split('-').reverse()[2]} disabled />
                            <label>Страна</label>
                            <input className='settingsLongInput mrg' value={user?.city} disabled />
                        </div>
                    </div>

                    <div className='secondaryDiv'>
                        <div className='loginAndPrivacy'>
                            <h1>Вход и безопасность</h1>
                            <div className='switch'>
                                <p>Статус <span>В сети</span></p>
                                <CustomizedSwitches />
                            </div>
                        </div>
                        <div className='settingsInputs'>
                            <label>Эл. почта</label>
                            <div className='inp'>
                                {
                                    user?.email &&
                                    <>
                                        <input className='settingsLongInput' type='email' value={user?.email} disabled />
                                        <button onClick={() => { setUpdateEmail(true) }}>
                                            <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M18.6875 11.5C18.6875 11.3094 18.7632 11.1266 18.898 10.9918C19.0328 10.857 19.2156 10.7812 19.4062 10.7812C19.5969 10.7812 19.7797 10.857 19.9145 10.9918C20.0493 11.1266 20.125 11.3094 20.125 11.5V19.4062C20.125 19.5969 20.0493 19.7797 19.9145 19.9145C19.7797 20.0493 19.5969 20.125 19.4062 20.125H3.59375C3.40313 20.125 3.22031 20.0493 3.08552 19.9145C2.95073 19.7797 2.875 19.5969 2.875 19.4062V3.59375C2.875 3.40313 2.95073 3.22031 3.08552 3.08552C3.22031 2.95073 3.40313 2.875 3.59375 2.875H11.5C11.6906 2.875 11.8734 2.95073 12.0082 3.08552C12.143 3.22031 12.2188 3.40313 12.2188 3.59375C12.2188 3.78437 12.143 3.96719 12.0082 4.10198C11.8734 4.23677 11.6906 4.3125 11.5 4.3125H4.3125V18.6875H18.6875V11.5Z" fill="#048E39" />
                                                <path d="M10.5553 12.4484L11.7412 12.2788L19.0265 4.995C19.0951 4.9287 19.1499 4.84939 19.1876 4.7617C19.2252 4.67401 19.2451 4.57969 19.2459 4.48426C19.2467 4.38882 19.2285 4.29418 19.1924 4.20585C19.1562 4.11751 19.1029 4.03726 19.0354 3.96978C18.9679 3.90229 18.8877 3.84892 18.7993 3.81278C18.711 3.77665 18.6163 3.75846 18.5209 3.75929C18.4255 3.76012 18.3312 3.77995 18.2435 3.81762C18.1558 3.85528 18.0765 3.91004 18.0102 3.97869L10.7235 11.2625L10.5539 12.4484H10.5553ZM20.0428 2.96094C20.2432 3.16118 20.4021 3.39895 20.5106 3.66065C20.619 3.92234 20.6748 4.20285 20.6748 4.48613C20.6748 4.7694 20.619 5.04991 20.5106 5.31161C20.4021 5.5733 20.2432 5.81107 20.0428 6.01131L12.5894 13.4647C12.4794 13.5751 12.3367 13.6467 12.1825 13.6689L9.81067 14.0081C9.70013 14.024 9.58742 14.0139 9.48146 13.9786C9.37551 13.9433 9.27923 13.8839 9.20026 13.8049C9.1213 13.7259 9.06182 13.6297 9.02655 13.5237C8.99127 13.4178 8.98117 13.305 8.99705 13.1945L9.3363 10.8226C9.35808 10.6687 9.4292 10.5259 9.53898 10.4158L16.9939 2.96238C17.3982 2.55814 17.9466 2.33105 18.5183 2.33105C19.0901 2.33105 19.6384 2.55814 20.0428 2.96238V2.96094Z" fill="#048E39" />
                                            </svg>
                                        </button>
                                    </>
                                }
                                {
                                    !tablet && !user?.email &&
                                    <>
                                        <input className='settingsLongInput' type='email' value={user?.email} disabled />
                                        <button id='addPhoneNumber' onClick={() => { setEmail(true) }}>Добавить</button>
                                    </>
                                }
                                {
                                    tablet && !user?.email &&
                                    <>
                                        <button id='addPhoneNumber' style={{ margin: '10px 0', width: '40%' }} onClick={() => { setEmail(true) }}>Добавить</button>
                                    </>
                                }

                            </div>
                            <label>Телефон</label>
                            <div className='inp'>
                                {
                                    user?.number &&
                                    <>
                                        <input className='settingsLongInput' type='email' value={user?.number} disabled />
                                        <button onClick={() => { setUpdatePhoneNumber(true) }}>
                                            <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M18.6875 11.5C18.6875 11.3094 18.7632 11.1266 18.898 10.9918C19.0328 10.857 19.2156 10.7812 19.4062 10.7812C19.5969 10.7812 19.7797 10.857 19.9145 10.9918C20.0493 11.1266 20.125 11.3094 20.125 11.5V19.4062C20.125 19.5969 20.0493 19.7797 19.9145 19.9145C19.7797 20.0493 19.5969 20.125 19.4062 20.125H3.59375C3.40313 20.125 3.22031 20.0493 3.08552 19.9145C2.95073 19.7797 2.875 19.5969 2.875 19.4062V3.59375C2.875 3.40313 2.95073 3.22031 3.08552 3.08552C3.22031 2.95073 3.40313 2.875 3.59375 2.875H11.5C11.6906 2.875 11.8734 2.95073 12.0082 3.08552C12.143 3.22031 12.2188 3.40313 12.2188 3.59375C12.2188 3.78437 12.143 3.96719 12.0082 4.10198C11.8734 4.23677 11.6906 4.3125 11.5 4.3125H4.3125V18.6875H18.6875V11.5Z" fill="#048E39" />
                                                <path d="M10.5553 12.4484L11.7412 12.2788L19.0265 4.995C19.0951 4.9287 19.1499 4.84939 19.1876 4.7617C19.2252 4.67401 19.2451 4.57969 19.2459 4.48426C19.2467 4.38882 19.2285 4.29418 19.1924 4.20585C19.1562 4.11751 19.1029 4.03726 19.0354 3.96978C18.9679 3.90229 18.8877 3.84892 18.7993 3.81278C18.711 3.77665 18.6163 3.75846 18.5209 3.75929C18.4255 3.76012 18.3312 3.77995 18.2435 3.81762C18.1558 3.85528 18.0765 3.91004 18.0102 3.97869L10.7235 11.2625L10.5539 12.4484H10.5553ZM20.0428 2.96094C20.2432 3.16118 20.4021 3.39895 20.5106 3.66065C20.619 3.92234 20.6748 4.20285 20.6748 4.48613C20.6748 4.7694 20.619 5.04991 20.5106 5.31161C20.4021 5.5733 20.2432 5.81107 20.0428 6.01131L12.5894 13.4647C12.4794 13.5751 12.3367 13.6467 12.1825 13.6689L9.81067 14.0081C9.70013 14.024 9.58742 14.0139 9.48146 13.9786C9.37551 13.9433 9.27923 13.8839 9.20026 13.8049C9.1213 13.7259 9.06182 13.6297 9.02655 13.5237C8.99127 13.4178 8.98117 13.305 8.99705 13.1945L9.3363 10.8226C9.35808 10.6687 9.4292 10.5259 9.53898 10.4158L16.9939 2.96238C17.3982 2.55814 17.9466 2.33105 18.5183 2.33105C19.0901 2.33105 19.6384 2.55814 20.0428 2.96238V2.96094Z" fill="#048E39" />
                                            </svg>
                                        </button>
                                    </>
                                }
                                {
                                    !tablet && !user?.number &&
                                    <>
                                        <input className='settingsLongInput' type='email' value={user?.number} disabled />
                                        <button id='addPhoneNumber' onClick={() => { setPhoneNumber(true) }}>Добавить</button>
                                    </>
                                }
                                {
                                    tablet && !user?.number &&
                                    <>
                                        <button id='addPhoneNumber' style={{ margin: '10px 0', width: '40%' }} onClick={() => { setEmail(true) }}>Добавить</button>
                                    </>
                                }
                            </div>
                            <label>Имя пользователя</label>
                            <div className='inp'>
                                <input className='settingsLongInput' value={user?.username} disabled />
                                <button onClick={() => setEditName(true)} >
                                    <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M18.6875 11.5C18.6875 11.3094 18.7632 11.1266 18.898 10.9918C19.0328 10.857 19.2156 10.7812 19.4062 10.7812C19.5969 10.7812 19.7797 10.857 19.9145 10.9918C20.0493 11.1266 20.125 11.3094 20.125 11.5V19.4062C20.125 19.5969 20.0493 19.7797 19.9145 19.9145C19.7797 20.0493 19.5969 20.125 19.4062 20.125H3.59375C3.40313 20.125 3.22031 20.0493 3.08552 19.9145C2.95073 19.7797 2.875 19.5969 2.875 19.4062V3.59375C2.875 3.40313 2.95073 3.22031 3.08552 3.08552C3.22031 2.95073 3.40313 2.875 3.59375 2.875H11.5C11.6906 2.875 11.8734 2.95073 12.0082 3.08552C12.143 3.22031 12.2188 3.40313 12.2188 3.59375C12.2188 3.78437 12.143 3.96719 12.0082 4.10198C11.8734 4.23677 11.6906 4.3125 11.5 4.3125H4.3125V18.6875H18.6875V11.5Z" fill="#048E39" />
                                        <path d="M10.5553 12.4484L11.7412 12.2788L19.0265 4.995C19.0951 4.9287 19.1499 4.84939 19.1876 4.7617C19.2252 4.67401 19.2451 4.57969 19.2459 4.48426C19.2467 4.38882 19.2285 4.29418 19.1924 4.20585C19.1562 4.11751 19.1029 4.03726 19.0354 3.96978C18.9679 3.90229 18.8877 3.84892 18.7993 3.81278C18.711 3.77665 18.6163 3.75846 18.5209 3.75929C18.4255 3.76012 18.3312 3.77995 18.2435 3.81762C18.1558 3.85528 18.0765 3.91004 18.0102 3.97869L10.7235 11.2625L10.5539 12.4484H10.5553ZM20.0428 2.96094C20.2432 3.16118 20.4021 3.39895 20.5106 3.66065C20.619 3.92234 20.6748 4.20285 20.6748 4.48613C20.6748 4.7694 20.619 5.04991 20.5106 5.31161C20.4021 5.5733 20.2432 5.81107 20.0428 6.01131L12.5894 13.4647C12.4794 13.5751 12.3367 13.6467 12.1825 13.6689L9.81067 14.0081C9.70013 14.024 9.58742 14.0139 9.48146 13.9786C9.37551 13.9433 9.27923 13.8839 9.20026 13.8049C9.1213 13.7259 9.06182 13.6297 9.02655 13.5237C8.99127 13.4178 8.98117 13.305 8.99705 13.1945L9.3363 10.8226C9.35808 10.6687 9.4292 10.5259 9.53898 10.4158L16.9939 2.96238C17.3982 2.55814 17.9466 2.33105 18.5183 2.33105C19.0901 2.33105 19.6384 2.55814 20.0428 2.96238V2.96094Z" fill="#048E39" />
                                    </svg>
                                </button>
                            </div>
                            <label>Пароль</label>
                            <div className='inp'>
                                <input className='settingsLongInput' type='password' value={'*********'} disabled />
                                <button onClick={() => setEditPassword(true)}>
                                    <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M18.6875 11.5C18.6875 11.3094 18.7632 11.1266 18.898 10.9918C19.0328 10.857 19.2156 10.7812 19.4062 10.7812C19.5969 10.7812 19.7797 10.857 19.9145 10.9918C20.0493 11.1266 20.125 11.3094 20.125 11.5V19.4062C20.125 19.5969 20.0493 19.7797 19.9145 19.9145C19.7797 20.0493 19.5969 20.125 19.4062 20.125H3.59375C3.40313 20.125 3.22031 20.0493 3.08552 19.9145C2.95073 19.7797 2.875 19.5969 2.875 19.4062V3.59375C2.875 3.40313 2.95073 3.22031 3.08552 3.08552C3.22031 2.95073 3.40313 2.875 3.59375 2.875H11.5C11.6906 2.875 11.8734 2.95073 12.0082 3.08552C12.143 3.22031 12.2188 3.40313 12.2188 3.59375C12.2188 3.78437 12.143 3.96719 12.0082 4.10198C11.8734 4.23677 11.6906 4.3125 11.5 4.3125H4.3125V18.6875H18.6875V11.5Z" fill="#048E39" />
                                        <path d="M10.5553 12.4484L11.7412 12.2788L19.0265 4.995C19.0951 4.9287 19.1499 4.84939 19.1876 4.7617C19.2252 4.67401 19.2451 4.57969 19.2459 4.48426C19.2467 4.38882 19.2285 4.29418 19.1924 4.20585C19.1562 4.11751 19.1029 4.03726 19.0354 3.96978C18.9679 3.90229 18.8877 3.84892 18.7993 3.81278C18.711 3.77665 18.6163 3.75846 18.5209 3.75929C18.4255 3.76012 18.3312 3.77995 18.2435 3.81762C18.1558 3.85528 18.0765 3.91004 18.0102 3.97869L10.7235 11.2625L10.5539 12.4484H10.5553ZM20.0428 2.96094C20.2432 3.16118 20.4021 3.39895 20.5106 3.66065C20.619 3.92234 20.6748 4.20285 20.6748 4.48613C20.6748 4.7694 20.619 5.04991 20.5106 5.31161C20.4021 5.5733 20.2432 5.81107 20.0428 6.01131L12.5894 13.4647C12.4794 13.5751 12.3367 13.6467 12.1825 13.6689L9.81067 14.0081C9.70013 14.024 9.58742 14.0139 9.48146 13.9786C9.37551 13.9433 9.27923 13.8839 9.20026 13.8049C9.1213 13.7259 9.06182 13.6297 9.02655 13.5237C8.99127 13.4178 8.98117 13.305 8.99705 13.1945L9.3363 10.8226C9.35808 10.6687 9.4292 10.5259 9.53898 10.4158L16.9939 2.96238C17.3982 2.55814 17.9466 2.33105 18.5183 2.33105C19.0901 2.33105 19.6384 2.55814 20.0428 2.96238V2.96094Z" fill="#048E39" />
                                    </svg>
                                </button>
                            </div>
                        </div>
                    </div>

                    <div className='hideProfile'>
                        <Link onClick={() => setEditProfile(true)}>Удалить / скрыть профиль</Link>
                        <button onClick={() => setLogout(true)}
                            onMouseLeave={() => {
                                setSvgColor(false)
                            }}
                            onMouseOver={() => {
                                setSvgColor(true)
                            }}
                        >
                            <span>Выйти</span>
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12.5 17.001C12.6326 17.001 12.7598 16.9483 12.8536 16.8545C12.9473 16.7608 13 16.6336 13 16.501C13 16.3684 12.9473 16.2412 12.8536 16.1474C12.7598 16.0537 12.6326 16.001 12.5 16.001H6C5.46957 16.001 4.96086 15.7903 4.58579 15.4152C4.21071 15.0401 4 14.5314 4 14.001V6.00098C4 5.47054 4.21071 4.96184 4.58579 4.58676C4.96086 4.21169 5.46957 4.00098 6 4.00098H12.5C12.6326 4.00098 12.7598 3.9483 12.8536 3.85453C12.9473 3.76076 13 3.63358 13 3.50098C13 3.36837 12.9473 3.24119 12.8536 3.14742C12.7598 3.05366 12.6326 3.00098 12.5 3.00098H6C5.20435 3.00098 4.44129 3.31705 3.87868 3.87966C3.31607 4.44227 3 5.20533 3 6.00098V14.001C3 14.7966 3.31607 15.5597 3.87868 16.1223C4.44129 16.6849 5.20435 17.001 6 17.001H12.5ZM13.646 6.14698C13.6924 6.10041 13.7476 6.06347 13.8084 6.03826C13.8691 6.01306 13.9342 6.00008 14 6.00008C14.0658 6.00008 14.1309 6.01306 14.1916 6.03826C14.2524 6.06347 14.3076 6.10041 14.354 6.14698L17.854 9.64698C17.9006 9.69342 17.9375 9.7486 17.9627 9.80934C17.9879 9.87009 18.0009 9.93521 18.0009 10.001C18.0009 10.0667 17.9879 10.1319 17.9627 10.1926C17.9375 10.2534 17.9006 10.3085 17.854 10.355L14.354 13.855C14.3075 13.9015 14.2523 13.9383 14.1916 13.9635C14.1308 13.9887 14.0657 14.0016 14 14.0016C13.9343 14.0016 13.8692 13.9887 13.8084 13.9635C13.7477 13.9383 13.6925 13.9015 13.646 13.855C13.5995 13.8085 13.5626 13.7533 13.5375 13.6926C13.5123 13.6318 13.4994 13.5667 13.4994 13.501C13.4994 13.4352 13.5123 13.3701 13.5375 13.3094C13.5626 13.2487 13.5995 13.1935 13.646 13.147L16.293 10.501H7.5C7.36739 10.501 7.24022 10.4483 7.14645 10.3545C7.05268 10.2608 7 10.1336 7 10.001C7 9.86837 7.05268 9.74119 7.14645 9.64742C7.24022 9.55365 7.36739 9.50098 7.5 9.50098H16.293L13.646 6.85498C13.5994 6.80853 13.5625 6.75336 13.5373 6.69261C13.5121 6.63187 13.4991 6.56674 13.4991 6.50098C13.4991 6.43521 13.5121 6.37009 13.5373 6.30934C13.5625 6.2486 13.5994 6.19342 13.646 6.14698Z" fill={svgColor ? '#fff' : '#333333'} />
                            </svg>
                        </button>
                    </div>

                </div>

            </div >
        )
    }
}  