import signUp from "../store/signUp";

export const loginRegister = (state = signUp, action) => {
    let temp = { ...state }
    switch (action.type) {
        case 'startLoading':
            temp.loading = true;
            break;

        case 'successLoading':
            temp.loading = false;
            break;

        case 'successSignUp':
            console.log('PAYLOAD --->>>', action.payload);
            if (action.payload.success === true) {
                temp.registerValidation.openPopup = true
                temp.registerValidation.confirmCode = false
            } else {
                temp.registerValidation.city = action.payload.city ? action.payload.city[0] : false
                temp.registerValidation.date_of_birth = action.payload.date_of_birth ? action.payload.date_of_birth[0] : false
                temp.registerValidation.name = action.payload.name ? action.payload.name[0] : false
                temp.registerValidation.password = action.payload.password ? action.payload.password[0] : false
                temp.registerValidation.password_confirmation = action.payload.password_confirmation ? action.payload.password_confirmation[0] : false
                temp.registerValidation.patronymic = action.payload.patronymic ? action.payload.patronymic[0] : false
                temp.registerValidation.surname = action.payload.surname ? action.payload.surname[0] : false
                temp.registerValidation.username = action.payload.username ? action.payload.username[0] : false
                temp.registerValidation.number = action.payload.number ? action.payload.number[0] : false
                temp.registerValidation.email = action.payload.email ? action.payload.email[0] : false
            }
            break;

        case 'repeat':
            console.log('PAYLOAD --->>>', action.payload);
            break;

        case 'failRegister':
            temp.loginValidation.confirmEmail = false
            break;

        case 'handleSignUp':
            console.log('PAYLOAD --->>>', action.payload);
            if(action.payload.success === true) {
                localStorage.setItem('token', action.payload.token)
                window.location = '/'
            } else {
                temp.registerValidation.confirmCode = true
            }
            break;
            
        case 'successSignIn':
            console.log('LOGIN PAYLOAD --->>>', action.payload)
            if (action.payload.token !== 'undefined') {
                localStorage.setItem('token', action.payload.token)
                localStorage.setItem('userId', action.payload.user.id)
                window.location = '/'
            }
            temp.loginValidation.failSignin = false
            temp.loading = false
            break;

        case 'failSignIn':
            console.log('PAYLOAD --->>>', action.payload)
            temp.loginValidation.failSignin = true
            break;

        case 'restore':
            console.log('PAYLOAD --->>>', action.payload)
            if (action.payload.success === true) {
                temp.loginValidation.confirmEmail = false
            } else if (action.payload.message === 'this number does not exist') {
                temp.loginValidation.restore = true
                temp.loginValidation.confirmEmail = true;
            }
            break;

        case 'failEmail':
            temp.loginValidation.confirmEmail = true
            break;

        case 'code':
            console.log('PAYLOAD --->>>', action.payload)
            if (action.payload.success === true) {
                localStorage.setItem('userId', action.payload.user_id)
                temp.loginValidation.code = false
            } else {
                temp.loginValidation.code = true
            }
            break;

        case 'failCode':
            temp.loginValidation.code = true
            break;

        case 'password':
            console.log('PAYLOAD --->>>', action.payload)
            if (action.payload.status === true) {
                temp.loginValidation.password = false
                temp.loginValidation.failSignin = false
                temp.loginValidation.restore = false
                temp.loginValidation.confirmEmail = false
                temp.loginValidation.code = false
                temp.loginValidation.password = false
            } else {
                temp.loginValidation.password = true
            }
            break;

        case 'failPassword':
            temp.loginValidation.password = true
            break;

        default:
            break;
    }
    return temp;
}
