import { useContext } from 'react';
import { Link } from 'react-router-dom';
import Context from '../context/context';
import './style.css';

export const Footer = () => {
    const context = useContext(Context);
    const tablet = context.tablet
    return (
        <div className='footer' style={{width: tablet ? '90%' : '70%'}}>
            <div className='footerMain'>
                <div className='footerLogo'>
                    <svg width="184" height="54" viewBox="0 0 184 54" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M21.4465 30.1038L24.7396 26.8107L21.4465 23.5176V18.861H16.7899L13.4968 15.5679L10.2036 18.861H5.54707V23.5176L2.25391 26.8107L5.54707 30.1038V34.7604H10.2036L13.4968 38.0536L16.7899 34.7604H21.4465V30.1038Z" stroke="#FFFCEE"  />
                        <path d="M46.9178 53.74H8.42378C4.21371 53.74 0.800781 50.3271 0.800781 46.117V7.623C0.800781 3.41293 4.21371 0 8.42378 0H46.9178C51.1278 0 54.5408 3.41293 54.5408 7.623V46.117C54.5408 50.3271 51.1278 53.74 46.9178 53.74Z" fill="#048E39" />
                        <path d="M44.6764 34.4127L51.8572 27.2297L44.6764 20.0488V9.89279H34.5182L27.3373 2.71191L20.1565 9.89279H10.0004V20.0488L2.81738 27.2297L10.0004 34.4127V44.5687H20.1565L27.3373 51.7496L34.5182 44.5687H44.6764V34.4127Z" stroke="#FFFCEE" />
                        <path d="M39.8047 23.8402L40.8327 27.0027L38.1429 25.049L35.4531 27.0027H35.4509L36.4789 23.8402L33.7912 21.8888L33.7891 21.8866H37.1149L38.1363 18.7437L38.1407 18.7285L38.1429 18.7241V18.7307L39.1687 21.8866H42.4945L39.8047 23.8402Z" fill="#F6E7B9" />
                        <path d="M38.1407 23.272L40.8153 27.0072L38.1299 25.0492L38.1407 23.272Z" fill="#F6E7B9" />
                        <path d="M22.6811 41.1859C21.6509 40.4672 20.8407 39.7724 20.2483 39.1015C19.6558 38.4307 19.2224 37.7403 18.948 37.0303C18.6736 36.3181 18.5037 35.5405 18.4383 34.6933C18.373 33.846 18.3403 32.8812 18.3403 31.7944V17.5088C18.6017 17.951 18.9088 18.3038 19.2594 18.5695C19.6101 18.8352 19.9651 19.0705 20.3267 19.2752C20.6534 19.4473 20.9539 19.6346 21.2284 19.8393C21.5028 20.044 21.7228 20.2967 21.8905 20.5994C22.0582 20.9022 22.1409 21.2942 22.1409 21.7756L22.154 34.0617C22.154 34.6824 22.2803 35.2835 22.5352 35.865C22.7878 36.4466 23.1929 36.8778 23.7483 37.1653C24.0924 37.3374 24.43 37.4223 24.7654 37.4223C25.1008 37.4223 25.4276 37.3374 25.7717 37.1653C26.3358 36.88 26.7431 36.4466 26.9913 35.865C27.2396 35.2857 27.366 34.6846 27.366 34.0617V25.0121C27.366 24.1539 27.4073 23.2087 27.4879 22.1785C27.6927 19.774 28.3417 18.0337 29.4372 16.9556C29.9687 16.4743 30.5698 16.0888 31.2406 15.8035C32.1162 15.4354 32.9373 15.2524 33.7061 15.2524C34.475 15.2524 35.1415 15.4049 35.6794 15.7055C35.8994 15.8274 36.0453 15.9385 36.115 16.0365C36.1847 16.1345 36.2282 16.2238 36.2435 16.3066C35.3766 16.3066 34.695 16.3959 34.1962 16.5767C33.8521 16.7073 33.5014 16.899 33.142 17.1538C32.334 17.7354 31.7938 18.5434 31.5238 19.5823C31.2864 20.4731 31.1687 21.6754 31.1687 23.1869C31.1774 24.4218 31.1818 27.41 31.1818 32.1494C31.1818 33.2035 30.9314 34.1618 30.4348 35.0243C29.936 35.8868 29.226 36.5881 28.3003 37.1261C27.2462 37.7468 26.179 38.3697 25.1009 38.9905C24.4888 39.3172 23.9639 39.6526 23.5305 39.9967C23.0971 40.3408 22.8139 40.7372 22.6833 41.1859" fill="#FFFCEE" />
                        <path d="M81.6903 44.0989C80.0098 42.9253 78.6838 41.789 77.7174 40.6978C76.751 39.6041 76.0419 38.4754 75.5949 37.3142C75.1479 36.153 74.8707 34.882 74.7633 33.5011C74.6559 32.1202 74.6035 30.542 74.6035 28.7666V5.4484C75.0305 6.17007 75.53 6.7469 76.1043 7.1789C76.6786 7.6134 77.258 7.99546 77.8448 8.33007C78.3792 8.60975 78.8686 8.9169 79.3156 9.25151C79.7625 9.58613 80.1221 9.99816 80.3968 10.4926C80.669 10.987 80.8064 11.6263 80.8064 12.4154L80.8263 32.4698C80.8263 33.4836 81.0336 34.465 81.4481 35.4114C81.8626 36.3603 82.5219 37.067 83.4284 37.534C83.9877 37.8136 84.5421 37.9535 85.0889 37.9535C85.6358 37.9535 86.1702 37.8136 86.7296 37.534C87.651 37.067 88.3152 36.3603 88.7223 35.4114C89.1293 34.465 89.3341 33.4836 89.3341 32.4698V17.6993C89.3341 16.2984 89.4015 14.7577 89.5338 13.0746C89.866 9.15163 90.9272 6.30991 92.7152 4.54694C93.5817 3.76034 94.5631 3.13356 95.6568 2.6666C97.0851 2.06729 98.4261 1.76514 99.6796 1.76514C100.933 1.76514 102.022 2.01236 102.901 2.50429C103.261 2.70406 103.498 2.88385 103.613 3.04366C103.725 3.20348 103.795 3.35081 103.822 3.48566C102.409 3.48566 101.293 3.63299 100.479 3.92516C99.9193 4.13991 99.345 4.45204 98.7582 4.86656C97.4372 5.81547 96.5557 7.13395 96.1162 8.8295C95.7292 10.2853 95.5344 12.2456 95.5344 14.7127C95.5469 16.7279 95.5544 21.6048 95.5544 29.3434C95.5544 31.0639 95.1474 32.6296 94.3333 34.038C93.5192 35.4464 92.3581 36.59 90.8498 37.4715C89.1293 38.4854 87.3863 39.4992 85.6258 40.513C84.6245 41.0474 83.7704 41.5943 83.0637 42.1536C82.3571 42.713 81.8951 43.3597 81.6829 44.0939" fill="#1E1E1C" />
                        <path d="M117.459 40.8376C116.058 40.8376 114.959 40.4855 114.168 39.7763C113.374 39.0696 112.817 38.1032 112.497 36.8746C111.963 38.0632 111.254 39.0196 110.365 39.7463C109.478 40.473 108.332 40.8376 106.931 40.8376C105.957 40.8376 105.033 40.6553 104.159 40.2882C103.285 39.9211 102.494 39.3717 101.787 38.6376C101 37.7985 100.409 36.8097 100.017 35.676C99.622 34.5423 99.4272 33.3861 99.4272 32.2125C99.4272 31.5058 99.4997 30.8041 99.647 30.1099C100.126 28.0822 101.008 26.4341 102.289 25.1656C103.57 23.8971 105.083 22.7908 106.834 21.8444C107.728 21.35 108.674 20.8555 109.675 20.3636C111.396 19.5495 113.022 18.6406 114.55 17.6318C116.078 16.6229 117.174 15.3993 117.844 13.9585V33.6733C117.844 34.355 117.896 34.9144 118.003 35.3539C118.111 35.7934 118.325 36.1205 118.653 36.3352C118.98 36.55 119.457 36.6549 120.083 36.6549C120.71 36.6549 121.265 36.4651 121.704 36.083C122.144 35.7035 122.486 35.2265 122.725 34.6522C122.965 34.0778 123.105 33.4785 123.145 32.8517H124.945C124.731 34.6122 124.331 36.0156 123.744 37.0544C123.265 37.9484 122.675 38.7025 121.984 39.3168C120.863 40.3306 119.354 40.8376 117.461 40.8376M108.634 36.7148C108.994 36.7148 109.388 36.6149 109.815 36.4151C110.482 36.068 110.951 35.5536 111.226 34.8744C111.501 34.1927 111.636 33.486 111.636 32.7519V22.1441L110.554 22.7634C108.926 23.6973 107.76 24.6911 107.051 25.7449C106.13 27.1183 105.67 29.0062 105.67 31.4084C105.67 33.3836 106.097 34.8844 106.951 35.9132C107.391 36.4476 107.952 36.7123 108.632 36.7123" fill="#1E1E1C" />
                        <path d="M129.028 40.7978C126.813 40.7978 125.263 40.186 124.374 38.9674C123.485 37.7463 123.043 36.1282 123.043 34.113V20.1216H120.581L121 19.1802C121.467 18.3386 122.146 17.9191 123.04 17.9191V16.9977C123.04 15.4645 123.742 14.2633 125.143 13.3943L125.805 13.0348C126.511 12.6602 127.153 12.2606 127.725 11.8336C128.299 11.4066 128.806 10.8073 129.246 10.0332V17.9191H132.307L131.888 18.8406C131.433 19.6946 130.766 20.1216 129.885 20.1216H129.243V33.631C129.243 34.7797 129.4 35.5638 129.713 35.9833C130.027 36.4029 130.617 36.6126 131.483 36.6126C132.35 36.6126 133.054 36.248 133.596 35.5214C134.135 34.7947 134.452 33.8907 134.547 32.8095H136.347C135.908 35.9983 134.859 38.2058 133.206 39.4343C132.005 40.3433 130.612 40.7953 129.023 40.7953" fill="#1E1E1C" />
                        <path d="M152.346 40.8376C150.945 40.8376 149.847 40.4855 149.053 39.7763C148.258 39.0696 147.702 38.1032 147.382 36.8746C146.848 38.0632 146.138 39.0196 145.249 39.7463C144.363 40.473 143.217 40.8376 141.816 40.8376C140.842 40.8376 139.918 40.6553 139.044 40.2882C138.17 39.9211 137.379 39.3717 136.672 38.6376C135.885 37.7985 135.293 36.8097 134.901 35.676C134.507 34.5423 134.312 33.3861 134.312 32.2125C134.312 31.5058 134.384 30.8041 134.532 30.1099C135.014 28.0822 135.893 26.4341 137.174 25.1656C138.455 23.8971 139.968 22.7908 141.719 21.8444C142.612 21.35 143.559 20.8555 144.56 20.3636C146.281 19.5495 147.906 18.6406 149.435 17.6318C150.963 16.6229 152.059 15.3993 152.726 13.9585V33.6733C152.726 34.355 152.778 34.9144 152.886 35.3539C152.993 35.7934 153.21 36.1205 153.535 36.3352C153.862 36.55 154.339 36.6549 154.966 36.6549C155.593 36.6549 156.147 36.4651 156.586 36.083C157.026 35.7035 157.368 35.2265 157.608 34.6522C157.847 34.0778 157.987 33.4785 158.027 32.8517H159.828C159.613 34.6122 159.213 36.0156 158.627 37.0544C158.147 37.9484 157.558 38.7025 156.866 39.3168C155.745 40.3306 154.237 40.8376 152.344 40.8376M143.516 36.7148C143.876 36.7148 144.271 36.6149 144.698 36.4151C145.364 36.068 145.836 35.5536 146.108 34.8744C146.383 34.1927 146.518 33.486 146.518 32.7519V22.1441L145.437 22.7634C143.809 23.6973 142.64 24.6911 141.933 25.7449C141.012 27.1183 140.552 29.0062 140.552 31.4084C140.552 33.3836 140.979 34.8844 141.833 35.9132C142.273 36.4476 142.835 36.7123 143.514 36.7123" fill="#1E1E1C" />
                        <path d="M164.236 37.1342C164.236 37.9332 164.096 38.5825 163.816 39.0744C163.536 39.5689 163.177 39.9784 162.735 40.3055C162.293 40.6326 161.806 40.9423 161.274 41.2369C160.675 41.5566 160.09 41.9336 159.523 42.3681C158.957 42.8026 158.452 43.3795 158.013 44.0986V29.8675C158.093 27.6651 158.649 25.8746 159.683 24.4937C160.717 23.1128 161.848 22.0415 163.077 21.2824C163.759 20.8429 164.53 20.3884 165.399 19.9215C166.493 19.3471 167.499 18.7378 168.421 18.0911C169.342 17.4443 169.929 16.7401 170.181 15.9785C172.691 17.714 174.346 19.4171 175.155 21.0926C175.962 22.7682 176.367 24.8533 176.367 27.3479C176.394 28.6015 176.406 29.8625 176.406 31.1311V33.6532C176.406 34.3349 176.459 34.8942 176.566 35.3337C176.674 35.7732 176.886 36.1003 177.206 36.3151C177.525 36.5274 178.007 36.6347 178.646 36.6347C179.286 36.6347 179.825 36.445 180.257 36.0654C180.692 35.6858 181.029 35.2089 181.268 34.6345C181.508 34.0602 181.655 33.4609 181.708 32.8341H183.508C183.269 34.5946 182.867 35.998 182.307 37.0368C181.825 37.9307 181.243 38.6849 180.557 39.2992C179.87 39.9135 179.071 40.3255 178.164 40.5402C177.418 40.715 176.676 40.7999 175.942 40.7999C175.033 40.7999 174.192 40.6551 173.41 40.3704C172.631 40.0833 171.999 39.6188 171.52 38.9795C170.638 37.751 170.199 36.1378 170.199 34.1351V25.9695C170.199 25.2628 170.051 24.5761 169.759 23.9069C169.467 23.2402 168.998 22.7457 168.358 22.4261C167.984 22.2263 167.597 22.1264 167.197 22.1264C166.838 22.1264 166.45 22.2263 166.036 22.4261C165.397 22.7457 164.932 23.2402 164.645 23.9069C164.358 24.5736 164.216 25.2603 164.216 25.9695L164.236 32.2947V37.1391V37.1342Z" fill="#1E1E1C" />
                    </svg>
                    <p>Международная социальная сеть, включающая
                        в себя все необходимые ресурсы для жизни в России.
                        Данный ресурс позволяет получить все необходимые
                        услуги в одном месте: поиск работы, помощь с документами в МФЦ и другие.</p>
                </div>
                <div className='infoFooter'>
                    <div className='footerinfo'>
                        <ul>
                            <Link to='#'><li className='biggerTitle'><b>О Vatan</b></li></Link>
                            <Link to='#'><li className='smallerTitle'>О Нас</li></Link>
                            <Link to='#'><li className='smallerTitle'>Контакты</li></Link>
                            <Link to='/services'><li className='smallerTitle'>Услуги</li></Link>
                            <Link to='#'><li className='smallerTitle'>Миссия проекта</li></Link>
                        </ul>
                    </div>

                    <div className='footerinfo'>
                        <ul>
                            <Link to='#'><li className='biggerTitle'><b>Соц. сеть</b></li></Link>
                            <Link to='/'><li className='smallerTitle'>Главная</li></Link>
                            <Link to='/friends'><li className='smallerTitle'>Друзья и семья</li></Link>
                            <Link to='/wallet'><li className='smallerTitle'>Кошелёк</li></Link>
                            <Link to='#'><li className='smallerTitle'>Обратная связь</li></Link>
                            <Link to='/settings'><li className='smallerTitle'>Настройки</li></Link>
                        </ul>
                    </div>
                </div>
            </div>
            <div className='footerBottom'>
                <span className='smallerTitle' id='bottom'>© 2022 VATAN-все права защищены</span>
                <span className='smallerTitle' id='bottom'>Сайт разработан компанией Shubyn IT</span>
            </div>
        </div>
    )
}