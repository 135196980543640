/* eslint-disable import/no-anonymous-default-export */
export default {
    loading: false,
    user: {
        friendRequests: [],
        friends: [],
        myGroups: [],
        groupsRequest: [],
        myProfile: [],
        chatMembers: [],
        currentChatMember: {},
        allChatMessages: [],
        chatMemberId: '',
        allPosts: [],
    },
    randomUser: {
        randomUserProfile: {},
        randomUserPosts: {},
        isFriend: false,
    },
    myCommentOnPost: {},
    registerValidation: { 
        city: false,
        date_of_birth: false,
        name: false,
        password: false,
        password_confirmation: false,
        patronymic: false,
        surname: false,
        username: false,
        openPopup: false,
        confirmCode: false,
        number: false,
        email: false,
    },
    loginValidation: {
        failSignin: false,
        restore: false,
        confirmEmail: false,
        code: false,
        password: false,
    },
    settingsValidation: {
        addEmail: false,
        confirmEmailCode: false,
        confirmEmailAddress: false,
        confirmEmailAddressCode: false,
        changeUserName: false,
        editPasswords: false,
        addPhoneNumber: false,
        confirmAddPhone: false,
        editPhoneNumber: false,
        confirmEditPhone: false,
        hideProfile: false,
        deleteProfile: false,
    }

}