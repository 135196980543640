import "@fontsource/montserrat"
import './App.css'
import Context from "./components/context/context";
import { MyRouter } from "./MyRouter";
import { useEffect, useState } from "react";

function App() {
  function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
  }
  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    } 

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);
  const [windowSize, setWindowSize] = useState(getWindowSize());

  const tablet = windowSize.innerWidth <= 768;
  const smallWindow = windowSize.innerWidth <= 600;

  const [restore, setRestore] = useState('')
  const [confirmCode, setConfirmCode] = useState('')
  const [newEmail, setNewEmail] = useState('')
  const [newEmailCode, setNewEmailCode] = useState('')
  const [oldPassword, setOldPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [openHamburger, setOpenHamburger] = useState(false);
  const [codeConfirmRegister, setCodeConfirmRegister] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('')
  const [userName, setUserName] = useState('')
  const [hideAccountPassword, setHideAccountPassword] = useState('')
  const [deleteAccountPassword, setDeleteAccountPassword] = useState('')
  const [code, setCode] = useState('')
  const [mainPost, setMainPost] = useState('')
  

  const value = {
    restore, setRestore, // sending email to restore password
    confirmCode, setConfirmCode, // sending confirmation code to verify email/phone on login page
    newEmail, setNewEmail,
    oldPassword, setOldPassword,
    newPassword, setNewPassword,
    confirmPassword, setConfirmPassword,
    tablet, smallWindow,
    openHamburger, setOpenHamburger,
    codeConfirmRegister, setCodeConfirmRegister,
    phoneNumber, setPhoneNumber,
    userName, setUserName,
    hideAccountPassword, setHideAccountPassword,
    deleteAccountPassword, setDeleteAccountPassword,
    newEmailCode, setNewEmailCode,
    code, setCode,
    mainPost, setMainPost,
  }


  return ( 
    <Context.Provider value={value}>
      <MyRouter />
    </Context.Provider>
  );
}

export default App;
