const token = localStorage.getItem('token')

const myProfileResponse= (payload) => {
    return {
        type: 'myProfileResponse',
        payload,
    }
}

export const myProfile = () => {
    return async (dispatch) => {
        await fetch(`${process.env.REACT_APP_HOSTNAME}/profile-user`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            }
        })
            .then(data => data.json())
            .then(res => { console.log('reererere', res); dispatch(myProfileResponse(res)) })
            .catch(err => console.log('ERROR --->>>', err.message))
    }
}