import './style.css';

export const MenuItem = ({ whiteIcon, blackIcon, title, active, messageCount }) => {

    return (
        <div className='menuItem' id={active ? 'active' : ''}>
            <div className='menuIcon'>
                {active ? whiteIcon : blackIcon}
            </div>
            <div className='menuTitle'>
                {title}
            </div>
            {messageCount && <div className='messageCount' >
                {messageCount}
            </div>
            }
            <div className='messagePoint'>
                {
                    messageCount &&
                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect width="14" height="14" rx="7" fill="#048E39" />
                    </svg>
                }
            </div>
        </div>
    )
} 