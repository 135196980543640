import { useState } from 'react'
import { Popup } from '../../components/popup'
import CustomizedSwitches from '../../components/switcher'
import './style.css'

export const GroupSettings = () => {

    const list = [
        {
            img: require('../../public/blackList1.png'),
            name: 'Богословский Артем',
        },
        {
            img: require('../../public/blackList2.png'),
            name: 'Самбикина Юлия',
        },
        {
            img: require('../../public/blackList3.png'),
            name: 'Иванов Алексей',
        },
        {
            img: require('../../public/blackList1.png'),
            name: 'Богословский Артем',
        },
        {
            img: require('../../public/blackList2.png'),
            name: 'Самбикина Юлия',
        },
        {
            img: require('../../public/blackList3.png'),
            name: 'Иванов Алексей',
        },
    ]

    const [editName, setEditName] = useState(false)
    const [successEditName, setSuccessEditName] = useState(false)
    const [unblock, setUnblock] = useState(false);
    const [successUnblock, setSuccessUnblock] = useState(false);

    function handleClick1() {
        setEditName(false)
        setSuccessEditName(true)
    }
    function handleClick2() {
        setSuccessEditName(false)
    }
    function handleClick3() {
        setSuccessUnblock(true)
    }
    function handleClick4() {
        setUnblock(false)
    }
    function handleClick5() {
        setSuccessUnblock(false)
        setUnblock(false)
    }

    return (
        <div>
            <Popup
                title={'Изменение названия группы'}
                text={'Введите новое название'}
                inputFirst='Название группы'
                button={'Сохранить'}
                onClick={handleClick1}
                showPopup={editName}
                setShowPopup={setEditName}
            />
            <Popup
                title='Название группы успешно изменено'
                button={'Закрыть'}
                onClick={handleClick2}
                showPopup={successEditName}
                setShowPopup={setSuccessEditName}
            />
            <Popup
                title='Вы действительно хотите разблокировать пользователя?'
                button={'Подтвердить'}
                transparentButton='Отменить'
                transBg='none'
                onClick={handleClick3}
                onClick2={handleClick4}
                showPopup={unblock}
                setShowPopup={setUnblock}
            />
            <Popup
                title='Пользователь успешно разблокирован'
                button={'Закрыть'}
                onClick={handleClick5}
                showPopup={successUnblock}
                setShowPopup={setSuccessUnblock}
            />
            <div className='top'>
                <h1>Настройки Группы</h1>
            </div>

            <div className='firstSetting'>
                <div className='groupPrivacy'>
                    <div className='groupPrivacyLeftPart'>
                        <span>Сведения группы</span>
                    </div>
                    <div className='groupPrivacyRightPart s'>
                        <div className='sw'>
                            <span>Доступность группы </span>
                        </div>
                        <div className='switchh'>
                            <span>Открытая</span>
                            <CustomizedSwitches />
                        </div>
                    </div>
                </div>
                <div className='groupName'>
                    <label>Название</label>
                    <div className='groupSettingsSwitch' >
                        <input />
                        <button onClick={() => setEditName(true)}>
                            <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M18.6875 11.5C18.6875 11.3094 18.7632 11.1266 18.898 10.9918C19.0328 10.857 19.2156 10.7812 19.4062 10.7812C19.5969 10.7812 19.7797 10.857 19.9145 10.9918C20.0493 11.1266 20.125 11.3094 20.125 11.5V19.4062C20.125 19.5969 20.0493 19.7797 19.9145 19.9145C19.7797 20.0493 19.5969 20.125 19.4062 20.125H3.59375C3.40313 20.125 3.22031 20.0493 3.08552 19.9145C2.95073 19.7797 2.875 19.5969 2.875 19.4062V3.59375C2.875 3.40313 2.95073 3.22031 3.08552 3.08552C3.22031 2.95073 3.40313 2.875 3.59375 2.875H11.5C11.6906 2.875 11.8734 2.95073 12.0082 3.08552C12.143 3.22031 12.2188 3.40313 12.2188 3.59375C12.2188 3.78437 12.143 3.96719 12.0082 4.10198C11.8734 4.23677 11.6906 4.3125 11.5 4.3125H4.3125V18.6875H18.6875V11.5Z" fill="#048E39" />
                                <path d="M10.5558 12.4494L11.7417 12.2798L19.027 4.99598C19.0956 4.92967 19.1504 4.85036 19.188 4.76267C19.2257 4.67498 19.2455 4.58067 19.2464 4.48523C19.2472 4.3898 19.229 4.29515 19.1929 4.20682C19.1567 4.11849 19.1034 4.03824 19.0359 3.97076C18.9684 3.90327 18.8881 3.8499 18.7998 3.81376C18.7115 3.77762 18.6168 3.75944 18.5214 3.76027C18.426 3.7611 18.3317 3.78092 18.244 3.81859C18.1563 3.85626 18.077 3.91102 18.0107 3.97966L10.724 11.2635L10.5543 12.4494H10.5558ZM20.0433 2.96191C20.2437 3.16216 20.4026 3.39992 20.5111 3.66162C20.6195 3.92332 20.6753 4.20382 20.6753 4.4871C20.6753 4.77038 20.6195 5.05088 20.5111 5.31258C20.4026 5.57428 20.2437 5.81205 20.0433 6.01229L12.5898 13.4657C12.4799 13.576 12.3372 13.6477 12.183 13.6699L9.81116 14.0091C9.70062 14.025 9.5879 14.0149 9.48195 13.9796C9.37599 13.9443 9.27972 13.8848 9.20075 13.8059C9.12179 13.7269 9.06231 13.6306 9.02703 13.5247C8.99176 13.4187 8.98166 13.306 8.99753 13.1955L9.33678 10.8236C9.35856 10.6696 9.42968 10.5269 9.53947 10.4168L16.9943 2.96335C17.3987 2.55912 17.9471 2.33203 18.5188 2.33203C19.0906 2.33203 19.6389 2.55912 20.0433 2.96335V2.96191Z" fill="#048E39" />
                            </svg>
                        </button>
                    </div>
                </div>
            </div>

            <div className='secondSetting'>
                <div className='groupPrivacyLeftPart'>
                    <span>Сведения группы</span>
                </div>

                <div className='eachGroupSetting' id='forMargin'>
                    <span>Комметарии</span>
                    <div className='s'>
                        <span>Все</span>
                        <CustomizedSwitches />
                    </div>
                </div>

                <div className='eachGroupSetting'>
                    <span>Аудио публикации</span>
                    <div className='groupSettingsSwitch s'>
                        <span>Все</span>
                        <CustomizedSwitches />
                    </div>
                </div>

                <div className='eachGroupSetting' id='onlyAdmin'>
                    <span>Видео публикации</span>
                    <div className='groupSettingsSwitch s'>
                        <span>Только Админ</span>
                        <CustomizedSwitches defaultChecked={false} />
                    </div>
                </div>
            </div>

            <div className='thirdSetting'>
                <div className='black'>
                    <span>Черный список</span>
                </div>
                <div className='blackList'>
                    {
                        list.map((e, i) => (
                            <div className='eachBlockedUser' key={i}>
                                <img src={e.img} alt={e.name} />
                                <span>{e.name}</span>
                                <div className='blurEffectBlock'>
                                    <button onClick={() => setUnblock(true)}>Разблокировать пользователья</button>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
        </div>
    )
}