import signUp from "../store/signUp";

export const friendsReducer = (state = signUp, action) => {
    let temp = { ...state }
    switch (action.type) {
        case 'getFriendRequests':
            console.log('ACTION.PAYLOAD --->>>', action.payload.data)
            temp.user.friendRequests = action.payload.data
        break;

        case 'getFriends':
            console.log('ACTION.PAYLOAD --->>>', action.payload.data)
            temp.user.friends = action.payload.data
        break;
       
        default:
            break;
    }
    return temp;
}