import './style.css';
import SimpleAccordion from '../../components/accordion';
import { Link } from 'react-router-dom';

export const Services = () => {
    return (
        <div>
            <div className='top'>
                <h1>Услуги</h1>
                <div className='serviceWhiteButtons'>
                    <Link to='/myDocuments'><button>Мои документы</button></Link>
                    <Link to='/callHistory'><button>История обращений</button></Link>
                </div>
                <div className='mobileServiceBtns'>
                    <button onClick={() => window.location = '/myDocuments'}>
                        <svg width="20" height="26" viewBox="0 0 20 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M13.2168 1.00615L18.9711 7.13662M5.28541 12.3403H14.6857M5.28541 19.7655H14.6857M5.28541 16.0529H14.6857M2.12348 1.00615C1.82294 1.01402 1.5372 1.14677 1.32728 1.37605C1.11737 1.60532 0.999909 1.91295 1 2.23323V23.7729C0.999906 24.0931 1.11728 24.4006 1.32708 24.6299C1.53687 24.8592 1.82247 24.992 2.12291 25H17.8482C18.1537 25 18.4466 24.8707 18.6626 24.6406C18.8787 24.4105 19 24.0984 19 23.7729V7.13662H14.3923C14.0916 7.12874 13.8058 6.99591 13.5959 6.7665C13.3859 6.5371 13.2685 6.22931 13.2688 5.90892V1L2.12348 1.00615Z" stroke="black" />
                        </svg>
                    </button>
                    <button onClick={() => window.location = '/callHistory'}>
                        <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M14 26.25C11.5772 26.25 9.20877 25.5316 7.19427 24.1855C5.17977 22.8395 3.60965 20.9263 2.68248 18.6879C1.75531 16.4495 1.51272 13.9864 1.98539 11.6101C2.45805 9.23388 3.62475 7.05114 5.33795 5.33795C7.05114 3.62475 9.23388 2.45805 11.6101 1.98539C13.9864 1.51272 16.4495 1.75531 18.6879 2.68248C20.9263 3.60965 22.8395 5.17977 24.1855 7.19427C25.5316 9.20877 26.25 11.5772 26.25 14C26.25 17.2489 24.9594 20.3647 22.6621 22.6621C20.3647 24.9594 17.2489 26.25 14 26.25ZM14 3.50001C11.9233 3.50001 9.89323 4.11582 8.16652 5.26957C6.4398 6.42333 5.09399 8.06321 4.29927 9.98183C3.50455 11.9005 3.29662 14.0117 3.70176 16.0485C4.1069 18.0853 5.10693 19.9562 6.57538 21.4246C8.04384 22.8931 9.91476 23.8931 11.9516 24.2983C13.9884 24.7034 16.0996 24.4955 18.0182 23.7007C19.9368 22.906 21.5767 21.5602 22.7304 19.8335C23.8842 18.1068 24.5 16.0767 24.5 14C24.5 11.2152 23.3938 8.54452 21.4246 6.57538C19.4555 4.60625 16.7848 3.50001 14 3.50001Z" fill="#333333" />
                            <path d="M18.6334 18.6328C18.2926 18.9737 17.7399 18.9737 17.3991 18.6328L13.125 14.3587V7C13.125 6.51675 13.5168 6.125 14 6.125C14.4832 6.125 14.875 6.51675 14.875 7V13.6325L18.634 17.3991C18.9743 17.74 18.9741 18.2922 18.6334 18.6328Z" fill="#333333" />
                        </svg>
                    </button>
                </div>
            </div>
            <hr className='horizontal' />
            <div className='serviceMainPage'>
                <SimpleAccordion />
            </div>
        </div>
    )
}
