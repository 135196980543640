import './style.css'
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import { Button } from '../button';
import { Popup } from '../popup'
import { useContext, useState } from 'react';
import Context from '../context/context';

export default function SimpleAccordion() {
    const [confirm, setConfirm] = useState(false)
    const [deny, setDeny] = useState(false)
    const [success, setSuccess] = useState(false)

    function handleClick1() {
        setConfirm(false)
        setDeny(true)
    }
    function handleClick2() {
        setConfirm(false)
    }
    function handleClick3() {
        setDeny(false)
        setSuccess(true)
    }
    function handleClick4() {
        setSuccess(false)
        window.location = '/myDocuments'
    }

    const context = useContext(Context);
    const tablet = context.tablet

    return (
        <div>
            <Popup
                title={`Подтвердите оформление услуги (Водительское удостоверение)`}
                button='Подтвердить'
                transparentButton='Отменить'
                transBg='none'
                onClick={handleClick1}
                onClick2={handleClick2}
                showPopup={confirm}
                setShowPopup={setConfirm}
            />
            <Popup
                title='На вашем балансе недостаточно средств.'
                titleFontWeight='400'
                title2='Пожалуйста пополните кошелёк!'
                button='Пополнить'
                transparentButton='Отменить'
                transBg='none'
                onClick={handleClick3}
                showPopup={deny}
                setShowPopup={setDeny}
            />
            <Popup
                title='Услуга успешно оплачена'
                titleColor='#048E39'
                text='Вы купили услугу (Сдача русского языка) для дальнейших действий вам нужно загрузить/выбрать  документы'
                textSize='20px'
                textLineHeight='24px'
                button='Выбрать'
                onClick={handleClick4}
                showPopup={success}
                setShowPopup={setSuccess}
            />


            <Accordion sx={{
                marginBottom: '10px',
                boxShadow: 'none',
            }}>
                <AccordionSummary
                    expandIcon={
                        <svg width="17" height="9" viewBox="0 0 17 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M15.5 0.875L8.5 7.875L1.5 0.875" stroke="#333333" />
                        </svg>
                    }
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography variant='h5' className='accordionTitle'> <b>Патент - </b> 1 месяц (со дня въезда в страну)</Typography>
                </AccordionSummary>

                <AccordionDetails>
                    <Typography className='accordionText'>
                        Это документ, который дает право иностранному гражданину или лицу без гражданства, прибывшему в Россию в порядке, не требующем получения визы, работать у физического или юридического лица.
                        Процесс получения , сроки , наши услуги по помощи в получении и стоимость читайте в разделе.
                    </Typography>
                    <div className="accordionOptionalText">
                        <Typography>
                            Список нужных документов
                        </Typography>
                        <Typography className='accordionRequiredDocuments'><b>Паспорт, Свидетельство о рождении</b></Typography>
                    </div>
                    <div className='accordionFooter' >
                        <div onClick={() => setConfirm(true)}>
                            <Button txt='Оформить' width='100%' height='45px' fontSize='20px' />
                        </div>
                        {
                            tablet ?
                                <Typography variant="h5">
                                    <b>15000 руб.</b>
                                </Typography>
                                :
                                <Typography variant="h5">
                                    <b>15000 рублей</b>
                                </Typography>
                        }
                    </div>
                </AccordionDetails>
            </Accordion>
            <Accordion sx={{
                marginBottom: '10px',
                boxShadow: 'none',
            }}>
                <AccordionSummary
                    expandIcon={
                        <svg width="17" height="9" viewBox="0 0 17 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M15.5 0.875L8.5 7.875L1.5 0.875" stroke="#333333" />
                        </svg>
                    }
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography variant='h5' className='accordionTitle'> <b>Патент - </b> 1 месяц (со дня въезда в страну)</Typography>
                </AccordionSummary>

                <AccordionDetails>
                    <Typography className='accordionText'>
                        Это документ, который дает право иностранному гражданину или лицу без гражданства, прибывшему в Россию в порядке, не требующем получения визы, работать у физического или юридического лица.
                        Процесс получения , сроки , наши услуги по помощи в получении и стоимость читайте в разделе.
                    </Typography>
                    <div className="accordionOptionalText">
                        <Typography>
                            Список нужных документов
                        </Typography>
                        <Typography className='accordionRequiredDocuments'><b>Паспорт, Свидетельство о рождении</b></Typography>
                    </div>
                    <div className='accordionFooter' >
                        <div onClick={() => setConfirm(true)}>
                            <Button txt='Оформить' width='100%' height='45px' fontSize='20px' />
                        </div>
                        {
                            tablet ?
                                <Typography variant="h5">
                                    <b>15000 руб.</b>
                                </Typography>
                                :
                                <Typography variant="h5">
                                    <b>15000 рублей</b>
                                </Typography>
                        }
                    </div>
                </AccordionDetails>
            </Accordion>
            <Accordion sx={{
                marginBottom: '10px',
                boxShadow: 'none',
            }}>
                <AccordionSummary
                    expandIcon={
                        <svg width="17" height="9" viewBox="0 0 17 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M15.5 0.875L8.5 7.875L1.5 0.875" stroke="#333333" />
                        </svg>
                    }
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography variant='h5' className='accordionTitle'> <b>Патент - </b> 1 месяц (со дня въезда в страну)</Typography>
                </AccordionSummary>

                <AccordionDetails>
                    <Typography className='accordionText'>
                        Это документ, который дает право иностранному гражданину или лицу без гражданства, прибывшему в Россию в порядке, не требующем получения визы, работать у физического или юридического лица.
                        Процесс получения , сроки , наши услуги по помощи в получении и стоимость читайте в разделе.
                    </Typography>
                    <div className="accordionOptionalText">
                        <Typography>
                            Список нужных документов
                        </Typography>
                        <Typography className='accordionRequiredDocuments'><b>Паспорт, Свидетельство о рождении</b></Typography>
                    </div>
                    <div className='accordionFooter' >
                        <div onClick={() => setConfirm(true)}>
                            <Button txt='Оформить' width='100%' height='45px' fontSize='20px' />
                        </div>
                        {
                            tablet ?
                                <Typography variant="h5">
                                    <b>15000 руб.</b>
                                </Typography>
                                :
                                <Typography variant="h5">
                                    <b>15000 рублей</b>
                                </Typography>
                        }
                    </div>
                </AccordionDetails>
            </Accordion>
            <Accordion sx={{
                marginBottom: '10px',
                boxShadow: 'none',
            }}>
                <AccordionSummary
                    expandIcon={
                        <svg width="17" height="9" viewBox="0 0 17 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M15.5 0.875L8.5 7.875L1.5 0.875" stroke="#333333" />
                        </svg>
                    }
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography variant='h5' className='accordionTitle'> <b>Патент - </b> 1 месяц (со дня въезда в страну)</Typography>
                </AccordionSummary>

                <AccordionDetails>
                    <Typography className='accordionText'>
                        Это документ, который дает право иностранному гражданину или лицу без гражданства, прибывшему в Россию в порядке, не требующем получения визы, работать у физического или юридического лица.
                        Процесс получения , сроки , наши услуги по помощи в получении и стоимость читайте в разделе.
                    </Typography>
                    <div className="accordionOptionalText">
                        <Typography>
                            Список нужных документов
                        </Typography>
                        <Typography className='accordionRequiredDocuments'><b>Паспорт, Свидетельство о рождении</b></Typography>
                    </div>
                    <div className='accordionFooter' >
                        <div onClick={() => setConfirm(true)}>
                            <Button txt='Оформить' width='100%' height='45px' fontSize='20px' />
                        </div>
                        {
                            tablet ?
                                <Typography variant="h5">
                                    <b>15000 руб.</b>
                                </Typography>
                                :
                                <Typography variant="h5">
                                    <b>15000 рублей</b>
                                </Typography>
                        }
                    </div>
                </AccordionDetails>
            </Accordion>
            <Accordion sx={{
                marginBottom: '10px',
                boxShadow: 'none',
            }}>
                <AccordionSummary
                    expandIcon={
                        <svg width="17" height="9" viewBox="0 0 17 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M15.5 0.875L8.5 7.875L1.5 0.875" stroke="#333333" />
                        </svg>
                    }
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography variant='h5' className='accordionTitle'> <b>Патент - </b> 1 месяц (со дня въезда в страну)</Typography>
                </AccordionSummary>

                <AccordionDetails>
                    <Typography className='accordionText'>
                        Это документ, который дает право иностранному гражданину или лицу без гражданства, прибывшему в Россию в порядке, не требующем получения визы, работать у физического или юридического лица.
                        Процесс получения , сроки , наши услуги по помощи в получении и стоимость читайте в разделе.
                    </Typography>
                    <div className="accordionOptionalText">
                        <Typography>
                            Список нужных документов
                        </Typography>
                        <Typography className='accordionRequiredDocuments'><b>Паспорт, Свидетельство о рождении</b></Typography>
                    </div>
                    <div className='accordionFooter' >
                        <div onClick={() => setConfirm(true)}>
                            <Button txt='Оформить' width='100%' height='45px' fontSize='20px' />
                        </div>
                        {
                            tablet ?
                                <Typography variant="h5">
                                    <b>15000 руб.</b>
                                </Typography>
                                :
                                <Typography variant="h5">
                                    <b>15000 рублей</b>
                                </Typography>
                        }
                    </div>
                </AccordionDetails>
            </Accordion>
            <Accordion sx={{
                marginBottom: '10px',
                boxShadow: 'none',
            }}>
                <AccordionSummary
                    expandIcon={
                        <svg width="17" height="9" viewBox="0 0 17 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M15.5 0.875L8.5 7.875L1.5 0.875" stroke="#333333" />
                        </svg>
                    }
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography variant='h5' className='accordionTitle'> <b>Патент - </b> 1 месяц (со дня въезда в страну)</Typography>
                </AccordionSummary>

                <AccordionDetails>
                    <Typography className='accordionText'>
                        Это документ, который дает право иностранному гражданину или лицу без гражданства, прибывшему в Россию в порядке, не требующем получения визы, работать у физического или юридического лица.
                        Процесс получения , сроки , наши услуги по помощи в получении и стоимость читайте в разделе.
                    </Typography>
                    <div className="accordionOptionalText">
                        <Typography>
                            Список нужных документов
                        </Typography>
                        <Typography className='accordionRequiredDocuments'><b>Паспорт, Свидетельство о рождении</b></Typography>
                    </div>
                    <div className='accordionFooter' >
                        <div onClick={() => setConfirm(true)}>
                            <Button txt='Оформить' width='100%' height='45px' fontSize='20px' />
                        </div>
                        {
                            tablet ?
                                <Typography variant="h5">
                                    <b>15000 руб.</b>
                                </Typography>
                                :
                                <Typography variant="h5">
                                    <b>15000 рублей</b>
                                </Typography>
                        }
                    </div>
                </AccordionDetails>
            </Accordion>




        </div>
    );
}
