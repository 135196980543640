import { startLoading, successLoading } from "./loginRegisterAction";

const token = localStorage.getItem('token')

const chatMembers = (payload) => {
    return {
        type: 'chatMembers',
        payload,
    }
}

const currentMember = (payload) => {
    return {
        type: 'currentMember',
        payload
    }
}

export const getChatMembers = () => {
    return async (dispatch) => {
        dispatch(startLoading())
        await fetch(`${process.env.REACT_APP_HOSTNAME}/rightsidechat`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            },
        })
            .then(data => data.json())
            .then(res => {
                dispatch(successLoading())
                dispatch(chatMembers(res))
            })
            .catch(err => {
                dispatch(successLoading())
                console.log('ERROR --->>>', err.message)
            })
    }
}

export const getCurrentChatMember = (id) => {
    return async (dispatch) => {
        dispatch(startLoading())
        await fetch(`${process.env.REACT_APP_HOSTNAME}/chat/${id}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            },
        })
            .then(data => data.json())
            .then(res => {
                dispatch(successLoading())
                dispatch(currentMember(res));
            })
            .catch(err => {
                dispatch(successLoading())
                console.log('ERROR --->>>', err.message)
            })
    }
}

export const sendMessage = (id, message) => {
    console.log(id, message);
    return async (dispatch) => {
        dispatch(startLoading())
        await fetch(`${process.env.REACT_APP_HOSTNAME}/chat`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            },
            body: JSON.stringify({
                receiver_id: id,
                messages: message
            }),
        })
            .then(data => data.json())
            .then(res => {
                dispatch(successLoading())
                console.log('RES --->>>', res);
            })
            .catch(err => {
                dispatch(successLoading())
                console.log('ERROR --->>>', err.message)
            })
    }
}