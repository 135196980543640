import './style.css';
import { useState } from 'react';
import { NewGroupPopup } from '../../components/newGroupPopup';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { cancelFriendRequest, confirmFriendRequest, getFriendRequests, getFriends } from '../../Redux/action/friendsAction';
import { getAllGroups, getGroupRequest } from '../../Redux/action/groupsAction';

export const FriendsAndFamily = () => {


    const [pop, setPop] = useState(false)

    const groups = [
        {
            img: require('../../public/group1.png'),
            name: 'Семья Буракшаева',
        },
        {
            img: require('../../public/group2.png'),
            name: 'Семья Буракшаева',
        },
        {
            img: require('../../public/group3.png'),
            name: 'Семья Буракшаева',
        },
        {
            img: require('../../public/group1.png'),
            name: 'Семья Буракшаева',
        },
        {
            img: require('../../public/group2.png'),
            name: 'Семья Буракшаева',
        },
        {
            img: require('../../public/group3.png'),
            name: 'Семья Буракшаева',
        },
        {
            img: require('../../public/group1.png'),
            name: 'Семья Буракшаева',
        },
        {
            img: require('../../public/group2.png'),
            name: 'Семья Буракшаева',
        },
        {
            img: require('../../public/group3.png'),
            name: 'Семья Буракшаева',
        },
        {
            img: require('../../public/group1.png'),
            name: 'Семья Буракшаева',
        },
        {
            img: require('../../public/group2.png'),
            name: 'Семья Буракшаева',
        },
        {
            img: require('../../public/group3.png'),
            name: 'Семья Буракшаева',
        },
        {
            img: require('../../public/group1.png'),
            name: 'Семья Буракшаева',
        },
        {
            img: require('../../public/group2.png'),
            name: 'Семья Буракшаева',
        },
        {
            img: require('../../public/group3.png'),
            name: 'Семья Буракшаева',
        },
    ]

    const invitation = [
        {
            img: require('../../public/request1.png'),
            group: 'Семья Буракшева',
            name: 'Мельникова Ксения',
        },
        {
            img: require('../../public/request1.png'),
            group: 'Семья Буракшева',
            name: 'Мельникова Ксения',
        },
        {
            img: require('../../public/request1.png'),
            group: 'Семья Буракшева',
            name: 'Мельникова Ксения',
        },
        {
            img: require('../../public/request1.png'),
            group: 'Семья Буракшева',
            name: 'Мельникова Ксения',
        },
        {
            img: require('../../public/request1.png'),
            group: 'Семья Буракшева',
            name: 'Мельникова Ксения',
        },
        {
            img: require('../../public/request1.png'),
            group: 'Семья Буракшева',
            name: 'Мельникова Ксения',
        },

    ]
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getFriendRequests())
        dispatch(getFriends())
        dispatch(getAllGroups())
        dispatch(getGroupRequest())
    }, [])


    const requests = useSelector(st => st.friendsReducer.user.friendRequests)
    const friends = useSelector(st => st.friendsReducer.user.friends)
    const group = useSelector(st => st.groupsReducer.user.myGroups)
    const groupRequest = useSelector(st => st.groupsReducer.user.groupsRequest)
    console.log(groupRequest, 'ffffff')

    let [appendFriends] = useState()

    async function functionh(id) {
        let friends = document.querySelector('.friends')

        document.querySelectorAll('.eachRequest').forEach((evn, index) => {
            if (id === index) {
                appendFriends = evn
                evn.remove()
            }
        })
        appendFriends.className = 'eachFriend'
        let img = appendFriends.children[0].children[0]
        let span = appendFriends.children[0].children[1]
        appendFriends.append(img)
        appendFriends.append(span)
        appendFriends.children[1].remove()
        friends.append(appendFriends)
    }

    console.log(requests);
    return (
        <div>
            <NewGroupPopup
                showPop={pop}
                setShowPop={setPop}
            />
            <div className='top'>
                <h1>Запросы в друзья</h1>
            </div>
            <div className='requests' style={requests?.length !== 0 ? { justifyContent: 'space-between' } : { justifyContent: 'center' }}>
                {
                    requests?.length !== 0 ?
                        requests.map((e, i) => (
                            <div className='eachRequest' key={i}>
                                <div className='requestLeftSide'>
                                    <img src={`${process.env.REACT_APP_SERVER_HOSTNAME}/${e?.sender?.avatar}`} alt='' />
                                    <span>{e?.sender?.name}</span>
                                </div>
                                <div className='requestRightSide'>
                                    <div className='acc' onClick={() => {
                                        dispatch(confirmFriendRequest(e?.sender?.id))
                                        functionh(i)
                                    }}></div>
                                    <div className='dec' onClick={() => {
                                        dispatch(cancelFriendRequest(e?.sender?.id))
                                        functionh(i)
                                    }}></div>
                                </div>
                            </div>
                        ))
                        :
                        <h1>Нет запросов в друзья</h1>
                }
            </div>

            <div className='top'>
                <h1>Друзья и Семья</h1>
            </div>

            <div className='friends'>
                {
                    friends.map((e, i) => (
                        <div className='eachFriend' key={i}>
                            <img src={`${process.env.REACT_APP_SERVER_HOSTNAME}/${e?.sender?.avatar}`} alt='' />
                            <span>{e?.sender?.name}</span>
                        </div>
                    ))
                }
            </div>

            <div className='top'>
                <h1>Группы</h1>
                <div className='hideGroupBtn' onClick={() => setPop(true)}>
                    <button>Создать группу</button>
                </div>
                <button className='groupMobile' onClick={() => setPop(true)}>
                    {/* <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M8.99951 0.10826C9.12283 0.108104 9.24496 0.132278 9.35892 0.179396C9.47287 0.226514 9.57641 0.295651 9.66361 0.382847C9.75081 0.470043 9.81994 0.573584 9.86706 0.687541C9.91418 0.801498 9.93835 0.923631 9.9382 1.04694L9.93687 8.06454L16.9545 8.06321C17.0777 8.06321 17.1998 8.08749 17.3137 8.13466C17.4276 8.18184 17.531 8.25098 17.6182 8.33815C17.7054 8.42531 17.7745 8.52879 17.8217 8.64268C17.8689 8.75656 17.8931 8.87863 17.8931 9.0019C17.8931 9.12517 17.8689 9.24723 17.8217 9.36111C17.7745 9.475 17.7054 9.57848 17.6182 9.66565C17.531 9.75281 17.4276 9.82195 17.3137 9.86913C17.1998 9.9163 17.0777 9.94058 16.9545 9.94058L9.93687 9.93925L9.9382 16.9568C9.9382 17.0801 9.91392 17.2022 9.86674 17.3161C9.81957 17.43 9.75043 17.5334 9.66326 17.6206C9.5761 17.7078 9.47262 17.7769 9.35873 17.8241C9.24484 17.8713 9.12278 17.8955 8.99951 17.8955C8.87624 17.8955 8.75418 17.8713 8.64029 17.8241C8.52641 17.7769 8.42293 17.7078 8.33576 17.6206C8.2486 17.5334 8.17945 17.43 8.13228 17.3161C8.08511 17.2022 8.06083 17.0801 8.06083 16.9568L8.06215 9.93925L1.04456 9.94058C0.921291 9.94058 0.799228 9.9163 0.685342 9.86913C0.571455 9.82195 0.467976 9.75281 0.380811 9.66565C0.293646 9.57848 0.224502 9.475 0.177329 9.36111C0.130156 9.24723 0.105876 9.12517 0.105876 9.0019C0.105876 8.87863 0.130156 8.75656 0.177329 8.64268C0.224502 8.52879 0.293645 8.42531 0.38081 8.33815C0.467975 8.25098 0.571455 8.18184 0.685342 8.13466C0.799228 8.08749 0.921291 8.06321 1.04456 8.06321L8.06215 8.06454L8.06083 1.04694C8.06067 0.923631 8.08484 0.801498 8.13196 0.687541C8.17908 0.573584 8.24822 0.470043 8.33541 0.382847C8.42261 0.295651 8.52615 0.226514 8.64011 0.179396C8.75407 0.132278 8.8762 0.108104 8.99951 0.10826Z" fill="#333333" />
                    </svg> */}
                    <img src={require('../../public/plusSign.png')} alt='' />
                </button>
            </div>

            <div className={group.success === false ? 'groups_small' : 'groups'} style={group.success === false ? { justifyContent: 'center' } : { justifyContent: 'space-between' }}>
                {
                    group.success === false
                        ?
                        <h1>У Вас нет группы</h1>
                        :
                        groups.map((e, i) => (
                            <div className='eachGroup' key={i} onClick={() => window.location = '/group'}>
                                <img src={e.img} alt={e.name} />
                                <span>{e.name}</span>
                            </div>
                        ))
                }
            </div>

            <div className='top'>
                <h1>Приглашения в группу</h1>
            </div>

            <div className='invitation'>
                {
                    groupRequest?.length !== 0 
                    ?
                        groupRequest.map((e, i) => (
                            <div className='eachInvitation' key={i}>
                                <div className='invitationTopPart'>
                                    <div className='requestLeftSide'>
                                        <img src={e.img} alt={e.group} />
                                        <span>{e.sender.name} пригласил(а) вас в группу ({e.group})</span>
                                    </div>
                                    <div className='invitationRightSide'>
                                        <div className='acc'></div>
                                        <div className='dec'></div>
                                    </div>
                                </div>
                                <div className='invitationBottomPart'>
                                    <button>Принять</button>
                                    <button>Отклонить</button>
                                </div>
                            </div>
                        ))
                        :
                        <h1>У Вас нет группы</h1>


                }
            </div>

        </div>
    )
}

