import { startLoading, successLoading } from "./loginRegisterAction";

const token = localStorage.getItem('token')

const allPosts = (payload) => {
    return {
        type: 'allPosts',
        payload,
    }
}

export const getAllPosts = () => {
    return async (dispatch) => {
        dispatch(startLoading())
        await fetch(`${process.env.REACT_APP_HOSTNAME}/allpost`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            }
        })
            .then(data => data.json())
            .then(res => {
                dispatch(successLoading())
                dispatch(allPosts(res))
            })
            .catch(err => {
                dispatch(successLoading())
                console.log('ERROR --->>>', err.message)
            })
    }
}

export const newPost = (data) => {
    return async (dispatch) => {
        // dispatch(startLoading())
        await fetch(`${process.env.REACT_APP_HOSTNAME}/post`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            },
            body: JSON.stringify({ description: data }),
        })
            .then(data => data.json())
            .then(res => {
                // dispatch(successLoading())
                // dispatch(post(res))
                if(res.success === true) {
                    window.location.reload(true)
                }
            })
            .catch(err => {
                // dispatch(successLoading())
                console.log('ERROR --->>>', err.message)
            })
    }
}

export const likePosts = (id) => {
    console.log('ID --->>>', id)
    return async (dispatch) => {
        await fetch(`${process.env.REACT_APP_HOSTNAME}/post-likes`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            },
            body: JSON.stringify({ post_id: id }),
        })
            .then(data => data.json())
            .then(res => {
                console.log('RESPONSE --->>>', res);
            })
            .catch(err => {
                console.log('ERROR --->>>', err.message)
            })
    }
}

export const commentPosts = (comment, id) => {
    return async (dispatch) => {
        dispatch(startLoading())
        await fetch(`${process.env.REACT_APP_HOSTNAME}/comment`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            },
            body: JSON.stringify({
                comment,
                post_id: id
            }),
        })
            .then(data => data.json())
            .then(res => {
                dispatch(successLoading())
                console.log('RESPONSE --->>>', res);
            })
            .catch(err => {
                dispatch(successLoading())
                console.log('ERROR --->>>', err.message)
            })
    }
}

export const likeComments = (id) => {
    return async (dispatch) => {
        await fetch(`${process.env.REACT_APP_HOSTNAME}/comment-likes`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            },
            body: JSON.stringify({
                comment_id: id
            }),
        })
            .then(data => data.json())
            .then(res => {
                console.log('RESPONSE --->>>', res);
            })
            .catch(err => {
                console.log('ERROR --->>>', err.message)
            })
    }
}

export const commentReply = (comment, id) => {
    return async (dispatch) => {
        dispatch(startLoading())
        await fetch(`${process.env.REACT_APP_HOSTNAME}/comment-reply`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            },
            body: JSON.stringify({
                comment_reply: comment,
                comment_id: id
            }),
        })
            .then(data => data.json())
            .then(res => {
                dispatch(successLoading())
                console.log('RESPONSE --->>>', res);
            })
            .catch(err => {
                dispatch(successLoading())
                console.log('ERROR --->>>', err.message)
            })
    }
}