import { startLoading, successLoading } from "./loginRegisterAction"

const token = localStorage.getItem('token')

const getUserData= (payload) => {
    return {
        type: 'getUser',
        payload,
    }
}

const addFriends= (payload) => {
    return {
        type: 'addFriends',
        payload,
    }
}

const deleteFriend= (payload) => {
    return {
        type: 'deleteFriends',
        payload,
    }
}

export const getUser = (id) => {
    return async (dispatch) => {
        dispatch(startLoading())
        await fetch(`${process.env.REACT_APP_HOSTNAME}/singlePageUser/user_id=${id}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            }
        })
            .then(data => data.json())
            .then(res => { 
                dispatch(successLoading())
                dispatch(getUserData(res)) 
            })
            .catch(err => {
                dispatch(successLoading())
                console.log('ERROR --->>>', err.message)
            })
    }
}

export const addFriend = (id) => {
    return async (dispatch) => {
        dispatch(startLoading())
        await fetch(`${process.env.REACT_APP_HOSTNAME}/add-friends`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            },
            body: JSON.stringify({receiver_id: id})
        })
            .then(data => data.json())
            .then(res => { 
                dispatch(successLoading())
                dispatch(addFriends(res)) 
            })
            .catch(err => {
                dispatch(successLoading())
                console.log('ERROR --->>>', err.message)
            })
    }
}

export const deleteFriends = (id) => {
    return async (dispatch) => {
        dispatch(startLoading())
        await fetch(`${process.env.REACT_APP_HOSTNAME}/delete-friend`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            },
            body: JSON.stringify({sender_id: id})
        })
            .then(data => data.json())
            .then(res => { 
                dispatch(successLoading())
                dispatch(deleteFriend(res)) 
            })
            .catch(err => {
                dispatch(successLoading())
                console.log('ERROR --->>>', err.message)
            })
    }
}