import { combineReducers } from 'redux'
import { loginRegister } from './loginRegister'
import { friendsReducer } from './friendsReducer'
import { settingsReducer } from './settingsReducer'
import { groupsReducer } from './groupReducer'
import { myProfileReducer } from './myProfileReducer'
import { chatReducer } from './chatReducer'
import { mainReducer } from './mainReducer'
import { userProfileReducer } from './userProfileReducer'

export default combineReducers({
    loginRegister,
    friendsReducer,
    settingsReducer,
    groupsReducer,
    myProfileReducer,
    chatReducer,
    mainReducer,
    userProfileReducer,
})
