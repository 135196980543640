import signUp from "../store/signUp";

export const mainReducer = (state = signUp, action) => {
    let temp = { ...state }
    switch (action.type) {

        case 'allPosts':
            console.log(action.payload)
            temp.user.allPosts = action.payload.date.data;
            break

        default:
            break;
    }
    return temp;
} 