import './style.css'

export const HamburgerMenuItem = ({ whiteIcon, blackIcon, title, active }) => {
    return (
        <div className='hamMenuItem' id={active ? 'active' : ''} >
            <div className='hamMenuIcon'>
                {active ? whiteIcon : blackIcon}
            </div>
            <div className='hamMenuTitle'>
                {title}
            </div>
        </div>
    )
}