import './style.css';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getChatMembers, getCurrentChatMember } from '../../Redux/action/chatAction';
import { CircleLoader } from 'react-spinners'

export const ChatLeftSide = () => {

    const dispatch = useDispatch() 
    const loading = useSelector(st => st.loginRegister.loading)


    function getWindowSize() {
        const { innerWidth, innerHeight } = window;
        return { innerWidth, innerHeight };
    }

    useEffect(() => {
        dispatch(getChatMembers())

        function handleWindowResize() {
            setWindowSize(getWindowSize());
        }

        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);

    const [managerMessageCount, setManagerMessageCount] = useState(null);
    const [windowSize, setWindowSize] = useState(getWindowSize());
    const navigate = useNavigate();
    const mediumChat = windowSize.innerWidth >= 768 && windowSize.innerWidth <= 1024;
    const bigChat = windowSize.innerWidth >= 768;

    const chatMembers = useSelector(st => st.chatReducer.user.chatMembers)

    return (
        <div className='chatLeftSide'>
            <h1>Сообщения</h1>
            <hr />
            <div className='chat'>
                <div className='chatStructure'>
                    {
                        chatMembers.map((e, i) => {
                            let sName;
                            mediumChat ?
                                sName = e?.surname.split('')[0] + '.'
                                :
                                sName = e?.surname
                            return (
                                <div className='eachChatMember' key={i} onClick={async () => {
                                    dispatch(getCurrentChatMember(e.receiver_id))
                                    bigChat ?
                                        navigate('', { state: { id: e.receiver_id } })
                                        :
                                        navigate(`/chat/${e.receiver_id}`, { state: { id: e.receiver_id } })
                                }}>
                                    <div className='memberLeftSide'>
                                        <img src={`${process.env.REACT_APP_SERVER_HOSTNAME}/${e?.user_image}`} alt='' style={e.count && mediumChat ? { position: 'relative' } : { position: 'inherit' }} />
                                        <span style={e.count && mediumChat ? { fontWeight: '600' } : { fontWeight: '400' }}>{e.user_name} {sName}</span>
                                    </div>
                                    <div className='memberRightSide' style={mediumChat ? { flexDirection: 'column-reverse' } : { flexDirection: 'column' }}>
                                        <span>{e.messTime}</span>
                                        <div className='newMess' style={mediumChat ? { display: 'none' } : { display: 'flex' }}>{e.count}</div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
                <div className='chatManager'>
                    <div className='eachChatMember' id='ChatManagerBorder'>
                        <div className='memberLeftSide'>
                            <img src={require('../../public/managerAvatar.png')} alt='manager' style={managerMessageCount && mediumChat ? { position: 'relative' } : { position: 'inherit' }} />
                            <span>Менеджер Ватан</span>
                        </div>
                        <div className='memberRightSide' style={mediumChat ? { flexDirection: 'column-reverse' } : { flexDirection: 'column' }}>
                            <span>18:00</span>
                            <div className='newMess' style={mediumChat ? { display: 'none' } : { display: 'flex' }}><span>{managerMessageCount}</span></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}