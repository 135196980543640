import './style.css'
import { Button } from '../../components/button';
import { Link, useNavigate } from 'react-router-dom';
import { Popup } from '../../components/popup';
import { useState } from 'react';

export const MyDocuments = () => {
    const navigate = useNavigate();

    const documents = [
        {
            title: 'Пасспорт',
            img: <img src={require('../../public/document1.png')} alt='document' />
        },
        {
            title: 'ВИД',
            img: <img src={require('../../public/document2.png')} alt='document' />
        },
        {
            title: 'Свидетельство о рождении',
            img: <img src={require('../../public/document3.png')} alt='document' />
        },
        {
            title: 'Пасспорт',
            img: <img src={require('../../public/document1.png')} alt='document' />
        },
        {
            title: 'Пасспорт',
            img: <img src={require('../../public/document1.png')} alt='document' />
        },
    ]

    const [newDoc, setNewDoc] = useState(false)
    const [successDoc, setSuccessDoc] = useState(false)

    function handleClick1() {
        setNewDoc(false)
        setSuccessDoc(true)
    }
    function handleClick2() {
        setSuccessDoc(false)
    }

    return (
        <div className='myDocumentsPage'>
            <Popup
                title={'Добавление документа'}
                text='Введите тип и выберите фотографию документа'
                inputFirst={'Тип документа'}
                image={require('../../public/addNewDocument.png')}
                button={'Добавить'}
                showPopup={newDoc}
                setShowPopup={setNewDoc}
                onClick={handleClick1}
            />
            <Popup
                title='Документ успешно добавлен'
                button='Закрыть'
                onClick={handleClick2}
                showPopup={successDoc}
                setShowPopup={setSuccessDoc}
            />
            <div className='top'>
                <h1>Мои документы</h1>
                <div className='serviceWhiteButtons'>
                    <Link to='/services'><button>Услуги</button></Link>
                    <Link to='/callHistory'><button>История обращений</button></Link>
                </div>
                <div className='mobileServiceBtns'>
                    <button onClick={() => window.location = '/services'}>
                        <svg width="25" height="25" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clipPath="url(#clip0_516_2535)">
                                <path d="M1.66602 12.4987H4.99935C4.99935 12.957 5.37435 13.332 5.83268 13.332C6.29102 13.332 6.66602 12.957 6.66602 12.4987H13.3327C13.3327 12.957 13.7077 13.332 14.166 13.332C14.6243 13.332 14.9993 12.957 14.9993 12.4987H18.3327V14.9987C18.3327 15.9154 17.5827 16.6654 16.666 16.6654H3.33268C2.41602 16.6654 1.66602 15.9154 1.66602 14.9987V12.4987ZM1.66602 12.4987V11.6654M1.66602 11.6654V8.33203C1.66602 7.41536 2.41602 6.66536 3.33268 6.66536H5.83268V4.9987C5.83268 4.08203 6.58268 3.33203 7.49935 3.33203H12.4993C13.416 3.33203 14.166 4.08203 14.166 4.9987V6.66536H16.666C17.5827 6.66536 18.3327 7.41536 18.3327 8.33203V11.6654H14.9993V10.832C14.9993 10.3737 14.6243 9.9987 14.166 9.9987C13.7077 9.9987 13.3327 10.3737 13.3327 10.832V11.6654H6.66602V10.832C6.66602 10.3737 6.29102 9.9987 5.83268 9.9987C5.37435 9.9987 4.99935 10.3737 4.99935 10.832V11.6654H1.66602ZM1.99935 12.6654V11.332M12.4993 6.66536H7.49935V5.9987C7.49935 5.44641 7.94706 4.9987 8.49935 4.9987H11.4993C12.0516 4.9987 12.4993 5.44641 12.4993 5.9987V6.66536Z" stroke="#333333" />
                            </g>
                            <defs>
                                <clipPath id="clip0_516_2535">
                                    <rect width="20" height="20" fill="white" />
                                </clipPath>
                            </defs>
                        </svg>
                    </button>
                    <button onClick={() => window.location = '/callHistory'}>
                        <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M14 26.25C11.5772 26.25 9.20877 25.5316 7.19427 24.1855C5.17977 22.8395 3.60965 20.9263 2.68248 18.6879C1.75531 16.4495 1.51272 13.9864 1.98539 11.6101C2.45805 9.23388 3.62475 7.05114 5.33795 5.33795C7.05114 3.62475 9.23388 2.45805 11.6101 1.98539C13.9864 1.51272 16.4495 1.75531 18.6879 2.68248C20.9263 3.60965 22.8395 5.17977 24.1855 7.19427C25.5316 9.20877 26.25 11.5772 26.25 14C26.25 17.2489 24.9594 20.3647 22.6621 22.6621C20.3647 24.9594 17.2489 26.25 14 26.25ZM14 3.50001C11.9233 3.50001 9.89323 4.11582 8.16652 5.26957C6.4398 6.42333 5.09399 8.06321 4.29927 9.98183C3.50455 11.9005 3.29662 14.0117 3.70176 16.0485C4.1069 18.0853 5.10693 19.9562 6.57538 21.4246C8.04384 22.8931 9.91476 23.8931 11.9516 24.2983C13.9884 24.7034 16.0996 24.4955 18.0182 23.7007C19.9368 22.906 21.5767 21.5602 22.7304 19.8335C23.8842 18.1068 24.5 16.0767 24.5 14C24.5 11.2152 23.3938 8.54452 21.4246 6.57538C19.4555 4.60625 16.7848 3.50001 14 3.50001Z" fill="#333333" />
                            <path d="M18.6334 18.6328C18.2926 18.9737 17.7399 18.9737 17.3991 18.6328L13.125 14.3587V7C13.125 6.51675 13.5168 6.125 14 6.125C14.4832 6.125 14.875 6.51675 14.875 7V13.6325L18.634 17.3991C18.9743 17.74 18.9741 18.2922 18.6334 18.6328Z" fill="#333333" />
                        </svg>
                    </button>
                </div>
            </div>

            <hr className='horizontal' />

            <div className='documentMainPage'>
                <div className='allDocuments'>
                    <div className='eachDocument' onClick={() => setNewDoc(true)}>
                        <span>Добавить новый</span>
                        <img id='addNewDocument' src={require('../../public/addNewDocument.png')} alt='document' onClick={() => navigate('#')} />
                    </div>
                    {
                        documents.map((e, i) => (
                            <div className='eachDocument' key={i}>
                                <span>{e.title}</span>
                                {e.img}
                            </div>
                        ))
                    }
                </div>
                <div className='callManager'>
                    <button>
                        
                        <span className='b'>Связатся с менеджером</span>
                    </button>
                    {/* <Button txt='Связатся с менеджером' width='38%' height='50px' fontSize='18px' navigation='/myDocuments' /> */}
                </div>
            </div>

        </div>
    )
}
