import signUp from "../store/signUp";

export const chatReducer = (state = signUp, action) => {
    let temp = { ...state }
    switch (action.type) {

        case 'chatMembers':
            temp.user.chatMembers = action.payload.userschatdata;
            break;

        case 'currentMember':
            console.log('PAYLOAD --->>>', action.payload);
            temp.user.allChatMessages = action.payload.data
            temp.user.currentChatMember = action.payload.receiver_user_data[0][0]
            temp.user.chatMemberId = action.payload.receiver_id
            break;

        default:
            break;
    }
    return temp;
} 