import signUp from "../store/signUp";

export const userProfileReducer = (state = signUp, action) => {
    let temp = { ...state }
    switch (action.type) {
        case 'getUser':
            console.log('PAYLOAD --->>>', action.payload)
            temp.randomUser.randomUserProfile = action.payload.data[0];
            temp.randomUser.randomUserPosts = action.payload.post
            temp.randomUser.isFriend = action.payload.friend
            break;

        case 'addFriends':
            console.log('PAYLOAD --->>>', action.payload);
            break;

        case 'deleteFriends':
            console.log('PAYLOAD --->>>', action.payload)
            if(action.payload.success === true) {
                window.location.reload(true)
            }
            break;


        default:
            break;
    }
    return temp;
}