import { startLoading, successLoading } from "./loginRegisterAction";

const token = localStorage.getItem('token')
const userId = localStorage.getItem('userId')

const newPhone = (payload) => {
    return {
        type: 'newPhone',
        payload,
    }
}

const confirmNewPhone = (payload) => {
    return {
        type: 'confirmNewPhone',
        payload,
    }
}

const editPhoneNumber = (payload) => {
    return {
        type: 'editPhoneNumber',
        payload,
    }
}

const confirmEditPhoneNumber = (payload) => {
    return {
        type: 'confirmEditPhoneNumber',
        payload,
    }
}

const addEmailAddress = (payload) => {
    return {
        type: 'addEmailAddress',
        payload,
    }
}

const confirmAddEmailAddress = (payload) => {
    return {
        type: 'confirmAddEmailAddress',
        payload,
    }
}

const editEmailAddress = (payload) => {
    return {
        type: 'editEmailAddress',
        payload,
    }
}

const confirmEditEmailAddress = (payload) => {
    return {
        type: 'confirmEditEmailAddress',
        payload,
    }
}

const editUsername = (payload) => {
    return {
        type: 'editUsername',
        payload,
    }
}

const editPassword = (payload) => {
    return {
        type: 'editPassword',
        payload,
    }
}

const hideProf = (payload) => {
    return {
        type: 'hideProf',
        payload,
    }
}

const deleteProf = (payload) => {
    return {
        type: 'deleteProf',
        payload,
    }
}

export const addEmail = (data) => {
    console.log('DATA --->>>', data)
    return async (dispatch) => {
        dispatch(startLoading())
        await fetch(`${process.env.REACT_APP_HOSTNAME}/send-email`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            },
            body: JSON.stringify({ email: data }),
        })
            .then(data => data.json())
            .then(res => {
                dispatch(successLoading())
                dispatch(addEmailAddress(res))
            })
            .catch(err => {
                dispatch(successLoading())
                console.log('ERROR --->>>', err.message)
            })
    }
}

export const confirmAddEmail = (data) => {
    console.log('DATA --->>>', data)
    return async (dispatch) => {
        dispatch(startLoading())
        await fetch(`${process.env.REACT_APP_HOSTNAME}/add-email`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            },
            body: JSON.stringify({ random_int: data, user_id: userId }),
        })
            .then(data => data.json())
            .then(res => {
                dispatch(successLoading())
                dispatch(confirmAddEmailAddress(res))
            })
            .catch(err => {
                dispatch(successLoading())
                console.log('ERROR --->>>', err.message)
            })
    }
}

export const editEmail = (data) => {
    console.log('DATA --->>>', data)
    return async (dispatch) => {
        dispatch(startLoading())
        await fetch(`${process.env.REACT_APP_HOSTNAME}/change-email`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            },
            body: JSON.stringify({ email: data }),
        })
            .then(data => data.json())
            .then(res => {
                dispatch(successLoading())
                dispatch(editEmailAddress(res))
            })
            .catch(err => {
                dispatch(successLoading())
                console.log('ERROR --->>>', err.message)
            })
    }
}

export const confirmEditEmail = (data) => {
    console.log('DATA --->>>', data)
    return async (dispatch) => {
        dispatch(startLoading())
        await fetch(`${process.env.REACT_APP_HOSTNAME}/update-email`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            },
            body: JSON.stringify({ random_int: data, user_id: userId }),
        })
            .then(data => data.json())
            .then(res => {
                dispatch(successLoading())
                dispatch(confirmEditEmailAddress(res))
            })
            .catch(err => {
                dispatch(successLoading())
                console.log('ERROR --->>>', err.message)
            })
    }
}

export const addPhone = (data) => {
    console.log('DATA --->>>', data)
    return async (dispatch) => {
        dispatch(startLoading())
        await fetch(`${process.env.REACT_APP_HOSTNAME}/send-number`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            },
            body: JSON.stringify({ number: data }),
        })
            .then(data => data.json())
            .then(res => {
                dispatch(successLoading())
                dispatch(newPhone(res))
            })
            .catch(err => {
                dispatch(successLoading())
                console.log('ERROR --->>>', err.message)
            })
    }
}

export const confirmPhoneCodes = (data) => {
    console.log('DATA --->>>', data)
    return async (dispatch) => {
        dispatch(startLoading())
        await fetch(`${process.env.REACT_APP_HOSTNAME}/add-number`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            },
            body: JSON.stringify({ random_int: data, user_id: userId }),
        })
            .then(data => data.json())
            .then(res => {
                dispatch(successLoading())
                dispatch(confirmNewPhone(res))
            })
            .catch(err => {
                dispatch(successLoading())
                console.log('ERROR --->>>', err.message)
            })
    }
}

export const editPhone = (data) => {
    console.log('DATA --->>>', data)
    return async (dispatch) => {
        dispatch(startLoading())
        await fetch(`${process.env.REACT_APP_HOSTNAME}/change-number`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            },
            body: JSON.stringify({ number: data }),
        })
            .then(data => data.json())
            .then(res => {
                dispatch(successLoading())
                dispatch(editPhoneNumber(res))
            })
            .catch(err => {
                dispatch(successLoading())
                console.log('ERROR --->>>', err.message)
            })
    }
}

export const confirmEditPhone = (data) => {
    console.log('DATA --->>>', data)
    return async (dispatch) => {
        dispatch(startLoading())
        await fetch(`${process.env.REACT_APP_HOSTNAME}/update-number`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            },
            body: JSON.stringify({ random_int: data, user_id: userId }),
        })
            .then(data => data.json())
            .then(res => {
                dispatch(successLoading())
                dispatch(confirmEditPhoneNumber(res))
            })
            .catch(err => {
                dispatch(successLoading())
                console.log('ERROR --->>>', err.message)
            })
    }
}

export const changeUsername = (data) => {
    console.log('DATA --->>>', data);
    return async (dispatch) => {
        dispatch(startLoading())
        await fetch(`${process.env.REACT_APP_HOSTNAME}/change-username`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: JSON.stringify({ username: data })
        })
            .then(data => data.json())
            .then(res => {
                dispatch(successLoading())
                dispatch(editUsername(res))
            })
            .catch(err => {
                dispatch(successLoading())
                console.log('ERROR --->>>', err.message)
            })
    }
}

export const changePassword = (newPass, oldPass) => {
    return async (dispatch) => {
        dispatch(startLoading())
        await fetch(`${process.env.REACT_APP_HOSTNAME}/change-password`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: JSON.stringify({ newpassword: newPass, oldpassword: oldPass })
        })
            .then(data => data.json())
            .then(res => {
                dispatch(successLoading())
                dispatch(editPassword(res))
            })
            .catch(err => {
                dispatch(successLoading())
                console.log('ERROR --->>>', err.message)
            })
    }
}

export const deleteAccount = (data) => {
    return async (dispatch) => {
        dispatch(startLoading())
        await fetch(`${process.env.REACT_APP_HOSTNAME}/users-delete/${userId}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: JSON.stringify({ password: data })
        })
            .then(data => data.json())
            .then(res => {
                dispatch(successLoading())
                dispatch(deleteProf(res))
            })
            .catch(err => {
                dispatch(successLoading())
                console.log('ERROR --->>>', err.message)
            })
    }
}

export const hidenAccount = (data) => {
    return async (dispatch) => {
        dispatch(startLoading())
        await fetch(`${process.env.REACT_APP_HOSTNAME}/hidden-account`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token },
            body: JSON.stringify({ password: data })
        })
            .then(data => data.json())
            .then(res => {
                dispatch(successLoading())
                dispatch(hideProf(res))
            })
            .catch(err => {
                dispatch(successLoading())
                console.log('ERROR --->>>', err.message)
            })
    }
}