const token = localStorage.getItem('token')

const getAllGroupsResponse = (payload) => {
    return {
        type: 'getAllGroupsResponse',
        payload,
    }
}
const getAllRequest = (payload) => {
    return {
        type: 'getAllRequest',
        payload,
    }
}

export const createGroup = (data) => {
    return async (dispatch) => {
        await fetch(`${process.env.REACT_APP_HOSTNAME}/create-group`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            },
            body: JSON.stringify({
                "name": "some",
                "image": 1
            }),
        })
            .then(data => data.json())
            .then(res => {
                console.log('RES --->>>', res)
            })
            .catch(err => console.log('ERROR --->>>', err.message))
    }
}



export const addGroup = () => {
    return async (dispatch) => {
        await fetch(`${process.env.REACT_APP_HOSTNAME}/add-group`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            },
            body: JSON.stringify(
                {
                    "receiver_id": 1
                }
            )
        })
            .then(data => data.json())
            .then(res => { console.log(res, 'add group') })
            .catch(err => console.log('ERROR --->>>', err.message))
    }
}



export const confirmGroupRequest = (data) => {
    return async (dispatch) => {
        await fetch(`${process.env.REACT_APP_HOSTNAME}/confirm-group-request`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            },
            body: JSON.stringify({ "sender_id": 1 })
        })
            .then(data => data.json())
            .then(res => { console.log(res, 'confirm group request') })
            .catch(err => console.log('ERROR --->>>', err.message))
    }
}



export const cancelGroupRequest = (data) => {
    return async (dispatch) => {
        await fetch(`${process.env.REACT_APP_HOSTNAME}/cancel-group-request`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            },
            body: JSON.stringify({ "sender_id": 1 })
        })
            .then(data => data.json())
            .then(res => { console.log(res, 'cancel group request') })
            .catch(err => console.log('ERROR --->>>', err.message))
    }
}





////receiverid stanal
export const leaveTheGroup = (data) => {
    return async (dispatch) => {
        await fetch(`${process.env.REACT_APP_HOSTNAME}/leave-the-group/receiverId`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            },
        })
            .then(data => data.json())
            .then(res => { console.log(res, 'leave the group') })
            .catch(err => console.log('ERROR --->>>', err.message))
    }
}

export const addBlackList = (data) => {
    return async (dispatch) => {
        await fetch(`${process.env.REACT_APP_HOSTNAME}/add-black-list`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            },
            body: JSON.stringify({
                "user_id": 1
            })
        })
            .then(data => data.json())
            .then(res => { console.log(res, 'add black list') })
            .catch(err => console.log('ERROR --->>>', err.message))
    }
}



export const getAllGroups = () => {
    return async (dispatch) => {
        await fetch(`${process.env.REACT_APP_HOSTNAME}/your-group`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            },
        })
            .then(data => data.json())
            .then(res => {
                dispatch(getAllGroupsResponse(res))
                console.log(res, 'get response all my groups')
            })
            .catch(err => console.log('ERROR --->>>', err.message))
    }
}



export const getGroupRequest = () => {
    return async (dispatch) => {
        await fetch(`${process.env.REACT_APP_HOSTNAME}/add-group`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            }
        })
            .then(data => data.json())
            .then(res => {
                dispatch(getAllRequest(res))
                console.log(res, 'get response all requests')
            })
            .catch(err => console.log('ERROR --->>>', err.message))
    }
}