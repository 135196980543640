import './style.css'
import React, { useContext, useState } from "react";
import ReactFlagsSelect from "react-flags-select";
import { Link, useNavigate } from 'react-router-dom';
import { Hamburger } from '../hamburger';
import Context from '../context/context';

export const Navigation = () => {
    const [selected, setSelected] = useState("RU");
    const user = localStorage.getItem('token');
    const context = useContext(Context);
    const openHamburger = context.openHamburger

    return (
        <div>
            <div className="navbar" style={user ? { width: '100%' } : { display: 'flex' }}>
                <div className="logo">
                    <Link to='/'>
                        <svg width="184" height="54" viewBox="0 0 184 54" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M21.4465 30.1043L24.7396 26.8112L21.4465 23.5181V18.8615H16.7899L13.4968 15.5684L10.2036 18.8615H5.54707V23.5181L2.25391 26.8112L5.54707 30.1043V34.7609H10.2036L13.4968 38.054L16.7899 34.7609H21.4465V30.1043Z" stroke="#FFFCEE" />
                            <path d="M46.9178 53.74H8.42378C4.21371 53.74 0.800781 50.3271 0.800781 46.117V7.623C0.800781 3.41293 4.21371 0 8.42378 0H46.9178C51.1278 0 54.5408 3.41293 54.5408 7.623V46.117C54.5408 50.3271 51.1278 53.74 46.9178 53.74Z" fill="#048E39" />
                            <path d="M44.6764 34.4118L51.8572 27.2287L44.6764 20.0478V9.89181H34.5182L27.3373 2.71094L20.1565 9.89181H10.0004V20.0478L2.81738 27.2287L10.0004 34.4118V44.5678H20.1565L27.3373 51.7486L34.5182 44.5678H44.6764V34.4118Z" stroke="#FFFCEE" />
                            <path d="M39.8047 23.8388L40.8327 27.0012L38.1429 25.0476L35.4531 27.0012H35.4509L36.4789 23.8388L33.7912 21.8873L33.7891 21.8851H37.1149L38.1363 18.7423L38.1407 18.727L38.1429 18.7227V18.7292L39.1687 21.8851H42.4945L39.8047 23.8388Z" fill="#F6E7B9" />
                            <path d="M38.1407 23.2715L40.8153 27.0068L38.1299 25.0487L38.1407 23.2715Z" fill="#F6E7B9" />
                            <path d="M22.6811 41.1854C21.6509 40.4667 20.8407 39.7719 20.2483 39.1011C19.6558 38.4302 19.2224 37.7398 18.948 37.0298C18.6736 36.3176 18.5037 35.54 18.4383 34.6928C18.373 33.8455 18.3403 32.8807 18.3403 31.7939V17.5084C18.6017 17.9505 18.9088 18.3033 19.2594 18.569C19.6101 18.8348 19.9651 19.07 20.3267 19.2747C20.6534 19.4468 20.9539 19.6341 21.2284 19.8388C21.5028 20.0436 21.7228 20.2962 21.8905 20.5989C22.0582 20.9017 22.1409 21.2937 22.1409 21.7751L22.154 34.0612C22.154 34.6819 22.2803 35.283 22.5352 35.8646C22.7878 36.4461 23.1929 36.8773 23.7483 37.1648C24.0924 37.3369 24.43 37.4218 24.7654 37.4218C25.1008 37.4218 25.4276 37.3369 25.7717 37.1648C26.3358 36.8795 26.7431 36.4461 26.9913 35.8646C27.2396 35.2852 27.366 34.6841 27.366 34.0612V25.0116C27.366 24.1534 27.4073 23.2082 27.4879 22.178C27.6927 19.7735 28.3417 18.0333 29.4372 16.9552C29.9687 16.4738 30.5698 16.0883 31.2406 15.803C32.1162 15.4349 32.9373 15.252 33.7061 15.252C34.475 15.252 35.1415 15.4044 35.6794 15.705C35.8994 15.8269 36.0453 15.938 36.115 16.036C36.1847 16.1341 36.2282 16.2233 36.2435 16.3061C35.3766 16.3061 34.695 16.3954 34.1962 16.5762C33.8521 16.7069 33.5014 16.8985 33.142 17.1533C32.334 17.7349 31.7938 18.5429 31.5238 19.5818C31.2864 20.4726 31.1687 21.6749 31.1687 23.1864C31.1774 24.4213 31.1818 27.4096 31.1818 32.1489C31.1818 33.203 30.9314 34.1614 30.4348 35.0238C29.936 35.8863 29.226 36.5876 28.3003 37.1256C27.2462 37.7463 26.179 38.3692 25.1009 38.99C24.4888 39.3167 23.9639 39.6521 23.5305 39.9962C23.0971 40.3403 22.8139 40.7367 22.6833 41.1854" fill="#FFFCEE" />
                            <path d="M81.6903 44.0994C80.0098 42.9257 78.6838 41.7895 77.7174 40.6983C76.751 39.6046 76.0419 38.4759 75.5949 37.3147C75.1479 36.1535 74.8707 34.8825 74.7633 33.5016C74.6559 32.1207 74.6035 30.5425 74.6035 28.767V5.44888C75.0305 6.17055 75.53 6.74739 76.1043 7.17939C76.6786 7.61389 77.258 7.99595 77.8448 8.33056C78.3792 8.61024 78.8686 8.91739 79.3156 9.252C79.7625 9.58662 80.1221 9.99865 80.3968 10.4931C80.669 10.9875 80.8064 11.6268 80.8064 12.4159L80.8263 32.4703C80.8263 33.4841 81.0336 34.4655 81.4481 35.4119C81.8626 36.3608 82.5219 37.0675 83.4284 37.5344C83.9877 37.8141 84.5421 37.954 85.0889 37.954C85.6358 37.954 86.1702 37.8141 86.7296 37.5344C87.651 37.0675 88.3152 36.3608 88.7223 35.4119C89.1293 34.4655 89.3341 33.4841 89.3341 32.4703V17.6998C89.3341 16.2989 89.4015 14.7582 89.5338 13.0751C89.866 9.15212 90.9272 6.3104 92.7152 4.54743C93.5817 3.76083 94.563 3.13405 95.6568 2.66708C97.0851 2.06777 98.4261 1.76562 99.6796 1.76562C100.933 1.76562 102.022 2.01285 102.901 2.50478C103.261 2.70455 103.498 2.88434 103.613 3.04415C103.725 3.20397 103.795 3.3513 103.822 3.48615C102.409 3.48615 101.293 3.63348 100.479 3.92564C99.9193 4.1404 99.345 4.45253 98.7582 4.86705C97.4372 5.81596 96.5557 7.13444 96.1162 8.82999C95.7292 10.2858 95.5344 12.2461 95.5344 14.7132C95.5469 16.7284 95.5544 21.6053 95.5544 29.3439C95.5544 31.0644 95.1474 32.6301 94.3333 34.0385C93.5192 35.4468 92.3581 36.5905 90.8498 37.472C89.1293 38.4858 87.3863 39.4997 85.6258 40.5135C84.6245 41.0479 83.7704 41.5948 83.0637 42.1541C82.3571 42.7135 81.8951 43.3602 81.6829 44.0944" fill="#1E1E1C" />
                            <path d="M117.459 40.838C116.058 40.838 114.959 40.4859 114.168 39.7768C113.374 39.0701 112.817 38.1037 112.497 36.8751C111.963 38.0637 111.254 39.0201 110.365 39.7468C109.478 40.4734 108.332 40.838 106.931 40.838C105.957 40.838 105.033 40.6557 104.159 40.2887C103.285 39.9216 102.494 39.3722 101.787 38.6381C101 37.799 100.409 36.8102 100.017 35.6765C99.622 34.5428 99.4272 33.3866 99.4272 32.213C99.4272 31.5063 99.4997 30.8046 99.647 30.1104C100.126 28.0827 101.008 26.4346 102.289 25.1661C103.57 23.8975 105.083 22.7913 106.834 21.8449C107.728 21.3505 108.674 20.856 109.675 20.3641C111.396 19.55 113.022 18.6411 114.55 17.6322C116.078 16.6234 117.174 15.3998 117.844 13.959V33.6738C117.844 34.3555 117.896 34.9149 118.003 35.3543C118.111 35.7938 118.325 36.121 118.653 36.3357C118.98 36.5505 119.457 36.6554 120.083 36.6554C120.71 36.6554 121.265 36.4656 121.704 36.0835C122.144 35.7039 122.486 35.227 122.725 34.6527C122.965 34.0783 123.105 33.479 123.145 32.8522H124.945C124.731 34.6127 124.331 36.0161 123.744 37.0549C123.265 37.9489 122.675 38.703 121.984 39.3173C120.863 40.3311 119.354 40.838 117.461 40.838M108.634 36.7153C108.994 36.7153 109.388 36.6154 109.815 36.4156C110.482 36.0685 110.951 35.5541 111.226 34.8749C111.501 34.1932 111.636 33.4865 111.636 32.7523V22.1446L110.554 22.7638C108.926 23.6978 107.76 24.6916 107.051 25.7454C106.13 27.1188 105.67 29.0067 105.67 31.4089C105.67 33.3841 106.097 34.8849 106.951 35.9137C107.391 36.4481 107.952 36.7128 108.632 36.7128" fill="#1E1E1C" />
                            <path d="M129.028 40.7978C126.813 40.7978 125.263 40.186 124.374 38.9674C123.485 37.7463 123.043 36.1282 123.043 34.113V20.1216H120.581L121 19.1802C121.467 18.3386 122.146 17.9191 123.04 17.9191V16.9977C123.04 15.4645 123.742 14.2633 125.143 13.3943L125.805 13.0348C126.511 12.6602 127.153 12.2606 127.725 11.8336C128.299 11.4066 128.806 10.8073 129.246 10.0332V17.9191H132.307L131.888 18.8406C131.433 19.6946 130.766 20.1216 129.885 20.1216H129.243V33.631C129.243 34.7797 129.4 35.5638 129.713 35.9833C130.027 36.4029 130.617 36.6126 131.483 36.6126C132.35 36.6126 133.054 36.248 133.596 35.5214C134.135 34.7947 134.452 33.8907 134.547 32.8095H136.347C135.908 35.9983 134.859 38.2058 133.206 39.4343C132.005 40.3433 130.612 40.7953 129.023 40.7953" fill="#1E1E1C" />
                            <path d="M152.346 40.838C150.945 40.838 149.847 40.4859 149.053 39.7768C148.258 39.0701 147.702 38.1037 147.382 36.8751C146.848 38.0637 146.138 39.0201 145.249 39.7468C144.363 40.4734 143.217 40.838 141.816 40.838C140.842 40.838 139.918 40.6557 139.044 40.2887C138.17 39.9216 137.379 39.3722 136.672 38.6381C135.885 37.799 135.293 36.8102 134.901 35.6765C134.507 34.5428 134.312 33.3866 134.312 32.213C134.312 31.5063 134.384 30.8046 134.532 30.1104C135.014 28.0827 135.893 26.4346 137.174 25.1661C138.455 23.8975 139.968 22.7913 141.719 21.8449C142.612 21.3505 143.559 20.856 144.56 20.3641C146.281 19.55 147.906 18.6411 149.435 17.6322C150.963 16.6234 152.059 15.3998 152.726 13.959V33.6738C152.726 34.3555 152.778 34.9149 152.886 35.3543C152.993 35.7938 153.21 36.121 153.535 36.3357C153.862 36.5505 154.339 36.6554 154.966 36.6554C155.593 36.6554 156.147 36.4656 156.586 36.0835C157.026 35.7039 157.368 35.227 157.608 34.6527C157.847 34.0783 157.987 33.479 158.027 32.8522H159.828C159.613 34.6127 159.213 36.0161 158.627 37.0549C158.147 37.9489 157.558 38.703 156.866 39.3173C155.745 40.3311 154.237 40.838 152.344 40.838M143.516 36.7153C143.876 36.7153 144.271 36.6154 144.698 36.4156C145.364 36.0685 145.836 35.5541 146.108 34.8749C146.383 34.1932 146.518 33.4865 146.518 32.7523V22.1446L145.437 22.7638C143.809 23.6978 142.64 24.6916 141.933 25.7454C141.012 27.1188 140.552 29.0067 140.552 31.4089C140.552 33.3841 140.979 34.8849 141.833 35.9137C142.273 36.4481 142.835 36.7128 143.514 36.7128" fill="#1E1E1C" />
                            <path d="M164.236 37.1342C164.236 37.9332 164.096 38.5825 163.816 39.0744C163.536 39.5689 163.177 39.9784 162.735 40.3055C162.293 40.6326 161.806 40.9423 161.274 41.2369C160.675 41.5566 160.09 41.9336 159.523 42.3681C158.957 42.8026 158.452 43.3795 158.013 44.0986V29.8675C158.093 27.6651 158.649 25.8746 159.683 24.4937C160.717 23.1128 161.848 22.0415 163.077 21.2824C163.759 20.8429 164.53 20.3884 165.399 19.9215C166.493 19.3471 167.499 18.7378 168.421 18.0911C169.342 17.4443 169.929 16.7401 170.181 15.9785C172.691 17.714 174.346 19.4171 175.155 21.0926C175.962 22.7682 176.367 24.8533 176.367 27.3479C176.394 28.6015 176.406 29.8625 176.406 31.1311V33.6532C176.406 34.3349 176.459 34.8942 176.566 35.3337C176.674 35.7732 176.886 36.1003 177.206 36.3151C177.525 36.5274 178.007 36.6347 178.646 36.6347C179.286 36.6347 179.825 36.445 180.257 36.0654C180.692 35.6858 181.029 35.2089 181.268 34.6345C181.508 34.0602 181.655 33.4609 181.708 32.8341H183.508C183.269 34.5946 182.867 35.998 182.307 37.0368C181.825 37.9307 181.243 38.6849 180.557 39.2992C179.87 39.9135 179.071 40.3255 178.164 40.5402C177.418 40.715 176.676 40.7999 175.942 40.7999C175.033 40.7999 174.192 40.6551 173.41 40.3704C172.631 40.0833 171.999 39.6188 171.52 38.9795C170.638 37.751 170.199 36.1378 170.199 34.1351V25.9695C170.199 25.2628 170.051 24.5761 169.759 23.9069C169.467 23.2402 168.998 22.7457 168.358 22.4261C167.984 22.2263 167.597 22.1264 167.197 22.1264C166.838 22.1264 166.45 22.2263 166.036 22.4261C165.397 22.7457 164.932 23.2402 164.645 23.9069C164.358 24.5736 164.216 25.2603 164.216 25.9695L164.236 32.2947V37.1391V37.1342Z" fill="#1E1E1C" />
                        </svg>

                    </Link>
                </div>

                <div className='navRightPart'>
                    <div className='userBalanceAndProfilePicture' id={user ? '' : 'user'}>
                        <div className='userBalance'>
                            <h1>Баланс: <span>300 руб.</span></h1>
                        </div>
                        <div className='userProfile' onClick={() => window.location = '/myProfile'}>
                            <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect width="38" height="38" rx="19" fill="#048E39" />
                                <path d="M23.375 15.25C23.375 16.4103 22.9141 17.5231 22.0936 18.3436C21.2731 19.1641 20.1603 19.625 19 19.625C17.8397 19.625 16.7269 19.1641 15.9064 18.3436C15.0859 17.5231 14.625 16.4103 14.625 15.25C14.625 14.0897 15.0859 12.9769 15.9064 12.1564C16.7269 11.3359 17.8397 10.875 19 10.875C20.1603 10.875 21.2731 11.3359 22.0936 12.1564C22.9141 12.9769 23.375 14.0897 23.375 15.25ZM12.75 27.125H25.875C26.0408 27.125 26.1997 27.0592 26.3169 26.9419C26.4342 26.8247 26.5 26.6658 26.5 26.5V24.625C26.5 23.7962 26.1708 23.0013 25.5847 22.4153C24.9987 21.8292 24.2038 21.5 23.375 21.5H14.625C13.7962 21.5 13.0013 21.8292 12.4153 22.4153C11.8292 23.0013 11.5 23.7962 11.5 24.625V26.5C11.5 26.6658 11.5658 26.8247 11.6831 26.9419C11.8003 27.0592 11.9592 27.125 12.125 27.125H12.75Z" fill="white" />
                            </svg>

                        </div>
                    </div>
                    <div className='kkkkkkk'>
                        <div className='flag'>
                            <ReactFlagsSelect
                                countries={["RU", "TJ", "UZ", "KG"]}
                                selected={selected}
                                onSelect={(code) => setSelected(code)}
                                optionsSize={14}
                                selectedSize={16}
                                className='countries'
                                showOptionLabel={false}
                                showSelectedLabel={false}
                            />
                        </div>
                    </div>
                </div>

                <div className='mobileMenu' onClick={() => context.setOpenHamburger(true)}>
                    <svg width="18" height="15" viewBox="0 0 18 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1.125 1.375H16.875M1.125 13.375H16.875H1.125ZM1.125 7.375H16.875H1.125Z" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                </div>
            </div>

            <div className={openHamburger ? 'hmbg' : 'closeHmbg'}>
                <Hamburger />
            </div>

        </div>

    )
}   
