import './style.css';
import Context from '../../components/context/context';
import { Posts } from '../../components/post';
import { WebTextarea } from '../../components/webTextarea';
import { useContext } from 'react';
import { MobileTextarea } from '../../components/mobileTextarea';

export const Main = () => {

    const events = [
        {
            img: require('../../public/avatar4.png'),
            name: 'Александра',
            time: '2',
            navigate: '/profile',
        },
        {
            img: require('../../public/russia.png'),
            name: 'День народного единства',
            time: '4',
            navigate: '/event',
        },
        {
            img: require('../../public/avatar2.png'),
            name: 'Алексей',
            time: '5',
            navigate: '/profile',
        },
        {
            img: require('../../public/tj.png'),
            name: 'День Конституции Таджикистана',
            time: '8',
            navigate: '/event',
        },
        {
            img: require('../../public/avatar3.png'),
            name: 'Пётр',
            time: '8',
            navigate: '/profile',
        },
        {
            img: require('../../public/avatar4.png'),
            name: 'Александра',
            time: '2',
            navigate: '/profile',
        },
        {
            img: require('../../public/russia.png'),
            name: 'День народного единства',
            time: '4',
            navigate: '/event',
        },
        {
            img: require('../../public/avatar2.png'),
            name: 'Алексей',
            time: '5',
            navigate: '/profile',
        },
        {
            img: require('../../public/tj.png'),
            name: 'День Конституции Таджикистана',
            time: '8',
            navigate: '/event',
        },
        {
            img: require('../../public/avatar3.png'),
            name: 'Пётр',
            time: '8',
            navigate: '/profile',
        },
    ]

    const context = useContext(Context)
    const tablet = context.tablet

    return (
        <div className='mainPage'>
            <h1>Главная</h1>
            <div className='events'>
                <h1>Дни рождения и Праздники</h1>
                <div className='birthdaysAndEvents'>
                    {
                        events.map((e, i) => (
                            <div className='eachEvent' key={i} onClick={() => window.location = e.navigate}>
                                <div className='eventRightSide'>
                                    <img src={e.img} alt={e.name} />
                                </div>
                                <div className='eventRightSide'>
                                    <h1>{e.name}</h1>
                                    <span>через {e.time} дня</span>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>

            <div className='newPost'>
                <h1>Поделитесь мыслями...</h1>
                {
                    tablet ?
                        <MobileTextarea />
                        :
                        <WebTextarea />
                }
            </div>

            <div className='mainPagePosts'>
                <Posts />
            </div>

        </div>
    )
}
