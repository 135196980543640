export const Button = ({
    txt,
    width = '40%',
    height = '30px',
    background = '#048E39',
    color = '#fff',
    fontSize = '14px',
    navigation = '#',
    border = '2px solid #048e39',
    borderRadius = '8px',
    padding,
    onClick
}) => {
    const style = {
        width,
        height,
        background,
        color,
        fontSize,
        navigation,
        border,
        borderRadius,
        fontFamily: 'Montserrat',
        padding,
    }

    return (
        <button style={style} id='greenBtn' onClick={() => {
            window.location = navigation
            onClick && onClick()
        }}>
            {txt}
        </button>
    )
}
