import './style.css';
import { Button } from '../../components/button'
import { Popup } from '../../components/popup';
import { useState } from 'react';

export const Wallet = () => {

    const transaction = [
        {
            action: 'Пополнение кошелька',
            price: '300 руб.',
            date: '13.10.2022'
        },
        {
            action: `Покупка услуги (Сдача русского языка) `,
            price: '2500 руб.',
            date: '09.10.2022'
        },
        {
            action: `Покупка услуги (Сдача русского языка) `,
            price: '2500 руб.',
            date: '09.10.2022'
        },
        {
            action: 'Пополнение кошелька',
            price: '300 руб.',
            date: '13.10.2022'
        },
        {
            action: `Покупка услуги (Сдача русского языка) `,
            price: '2500 руб.',
            date: '09.10.2022'
        },
        {
            action: `Покупка услуги (Сдача русского языка) `,
            price: '2500 руб.',
            date: '09.10.2022'
        },
        {
            action: `Покупка услуги (Сдача русского языка) `,
            price: '2500 руб.',
            date: '09.10.2022'
        },
        {
            action: `Покупка услуги (Сдача русского языка) `,
            price: '2500 руб.',
            date: '09.10.2022'
        },
    ]
    const [confirmFill, setConfirmFill] = useState(false)
    const [successFill, setSuccessFill] = useState(false)

    function handleClick1() {
        setConfirmFill(false)
        setSuccessFill(true)
    }
    function handleClick2() {
        setConfirmFill(false)
    }
    function handleClick3() {
        setSuccessFill(false)
        setConfirmFill(false)
    }

    return (
        <div>
            <Popup
                title='Вы действительно хотите пополнить баланс?'
                button='Подтвердить'
                transparentButton='Отменить'
                transBg='none'
                onClick={handleClick1}
                onClick2={handleClick2}
                showPopup={confirmFill}
                setShowPopup={setConfirmFill}
            />
            <Popup
                title='Вы успешно пополнили баланс'
                button='Закрыть'
                onClick={handleClick3}
                showPopup={successFill}
                setShowPopup={setSuccessFill}
            />

            <div className='walletBalance'>
                <div className='walletLeftSide'>
                    <h5>Баланс <span>кошелька</span></h5>
                    <span>300 руб.</span>
                </div>
                <div className='walletRightSide' onClick={() => setConfirmFill(true)}>
                    <Button txt='Пополнить' width='95%' height='46px' fontSize='18px' />
                </div>
            </div>

            <div className='transactionHistory'>
                <h5>История транзакий</h5>
                {
                    transaction.map((e, i) => (
                        <div className='everyTransaction' key={i}>
                            <div className='transactionLeftSide'>
                                <span>{e.action}</span>
                                <h5>{e.price}</h5>
                                <h6>{e.date}</h6>
                            </div>
                            <div className='transactionRightSide'>
                                <span><b>{e.date}</b></span>
                                <h6><b>{e.price}</b></h6>
                            </div>
                        </div>
                    ))
                }
            </div>
        </div>
    )
} 
