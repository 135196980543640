const token = localStorage.getItem('token')
const userId = localStorage.getItem('userId')

const myFriendRequests = (payload) => {
    return {
        type: 'getFriendRequests',
        payload,
    }
}

const myFriends = (payload) => {
    return {
        type: 'getFriends',
        payload,
    }
}

export const getFriendRequests = () => {
    return async (dispatch) => {
        await fetch(`${process.env.REACT_APP_HOSTNAME}/add-friends`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            },
        })
            .then(data => data.json())
            .then(res => {
                dispatch(myFriendRequests(res))
            })
            .catch(err => console.log('ERROR --->>>', err.message))
    }
}

export const confirmFriendRequest = (data) => {
    console.log('DATA --->>>', data)
    return async (dispatch) => {
        await fetch(`${process.env.REACT_APP_HOSTNAME}/confirm-request`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            },
            body: JSON.stringify({ sender_id: data }),
        })
            .then(data => data.json())
            .then(res => {
                console.log('RES --->>>', res)
            })
            .catch(err => console.log('ERROR --->>>', err.message))
    }
}

export const cancelFriendRequest = (data) => {
    console.log('DATA --->>>', data)
    return async (dispatch) => {
        await fetch(`${process.env.REACT_APP_HOSTNAME}/cancel-request`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            },
            body: JSON.stringify({ sender_id: data }),
        })
            .then(data => data.json())
            .then(res => {
                console.log('RES --->>>', res)
                getFriendRequests()
            })
            .catch(err => console.log('ERROR --->>>', err.message))
    }
}


export const getFriends = () => {
    return async (dispatch) => {
        await fetch(`${process.env.REACT_APP_HOSTNAME}/all-friends`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            },
        })
            .then(data => data.json())
            .then(res => {
                dispatch(myFriends(res))
            })
            .catch(err => console.log('ERROR --->>>', err.message))
    }
}