import { useContext } from 'react'
import { useState } from 'react'
import { Button } from '../button'
import Context from '../context/context'
import { CreateGroup } from '../createNewGroup'
import './style.css'

export const NewGroupPopup = ({
    showPop,
    setShowPop
}) => {

    const groups = [
        {
            img: require('../../public/request1.png'),
            name: 'Семья Буракшаева',
        },
        {
            img: require('../../public/request2.png'),
            name: 'Семья Буракшаева',
        },
        {
            img: require('../../public/request3.png'),
            name: 'Семья Буракшаева',
        },
        {
            img: require('../../public/request4.png'),
            name: 'Семья Буракшаева',
        },
        {
            img: require('../../public/request5.png'),
            name: 'Семья Буракшаева',
        },
        {
            img: require('../../public/request6.png'),
            name: 'Семья Буракшаева',
        },
        {
            img: require('../../public/request1.png'),
            name: 'Семья Буракшаева',
        },
        {
            img: require('../../public/request2.png'),
            name: 'Семья Буракшаева',
        },
        {
            img: require('../../public/request3.png'),
            name: 'Семья Буракшаева',
        },
        {
            img: require('../../public/request4.png'),
            name: 'Семья Буракшаева',
        },
        {
            img: require('../../public/request5.png'),
            name: 'Семья Буракшаева',
        },
        {
            img: require('../../public/request6.png'),
            name: 'Семья Буракшаева',
        },
    ]

    const [checkBg, setCheckBg] = useState(false)
    const [chooseMembers, setChooseMembers] = useState(false)

    const context = useContext(Context)
    const tablet = context.tablet;

    const token = localStorage.getItem('token')

    if (!token && showPop) {
        document.querySelector('.container').style.position = 'fixed'
    }
    if (token && showPop) {
        document.querySelector('.layout').style.position = 'fixed'
    }

    return (
        <div className={showPop ? 'activeModalParent' : 'modalParent'} onClick={(e) => {
            setShowPop(false)
            if (!token && showPop) {
                document.querySelector('.container').style.position = 'relative'
            }
            if (token && showPop) {
                document.querySelector('.layout').style.position = 'relative'
            }
        }}>

            <div className='pop' onClick={(e) => e.stopPropagation()}>

                <div className='closeIcon' onClick={() => {
                    setShowPop(false)
                    if (!token && showPop) {
                        document.querySelector('.container').style.position = 'relative'
                    }
                    if (token && showPop) {
                        document.querySelector('.layout').style.position = 'relative'
                    }
                }}>
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0.419604 0.419604C0.552277 0.286595 0.709888 0.181067 0.883407 0.109064C1.05693 0.0370616 1.24295 0 1.43081 0C1.61868 0 1.8047 0.0370616 1.97822 0.109064C2.15174 0.181067 2.30935 0.286595 2.44202 0.419604L10.0004 7.98082L17.5587 0.419604C17.6915 0.286811 17.8492 0.181473 18.0227 0.109605C18.1962 0.0377376 18.3821 0.00074779 18.5699 0.00074779C18.7577 0.00074779 18.9437 0.0377376 19.1172 0.109605C19.2907 0.181473 19.4484 0.286811 19.5811 0.419604C19.7139 0.552398 19.8193 0.710047 19.8911 0.88355C19.963 1.05705 20 1.24301 20 1.43081C20 1.61861 19.963 1.80457 19.8911 1.97808C19.8193 2.15158 19.7139 2.30923 19.5811 2.44202L12.0199 10.0004L19.5811 17.5587C19.7139 17.6915 19.8193 17.8492 19.8911 18.0227C19.963 18.1962 20 18.3821 20 18.5699C20 18.7577 19.963 18.9437 19.8911 19.1172C19.8193 19.2907 19.7139 19.4484 19.5811 19.5811C19.4484 19.7139 19.2907 19.8193 19.1172 19.8911C18.9437 19.963 18.7577 20 18.5699 20C18.3821 20 18.1962 19.963 18.0227 19.8911C17.8492 19.8193 17.6915 19.7139 17.5587 19.5811L10.0004 12.0199L2.44202 19.5811C2.30923 19.7139 2.15158 19.8193 1.97808 19.8911C1.80457 19.963 1.61861 20 1.43081 20C1.24301 20 1.05705 19.963 0.88355 19.8911C0.710047 19.8193 0.552398 19.7139 0.419604 19.5811C0.286811 19.4484 0.181473 19.2907 0.109605 19.1172C0.0377376 18.9437 0.00074779 18.7577 0.00074779 18.5699C0.00074779 18.3821 0.0377376 18.1962 0.109605 18.0227C0.181473 17.8492 0.286811 17.6915 0.419604 17.5587L7.98082 10.0004L0.419604 2.44202C0.286595 2.30935 0.181067 2.15174 0.109064 1.97822C0.0370616 1.8047 0 1.61868 0 1.43081C0 1.24295 0.0370616 1.05693 0.109064 0.883407C0.181067 0.709888 0.286595 0.552277 0.419604 0.419604Z" fill="#333333" />
                    </svg>
                </div>

                <h2 className="windowTitle">Создание новой группы</h2>

                <div>
                    <CreateGroup />

                    <div className='reqMrg'>
                        <div className='requestsss'>
                            <div className='i'><h1>Выберите участников группы</h1></div>
                            {
                                groups.map((e, i) => (
                                    <div className='eachRequesttt' key={i}>
                                        <div className='requestLeftSidee'>
                                            <img src={e.img} alt={e.name} />
                                            <span>{e.name}</span>
                                        </div>
                                        <div className='requestRightSidee'>
                                            <div className='checkbox'>
                                                <input type='checkbox' value={chooseMembers} onChange={e => setChooseMembers(e.target.checked)} />
                                            </div>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </div>

                <div className='reqMrg'>
                    <Button
                        txt='Создать группу'
                        width='100%'
                        height="50px"
                        fontSize={tablet ? '16px' : '20px'}
                        padding={'0 35px'}
                    />
                </div>

                <div className='popCheck'>
                    <div className='chh' onClick={() => setCheckBg(!checkBg)} style={checkBg ? { background: 'none' } : { background: '#048E39' }}>
                        <svg width="15" height="12" viewBox="0 0 17 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd" d="M16.6442 0.393613C16.872 0.64572 17 0.987605 17 1.34408C17 1.70056 16.872 2.04245 16.6442 2.29456L6.06192 14L0.341169 7.67204C0.119779 7.41849 -0.00272318 7.0789 4.59442e-05 6.72641C0.00281507 6.37392 0.130638 6.03674 0.355977 5.78748C0.581317 5.53822 0.886147 5.39683 1.20481 5.39377C1.52348 5.39071 1.83048 5.52621 2.05971 5.7711L6.06192 10.1981L14.9256 0.393613C15.1535 0.141582 15.4626 0 15.7849 0C16.1072 0 16.4162 0.141582 16.6442 0.393613Z" fill="white" />
                        </svg>
                    </div>
                    <label>Запомнить дату создания группы</label>
                </div>

            </div>

        </div>
    )
}