import './style.css';

export const Event = () => {
    return (
        <div>
            <div className='top'>
                <h1>Праздники</h1>
            </div>
            <div className='event'>
                <div className='eventImg'>
                    <img src={require('../../public/event.png')} alt='' />
                </div>
                <div className='top'>
                    <h1>День Конституции Республики Таджикистан</h1>
                </div>
                <div className='top' id='eventDate'>
                    <h1>6 ноября</h1>
                </div>
                <div className='eventText'>
                    <p>6 ноября 1994 года впервые в новейшей истории Республики Таджикистан состоялся всенародный референдум по принятию Конституции Республики Таджикистан. В 
                        последующие годы, в 1999, 2003 и 2016 годах в Конституцию Республики Таджикистан 
                        путём референдумов были внесены поправки и дополнения[2].

                        По Закону Республики Таджикистан «О праздничных днях» (редакция закона № 628 от 22 
                        мая 1998 г.) 6 ноября таджикский народ широко отмечает национальный праздник — 
                        День Конституции Республики Таджикистан.

                        В этот день в соответствии с Положением «О государственном флаге Республики 
                        Таджикистан» поднимается Государственный флаг Республики Таджикистан. По случаю 
                        «Дня Конституции Республики Таджикистан» по инициативе государственных органов, 
                        общественных организаций и трудовых коллективов проводятся мероприятия 
                        общественно-политического характера.

                        6 ноября является в Таджикистане праздничным нерабочим днём.</p>
                </div>
            </div>
        </div>
    )
} 