import moment from "moment/moment";
import 'moment/locale/ru'
import { useState } from "react";
import { commentReply, likeComments } from "../../Redux/action/mainAction";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "../button";
import { MyComment } from "../myComment";

export const EachComment = ({ item }) => {

    const dispatch = useDispatch()
    const me = useSelector(st => st?.userProfileReducer?.randomUser?.randomUserProfile)
    const [likeComment, setlikeComment] = useState([])
    const [reply, setReply] = useState([])
    const [commentOnPost, setCommentOnPost] = useState('');
    const [commentPost, setCommentPost] = useState([]);
    const [com, setCom] = useState([]);
    const [components, setComponents] = useState([]);

    const onClickLike = (comment) => {
        let index = likeComment.findIndex((x) => x === comment.id);
        if (index >= 0) {
            likeComment.splice(index, 1);
        } else {
            likeComment.push(comment.id)
        };
        setlikeComment([...likeComment]);
        dispatch(likeComments(comment.id))
    };

    const onClickCommentReply = async (comment) => {
        if (commentOnPost.length > 0) {
            commentPost.push(comment.id)
            setCommentPost([...commentPost]);
            dispatch(commentReply(commentOnPost, comment.id))

            let index = com.findIndex((x) => x === comment.id);
            if (index >= 0) {
                com.splice(index, 1);
            } else {
                com.push(comment.id)
            };
            setCom([...com]);
            setComponents([...components, commentOnPost])
        }
    };

    return (
        <div>
            {
                item?.map((elm, index) => (
                    <div style={{ marginLeft: `${elm.count * 20}px` }} key={index} className="eachComment" >
                        <div className="commentTop">
                            <div className="commentPic" onClick={() => {
                                elm?.user?.id === localStorage.getItem('userId')
                                    ? window.location = (`/myProfile`)
                                    : window.location = (`/profile/${elm?.user?.id}`)
                            }}>
                                <img src={`${process.env.REACT_APP_SERVER_HOSTNAME}/${elm.user?.avatar}`} alt={elm?.user?.name} />
                            </div>

                            <div className="mainComment">
                                <span onClick={() => {
                                    elm?.user?.id === localStorage.getItem('userId')
                                        ? window.location = (`/myProfile`)
                                        : window.location = (`/profile/${elm?.user?.id}`)
                                }}>{elm.user?.name}</span>
                                <p>{elm.data?.comment}</p>
                            </div>
                        </div>
                        <div className="likeComment">
                            <div className="likeLeftPart">
                                <span
                                    className="kk"
                                    onClick={onClickLike.bind(this, elm.data)}
                                    style={
                                        likeComment.some((x) => x === elm.data.id) ? { color: "#048E39" } : { color: "#787878" }
                                    }
                                >Нравится</span>
                                <span className="kk" onClick={() => setReply(!reply)}>Ответить</span>
                                <span>{moment.utc(elm?.data.created_at).local().startOf('seconds').fromNow()}</span>
                            </div>
                            {/* <div className="likeRightPart">
                                <span>Ответы</span>
                            </div> */}
                        </div>
                      
                        {
                            !reply &&
                            <div className="addComment">
                                <div className="myCom">
                                    <img src={`${process.env.REACT_APP_SERVER_HOSTNAME}/${me.avatar}`} alt='' />
                                </div>

                                <input placeholder="Написать комментарий..." onChange={(e) => setCommentOnPost(e.target.value)} />
                                <div onClick={onClickCommentReply.bind(this, elm.data)}> <Button txt="Отправить" width="121px" height="32px" /> </div>
                            </div>
                        }
                    </div>
                ))
            }
        </div>
    )
}