import './style.css';
import { Link } from 'react-router-dom';
import { Popup } from '../../components/popup';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addFriend, deleteFriends, getUser } from '../../Redux/action/userProfileAction';
import { UserPosts } from '../../components/userPosts';

export const FriendsProfile = () => {
    const dispatch = useDispatch()
    const user = useSelector(st => st.userProfileReducer.randomUser.randomUserProfile)
    const isFriend = useSelector(st => st.userProfileReducer.randomUser.isFriend)
    let userId = window.location.pathname.split('/')[2]
    
    useEffect(() => {
        dispatch(getUser(userId))
    }, [])

    const [deleteFriend, setDeleteFriend] = useState(false)
    const [invite, setInvite] = useState(false)
    const [svgColor, setSvgColor] = useState(false)

    function handleClick1() {
        dispatch(deleteFriends(userId))
        setDeleteFriend(false)
    }
    function handleClick2() {
        setDeleteFriend(false)
    }

    return (
        <div>
            <Popup
                title={'Вы действительно хотите удалить Алексея из списка друзей?'}
                button={'Удалить'}
                buttonBg='#CE2028'
                transparentButton={'Отменить'}
                transBorder='3px solid #333333'
                transBg='none'
                onClick={handleClick1}
                onClick2={handleClick2}
                showPopup={deleteFriend}
                setShowPopup={setDeleteFriend}
            />
            <Popup
                title='Выберите группу в которую хотите пригласить пользователя'
                invitation
                showPopup={invite}
                setShowPopup={setInvite}
            />

            {
                isFriend.length ?
                    <>
                        <div className='mecDivv'>
                            <div className='bgDiv'>
                                <div className='bg'>
                                    <img src={require('../../public/bgPhoto1.png')} alt='' className='coverPhoto' />
                                </div>

                            </div>

                            <div className='profName profNamee'>
                                <div className='nkar'>
                                    <div className='profNkarr'>
                                        <img src={`${process.env.REACT_APP_SERVER_HOSTNAME}/${user?.avatar}`} alt='' />

                                    </div>

                                </div>
                                <div className='ynkerojAnun'>
                                    <h1>{user?.name} {user?.surname}</h1>
                                    <span>{user?.city}</span>
                                </div>
                            </div>
                        </div>
                        <div className='friendOptions'>

                            <div className='friendButtons'>
                                <Link to={`/chat`}><button><b>Написать сообщение</b></button></Link>
                                <button onClick={() => setInvite(true)}><b>Пригласить в группу</b></button>
                            </div>
                            <div className='writeRemove'>
                                <div className='writeMess' >
                                    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M12.833 17.4167C16.2898 17.4167 18.0186 17.4167 19.092 16.3423C20.1663 15.2689 20.1663 13.5401 20.1663 10.0833C20.1663 6.62658 20.1663 4.89775 19.092 3.82433C18.0186 2.75 16.2898 2.75 12.833 2.75H9.16634C5.70959 2.75 3.98076 2.75 2.90734 3.82433C1.83301 4.89775 1.83301 6.62658 1.83301 10.0833C1.83301 13.5401 1.83301 15.2689 2.90734 16.3423C3.50592 16.9418 4.30801 17.2068 5.49967 17.3232" stroke="#333333" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M12.8338 17.4141C11.7008 17.4141 10.4523 17.8724 9.31288 18.4636C7.48138 19.4142 6.56563 19.89 6.11463 19.5866C5.66363 19.2841 5.74888 18.3445 5.9203 16.4662L5.9588 16.0391" stroke="#333333" strokeWidth="1.2" strokeLinecap="round" />
                                    </svg>
                                </div>
                                <div className='removeFriend'
                                    onMouseLeave={() => {
                                        setSvgColor(false)
                                    }}
                                    onMouseOver={() => {
                                        setSvgColor(true)
                                    }} onClick={() => { setDeleteFriend(true) }}>
                                    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M9.625 9.625C9.85706 9.625 10.0796 9.71719 10.2437 9.88128C10.4078 10.0454 10.5 10.2679 10.5 10.5V21C10.5 21.2321 10.4078 21.4546 10.2437 21.6187C10.0796 21.7828 9.85706 21.875 9.625 21.875C9.39294 21.875 9.17038 21.7828 9.00628 21.6187C8.84219 21.4546 8.75 21.2321 8.75 21V10.5C8.75 10.2679 8.84219 10.0454 9.00628 9.88128C9.17038 9.71719 9.39294 9.625 9.625 9.625ZM14 9.625C14.2321 9.625 14.4546 9.71719 14.6187 9.88128C14.7828 10.0454 14.875 10.2679 14.875 10.5V21C14.875 21.2321 14.7828 21.4546 14.6187 21.6187C14.4546 21.7828 14.2321 21.875 14 21.875C13.7679 21.875 13.5454 21.7828 13.3813 21.6187C13.2172 21.4546 13.125 21.2321 13.125 21V10.5C13.125 10.2679 13.2172 10.0454 13.3813 9.88128C13.5454 9.71719 13.7679 9.625 14 9.625ZM19.25 10.5C19.25 10.2679 19.1578 10.0454 18.9937 9.88128C18.8296 9.71719 18.6071 9.625 18.375 9.625C18.1429 9.625 17.9204 9.71719 17.7563 9.88128C17.5922 10.0454 17.5 10.2679 17.5 10.5V21C17.5 21.2321 17.5922 21.4546 17.7563 21.6187C17.9204 21.7828 18.1429 21.875 18.375 21.875C18.6071 21.875 18.8296 21.7828 18.9937 21.6187C19.1578 21.4546 19.25 21.2321 19.25 21V10.5Z" fill={svgColor ? '#FFFFFF' : '#333333'} />
                                        <path fillRule="evenodd" clipRule="evenodd" d="M25.375 5.25C25.375 5.71413 25.1906 6.15925 24.8624 6.48744C24.5342 6.81563 24.0891 7 23.625 7H22.75V22.75C22.75 23.6783 22.3813 24.5685 21.7249 25.2249C21.0685 25.8813 20.1783 26.25 19.25 26.25H8.75C7.82174 26.25 6.9315 25.8813 6.27513 25.2249C5.61875 24.5685 5.25 23.6783 5.25 22.75V7H4.375C3.91087 7 3.46575 6.81563 3.13756 6.48744C2.80937 6.15925 2.625 5.71413 2.625 5.25V3.5C2.625 3.03587 2.80937 2.59075 3.13756 2.26256C3.46575 1.93437 3.91087 1.75 4.375 1.75H10.5C10.5 1.28587 10.6844 0.840752 11.0126 0.512563C11.3408 0.184374 11.7859 0 12.25 0L15.75 0C16.2141 0 16.6592 0.184374 16.9874 0.512563C17.3156 0.840752 17.5 1.28587 17.5 1.75H23.625C24.0891 1.75 24.5342 1.93437 24.8624 2.26256C25.1906 2.59075 25.375 3.03587 25.375 3.5V5.25ZM7.2065 7L7 7.10325V22.75C7 23.2141 7.18437 23.6592 7.51256 23.9874C7.84075 24.3156 8.28587 24.5 8.75 24.5H19.25C19.7141 24.5 20.1592 24.3156 20.4874 23.9874C20.8156 23.6592 21 23.2141 21 22.75V7.10325L20.7935 7H7.2065ZM4.375 5.25V3.5H23.625V5.25H4.375Z" fill={svgColor ? '#FFFFFF' : '#333333'} />
                                    </svg>

                                </div>
                            </div>

                        </div>
                        <div className='friendPosts'>
                            <UserPosts />
                        </div>
                    </>
                    :
                    <>
                        <div className='mecDivv'>
                            <div className='bgDiv'>
                                <div className='bg'>
                                    <img src={require('../../public/bgPhoto1.png')} alt='' className='coverPhoto' />
                                </div>

                            </div>

                            <div className='profName profNamee'>
                                <div className='nkar'>
                                    <div className='profNkarr'>
                                        <img src={`${process.env.REACT_APP_SERVER_HOSTNAME}/${user?.avatar}`} alt='' />

                                    </div>

                                </div>
                                <div className='ynkerojAnun'>
                                    <h1>{user?.name} {user?.surname}</h1>
                                    <span>{user?.city}</span>
                                </div>
                            </div>
                        </div>
                        <div className='friendOptions'>
                            <div className='friendButtons'>
                                <Link><button disabled onClick={() => dispatch(addFriend(userId))}><b>Добавить в друзья</b></button></Link>
                                <button><b>Написать сообщение</b></button>
                            </div>
                            <div className='writeRemove'>
                                <div className='writeMess' >
                                    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M12.833 17.4167C16.2898 17.4167 18.0186 17.4167 19.092 16.3423C20.1663 15.2689 20.1663 13.5401 20.1663 10.0833C20.1663 6.62658 20.1663 4.89775 19.092 3.82433C18.0186 2.75 16.2898 2.75 12.833 2.75H9.16634C5.70959 2.75 3.98076 2.75 2.90734 3.82433C1.83301 4.89775 1.83301 6.62658 1.83301 10.0833C1.83301 13.5401 1.83301 15.2689 2.90734 16.3423C3.50592 16.9418 4.30801 17.2068 5.49967 17.3232" stroke="#333333" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M12.8338 17.4141C11.7008 17.4141 10.4523 17.8724 9.31288 18.4636C7.48138 19.4142 6.56563 19.89 6.11463 19.5866C5.66363 19.2841 5.74888 18.3445 5.9203 16.4662L5.9588 16.0391" stroke="#333333" strokeWidth="1.2" strokeLinecap="round" />
                                    </svg>
                                </div>
                            </div>
                        </div>
                        <div className='friendPosts'>
                            <h1>Добавьте в друзья для просмотра публикаций</h1>
                        </div>
                    </>
            }

        </div>
    )
}
