import './style.css';
import { Link, useNavigate } from 'react-router-dom';

export const CallHistory = () => {
    const history = [
        {
            title: 'Патент',
            price: '15 000 руб.',
            date: '13.10.2022',
        },
        {
            title: 'Вид на жительство',
            price: '20 000 руб.',
            date: '03.10.2022',
        },
        {
            title: 'Сдача русского языка',
            price: '3 000 руб.',
            date: '22.09.2022',
        },
        {
            title: 'Патент',
            price: '15 000 руб.',
            date: '13.10.2022',
        },
        {
            title: 'Патент',
            price: '15 000 руб.',
            date: '13.10.2022',
        },
        {
            title: 'Патент',
            price: '15 000 руб.',
            date: '13.10.2022',
        },
        {
            title: 'Патент',
            price: '15 000 руб.',
            date: '13.10.2022',
        },
    ]

    return (
        <div>

            <div className='top'>
                <h1>История обращений</h1>
                <div className='serviceWhiteButtons'>
                    <Link to='/myDocuments'><button>Мои документы</button></Link>
                    <Link to='/services'><button>Услуги</button></Link>
                </div>
                <div className='mobileServiceBtns'>
                    <button onClick={() => window.location = '/myDocuments'}>
                        <svg width="20" height="26" viewBox="0 0 20 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M13.2168 1.00615L18.9711 7.13662M5.28541 12.3403H14.6857M5.28541 19.7655H14.6857M5.28541 16.0529H14.6857M2.12348 1.00615C1.82294 1.01402 1.5372 1.14677 1.32728 1.37605C1.11737 1.60532 0.999909 1.91295 1 2.23323V23.7729C0.999906 24.0931 1.11728 24.4006 1.32708 24.6299C1.53687 24.8592 1.82247 24.992 2.12291 25H17.8482C18.1537 25 18.4466 24.8707 18.6626 24.6406C18.8787 24.4105 19 24.0984 19 23.7729V7.13662H14.3923C14.0916 7.12874 13.8058 6.99591 13.5959 6.7665C13.3859 6.5371 13.2685 6.22931 13.2688 5.90892V1L2.12348 1.00615Z" stroke="black" />
                        </svg>
                    </button>
                    <button onClick={() => window.location = '/services'}>
                        <svg width="25" height="25" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clipPath="url(#clip0_516_2535)">
                                <path d="M1.66602 12.4987H4.99935C4.99935 12.957 5.37435 13.332 5.83268 13.332C6.29102 13.332 6.66602 12.957 6.66602 12.4987H13.3327C13.3327 12.957 13.7077 13.332 14.166 13.332C14.6243 13.332 14.9993 12.957 14.9993 12.4987H18.3327V14.9987C18.3327 15.9154 17.5827 16.6654 16.666 16.6654H3.33268C2.41602 16.6654 1.66602 15.9154 1.66602 14.9987V12.4987ZM1.66602 12.4987V11.6654M1.66602 11.6654V8.33203C1.66602 7.41536 2.41602 6.66536 3.33268 6.66536H5.83268V4.9987C5.83268 4.08203 6.58268 3.33203 7.49935 3.33203H12.4993C13.416 3.33203 14.166 4.08203 14.166 4.9987V6.66536H16.666C17.5827 6.66536 18.3327 7.41536 18.3327 8.33203V11.6654H14.9993V10.832C14.9993 10.3737 14.6243 9.9987 14.166 9.9987C13.7077 9.9987 13.3327 10.3737 13.3327 10.832V11.6654H6.66602V10.832C6.66602 10.3737 6.29102 9.9987 5.83268 9.9987C5.37435 9.9987 4.99935 10.3737 4.99935 10.832V11.6654H1.66602ZM1.99935 12.6654V11.332M12.4993 6.66536H7.49935V5.9987C7.49935 5.44641 7.94706 4.9987 8.49935 4.9987H11.4993C12.0516 4.9987 12.4993 5.44641 12.4993 5.9987V6.66536Z" stroke="#333333" />
                            </g>
                            <defs>
                                <clipPath id="clip0_516_2535">
                                    <rect width="20" height="20" fill="white" />
                                </clipPath>
                            </defs>
                        </svg>

                    </button>
                </div>
            </div>

            <hr className='horizontal' />

            <div className='transactionHistory'>
                {
                    history.map((e, i) => (
                        <div className='everyTransaction' key={i}>
                            <div className='transactionLeftSide'>
                                <span>{e.title}</span>
                                <h5>{e.price}</h5>
                                <h6>{e.date}</h6>
                            </div>
                            <div className='transactionRightSide'>
                                <span><b>{e.date}</b></span>
                                <h6><b>{e.price}</b></h6>
                            </div>
                        </div>
                    ))
                }
            </div>

        </div>
    )
}