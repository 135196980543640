import './style.css';
import { Outlet } from 'react-router-dom';
import { Sidebar } from '../../components/sidebar';
import { Navigation } from '../../components/navigation';
import { Footer } from '../../components/footer';
import { useDispatch, useSelector } from 'react-redux';
import { getUser } from '../../Redux/action/loginRegisterAction';
import { useEffect } from 'react';

export const Layout = () => {

    // const user = useSelector(st => st.loginRegister.user)
    
    // const dispatch = useDispatch();
    // useEffect(() => {
    //     dispatch(getUser());
    //     console.log('CURRENT USER --->>>', user)
    // }, [])

    return (
        <div className='layout'>
            <div className='topPage' >
                <Navigation />
                <div className='page'>
                    <Sidebar />
                    <div className='outlet'>
                        <hr className='hr'  />
                        <Outlet />
                    </div>
                </div>
            </div>
            <hr id='ccc'/>
            <Footer />
        </div>
    )
}
