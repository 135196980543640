import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { Layout } from './container/Layout'
import { Login } from './container/login'
import { Main } from './container/main'
import { Register } from './container/register'
// import { Restore } from './container/restore'
import { Wallet } from './container/wallet'
import { Photo } from './container/photo'
import { FriendsAndFamily } from './container/friends'
import { Chat } from './container/chat'
import { Services } from './container/services'
import { Settings } from './container/settings'
import { MyDocuments } from './container/myDocuments'
import { CallHistory } from './container/callHistory'
import { MyProfile } from './container/myProfile'
import { GroupSettings } from './container/groupSettings'
import { SingleGroup } from './container/singleGroup'
import { Event } from './container/event'
import { GroupMembers } from './container/groupMembers'
import { FriendsProfile } from './container/friend\'s profile'
import { Landing } from './container/landing'
import { ChatRightSide } from './components/chatRightSide'

export const MyRouter = () => {

    const token = localStorage.getItem('token');

    if (token) {
        return (
            <BrowserRouter>
                <Routes>
                    <Route path='/' element={<Layout />}>
                        <Route path='/' element={<Main />} />
                        <Route path='/photo' element={<Photo />} />
                        <Route path='/friends' element={<FriendsAndFamily />} />
                        <Route path='/chat' element={<Chat />} />
                        <Route path='/chat/:id' element={<ChatRightSide />} />
                        <Route path='/wallet' element={<Wallet />} />
                        <Route path='/services' element={<Services />} />
                        <Route path='/myDocuments' element={<MyDocuments />} />
                        <Route path='/callHistory' element={<CallHistory />} />
                        <Route path='/settings' element={<Settings />} />
                        <Route path='/myProfile' element={<MyProfile />} />
                        <Route path='/profile/:id' element={<FriendsProfile />} />
                        <Route path='/groupSettings' element={<GroupSettings />} />
                        <Route path='/group' element={<SingleGroup />} />
                        <Route path='/event' element={<Event />} />
                        <Route path='/groupMembers' element={<GroupMembers />} />
                    </Route>
                </Routes>
            </BrowserRouter>
        )
    } else {
        return (
            <BrowserRouter>
                <Routes>
                    <Route path='/' element={<Landing />} />
                    <Route path='/login' element={<Login />} />
                    <Route path='/register' element={<Register />} />
                    {/* <Route path='/restore' element={<Restore />} /> */}
                </Routes>
            </BrowserRouter>
        )
    }


}
