import './style.css'
import { EmailIcon, EmailShareButton, OKIcon, OKShareButton, TelegramIcon, TelegramShareButton, ViberIcon, ViberShareButton, VKIcon, VKShareButton, WhatsappIcon, WhatsappShareButton } from "react-share"

export const Share = () => {
    return (
        <div className='shareStyle'>
            <div className='eachShare' >
                <WhatsappShareButton
                    url={'https://vatanfront.justcode.am/'}
                    quote={'WP'}
                    style={{
                        background: 'none',
                        borderRadius: '8px',
                        width: '50px',
                        height: '50px',
                        margin: '8px',
                    }}
                >
                    <WhatsappIcon
                        size={32}
                        round
                    />
                </WhatsappShareButton>
                <span>Whatsapp</span>
            </div>

            <div className='eachShare'>
                <TelegramShareButton
                    url={'https://vatanfront.justcode.am/'}
                    quote={'TG'}
                    style={{
                        background: 'none',
                        borderRadius: '8px',
                        width: '50px',
                        height: '50px',
                        margin: '8px',
                    }}
                >
                    <TelegramIcon
                        size={32}
                        round
                    />
                </TelegramShareButton>
                <span>Telegram</span>
            </div>

            <div className='eachShare'>
                <ViberShareButton
                    url={'https://vatanfront.justcode.am/'}
                    quote='VB'
                    style={{
                        background: 'none',
                        borderRadius: '8px',
                        width: '50px',
                        height: '50px',
                        margin: '8px',
                    }}
                >
                    <ViberIcon
                        size={32}
                        round
                    />
                </ViberShareButton>
                <span>Viber</span>
            </div>

            <div className='eachShare'>
                <VKShareButton
                    url={'https://vatanfront.justcode.am/'}
                    quote='VK'
                    style={{
                        background: 'none',
                        borderRadius: '8px',
                        width: '50px',
                        height: '50px',
                        margin: '8px',
                    }}
                >
                    <VKIcon
                        size={32}
                        round
                    />
                </VKShareButton>
                <span>Вконтакте</span>
            </div>

            <div className='eachShare'>
                <OKShareButton
                    url={'https://vatanfront.justcode.am/'}
                    quote='OK'
                    style={{
                        background: 'none',
                        borderRadius: '8px',
                        width: '50px',
                        height: '50px',
                        margin: '8px',
                    }}
                >
                    <OKIcon
                        size={32}
                        round
                    />
                </OKShareButton>
                <span>Ok.ru</span>
            </div>

            <div className='eachShare'>
                <EmailShareButton
                    url={'https://vatanfront.justcode.am/'}
                    quote='Mail'
                    style={{
                        background: 'none',
                        borderRadius: '8px',
                        width: '50px',
                        height: '50px',
                        margin: '8px',
                    }}
                >
                    <EmailIcon
                        size={32}
                        round
                    />
                </EmailShareButton>
                <span>На почту</span>
            </div>

            <div className='showCopyURL'>
                <div>
                    <div className='copyShareURL'>
                        <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clipPath="url(#clip0_498_2070)">
                                <path d="M21.3056 5.05554H7.58333C7.29602 5.05554 7.02047 5.16968 6.8173 5.37284C6.61414 5.57601 6.5 5.85156 6.5 6.13888V23.4722C6.5 23.7595 6.61414 24.0351 6.8173 24.2382C7.02047 24.4414 7.29602 24.5555 7.58333 24.5555H21.3056C21.5929 24.5555 21.8684 24.4414 22.0716 24.2382C22.2748 24.0351 22.3889 23.7595 22.3889 23.4722V6.13888C22.3889 5.85156 22.2748 5.57601 22.0716 5.37284C21.8684 5.16968 21.5929 5.05554 21.3056 5.05554V5.05554ZM20.9444 23.1111H7.94444V6.49999H20.9444V23.1111Z" fill="#333333" />
                                <path d="M18.7776 2.52779C18.7776 2.24047 18.6634 1.96492 18.4603 1.76176C18.2571 1.55859 17.9815 1.44446 17.6942 1.44446H3.97201C3.68469 1.44446 3.40914 1.55859 3.20597 1.76176C3.00281 1.96492 2.88867 2.24047 2.88867 2.52779V19.8611C2.88867 20.1484 3.00281 20.424 3.20597 20.6272C3.40914 20.8303 3.68469 20.9445 3.97201 20.9445H4.33312V2.8889H18.7776V2.52779Z" fill="#333333" />
                            </g>
                            <defs>
                                <clipPath id="clip0_498_2070">
                                    <rect width="26" height="26" fill="white" />
                                </clipPath>
                            </defs>
                        </svg>
                    </div>
                </div>
                <span>Копировать</span>
            </div>

        </div >)
}