
import signUp from "../store/signUp";

export const myProfileReducer = (state = signUp, action) => {
    let temp = { ...state }
    switch (action.type) {
        case 'myProfileResponse':
            console.log('ACTION.PAYLOAD PTOFILE --->>>', action.payload)
            if (action.payload.status === true) {
                temp.user.myProfile = action.payload.data
            }
            break;

        default:
            break;
    }
    return temp;
}