import moment from "moment/moment";
import 'moment/locale/ru'
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { likeComments } from "../../Redux/action/mainAction";

export const MyComment = ({ comment }) => {

    const dispatch = useDispatch()
    const me = useSelector(st => st?.userProfileReducer?.randomUser?.randomUserProfile)
    const [likeComment, setlikeComment] = useState([])

    const onClickLike = (comment) => {
        let index = likeComment.findIndex((x) => x === comment.id);
        if (index >= 0) {
            likeComment.splice(index, 1);
        } else {
            likeComment.push(comment.id)
        };
        setlikeComment([...likeComment]);
        dispatch(likeComments(comment.id))
    };

    return (
        <div>
            <div className="eachComment" >
                <div className="commentTop">
                    <div className="commentPic" onClick={() => { window.location = (`/myProfile`) }}>
                        <img src={`${process.env.REACT_APP_SERVER_HOSTNAME}/${me?.avatar}`} alt={me?.name} />
                    </div>

                    <div className="mainComment">
                        <span onClick={() => { window.location = (`/myProfile`) }}>{me?.name}</span>
                        <p>{comment}</p>
                    </div>
                </div>

                <div className="likeComment">
                    <div className="likeLeftPart">
                        <span
                            className="kk"
                            onClick={onClickLike.bind(this, comment)}
                            style={
                                likeComment.some((x) => x === comment.id) ? { color: "#048E39" } : { color: "#787878" }
                            }
                        >Нравится</span>
                        <span className="kk">Ответить</span>
                        <span>{moment.utc(new Date()).local().startOf('seconds').fromNow()}</span>
                    </div>

                    {/* <div className="likeRightPart">
                        <span>Ответы</span>
                    </div> */}
                </div>
            </div>
        </div>
    )
}