import { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { Popup } from '../../components/popup'
import './style.css';

export const Photo = () => {

    const photos = [
        require('../../public/photo1.png'),
        require('../../public/photo2.png'),
        require('../../public/photo3.png'),
        require('../../public/photo4.png'),
        require('../../public/photo5.png'),
        require('../../public/photo6.png'),
        require('../../public/photo7.png'),
        require('../../public/photo8.png'),
        require('../../public/photo9.png'),
        require('../../public/photo10.png'),
        require('../../public/photo11.png'),
        require('../../public/photo12.png'),
    ]

    const videos = [
        require('../../public/nature.mp4'),
        require('../../public/nature.mp4'),
        require('../../public/nature.mp4'),
        require('../../public/nature.mp4'),
        require('../../public/nature.mp4'),
        require('../../public/nature.mp4'),
        require('../../public/nature.mp4'),
        require('../../public/nature.mp4'),
        require('../../public/nature.mp4'),
        require('../../public/nature.mp4'),
        require('../../public/nature.mp4'),
        require('../../public/nature.mp4'),
    ]

    const [moreIcons, setMoreIcons] = useState(null)
    const [share, setShare] = useState(false)
    const [shareGroup, setShareGroup] = useState(false)
    const [sharee, setSharee] = useState(false)

    document.body.onclick = (e) => {
        if (e.target.className !== 'moreIcon') {
            setMoreIcons(false)
        }
    }


    const [playing, setPlaying] = useState(false);
    const videoRef = useRef(null);

    const video = document.querySelectorAll('.v')

    function playVid() {
        video.play()
    }

    function pauseVid() {
        video.pause();
    }

    return (
        <div>
            <Popup
                sharePic
                showPopup={share}
                setShowPopup={setShare}
            />
            <Popup
                shareGroup
                showPopup={shareGroup}
                setShowPopup={setShareGroup}
            />
            <Popup
                title='Поделиться'
                share
                inputFirst=' '
                inputFirstValue='https://vatanfront.justcode.am/'
                showPopup={sharee}
                setShowPopup={setSharee}
            />
            <div className='allPhotos'>

                <div className="top">
                    <h1>Мои фото</h1>
                </div>
                <div className='mainDivs'>
                    {
                        photos.map((e, i) => (
                            <div className='eachPhoto' key={i}>
                                <img src={require('../../public/moreIcon.png')} alt='more' className='moreIcon' onClick={() => {
                                    if (moreIcons !== i) {
                                        setMoreIcons(i)
                                    } else {
                                        setMoreIcons(null)
                                    }
                                }} />
                                <img src={e} alt='Мои фото' className='ph' />
                                <div className={moreIcons === i ? 'moreActive' : 'moreDisabled'}>
                                    <ul className='ul'>
                                        <Link to='#'><li className='li' onClick={() => { setSharee(true) }}>Поделиться</li></Link>
                                        <Link to='#'><li className='li' onClick={() => setShare(true)}>Отправить другу</li></Link>
                                        <Link to='#'><li className='li' onClick={() => setShareGroup(true)}>Отправить в группу</li></Link>
                                    </ul>
                                </div>
                            </div>
                        ))
                    }
                </div>

            </div>

            <div className='allPhotos'>
                <div className="top">
                    <h1>Мои видео</h1>
                </div>
                <div className='mainDivs'>
                    {
                        videos.map((e, i) => (
                            <div className='eachPhoto' key={i}>
                                <img src={require('../../public/moreIcon.png')} alt='more' className='moreIcon' />
                                <video
                                    src={(e)}
                                    controls
                                    className='v'
                                />
                            </div>
                        ))
                    }
                </div>
            </div>
        </div>
    )
} 