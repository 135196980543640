import signUp from "../store/signUp";

export const groupsReducer = (state = signUp, action) => {
    let temp = { ...state }
    switch (action.type) {
        case 'getAllGroupsResponse':
            // console.log('ACTION.PAYLOAD Group --->>>', action.payload)
            temp.user.myGroups = action.payload
            // if(action.payload.success === true) {
            //     // temp.user.myGroups = action.payload.data
            // }
            break;

        case 'getAllRequest':
            // console.log('Action.Payload Requestss --->>>>>', action.payload)
            if (action.payload.success === true) {
                temp.user.groupsRequest = action.payload.data
            } 
        default:
            break;
    }
    return temp;
}