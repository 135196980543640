import { useState } from 'react'
import CustomizedSwitches from '../switcher'
import './style.css'

export const CreateGroup = () => {

    const [name, setName] = useState()

    return (
        <div className='crGr'> 

            <div className='grNkar'>
                <div className='groupNkar'>
                    <img src={require('../../public/createGroup.png')} alt='' />
                </div>
                <div className='editBg'>
                    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M17.875 11C17.875 10.8177 17.9474 10.6428 18.0764 10.5139C18.2053 10.3849 18.3802 10.3125 18.5625 10.3125C18.7448 10.3125 18.9197 10.3849 19.0486 10.5139C19.1776 10.6428 19.25 10.8177 19.25 11V18.5625C19.25 18.7448 19.1776 18.9197 19.0486 19.0486C18.9197 19.1776 18.7448 19.25 18.5625 19.25H3.4375C3.25516 19.25 3.0803 19.1776 2.95136 19.0486C2.82243 18.9197 2.75 18.7448 2.75 18.5625V3.4375C2.75 3.25516 2.82243 3.0803 2.95136 2.95136C3.0803 2.82243 3.25516 2.75 3.4375 2.75H11C11.1823 2.75 11.3572 2.82243 11.4861 2.95136C11.6151 3.0803 11.6875 3.25516 11.6875 3.4375C11.6875 3.61984 11.6151 3.7947 11.4861 3.92364C11.3572 4.05257 11.1823 4.125 11 4.125H4.125V17.875H17.875V11Z" fill="#048E39" />
                        <path d="M10.0966 11.908L11.231 11.7457L18.1995 4.77859C18.2651 4.71517 18.3175 4.63931 18.3535 4.55543C18.3896 4.47155 18.4085 4.38134 18.4093 4.29005C18.4101 4.19877 18.3927 4.10824 18.3582 4.02375C18.3236 3.93926 18.2725 3.8625 18.208 3.79794C18.1434 3.73339 18.0667 3.68234 17.9822 3.64778C17.8977 3.61321 17.8072 3.59581 17.7159 3.59661C17.6246 3.5974 17.5344 3.61636 17.4505 3.6524C17.3666 3.68843 17.2908 3.7408 17.2274 3.80647L10.2575 10.7736L10.0952 11.908H10.0966ZM19.1716 2.83297C19.3633 3.0245 19.5153 3.25193 19.619 3.50225C19.7228 3.75257 19.7762 4.02088 19.7762 4.29184C19.7762 4.5628 19.7228 4.83111 19.619 5.08143C19.5153 5.33175 19.3633 5.55918 19.1716 5.75072L12.0422 12.8801C11.9371 12.9856 11.8005 13.0541 11.6531 13.0753L9.38435 13.3998C9.27862 13.415 9.17081 13.4054 9.06946 13.3716C8.96811 13.3379 8.87602 13.281 8.80049 13.2055C8.72495 13.1299 8.66806 13.0378 8.63432 12.9365C8.60058 12.8351 8.59092 12.7273 8.6061 12.6216L8.9306 10.3528C8.95144 10.2056 9.01946 10.069 9.12448 9.96372L16.2552 2.83434C16.642 2.44768 17.1665 2.23047 17.7134 2.23047C18.2603 2.23047 18.7848 2.44768 19.1716 2.83434V2.83297Z" fill="#048E39" />
                    </svg>
                </div>
            </div>

            <div className='crRightPart'>
                <input placeholder='Название группы' className='input grInp' value={name} onChange={e => setName(e.target.value)} />

                <div className='grPr'>
                    <span>Доступность</span>
                    <div className='grSw s'>
                        <label>Открытая</label>
                        <CustomizedSwitches />
                    </div>
                </div>
            </div>
        </div>
    )
}