import signUp from "../store/signUp";

export const settingsReducer = (state = signUp, action) => {
    let temp = { ...state }
    switch (action.type) {

        case 'getUser':
            console.log('USER --->>>', action.payload);
            break;

        case 'addEmailAddress':
            console.log('PAYLOAD --->>>', action.payload)
            if (action.payload.success === true) {
                temp.settingsValidation.addEmail = false
            } else {
                temp.settingsValidation.addEmail = true
            }
            break;

        case 'confirmAddEmailAddress':
            console.log('PAYLOAD --->>>', action.payload)
            if (action.payload.success === true) {
                temp.settingsValidation.confirmEmailCode = false
            } else {
                temp.settingsValidation.confirmEmailCode = true
            }
            break;

        case 'newPhone':
            console.log('PAYLOAD --->>>', action.payload)
            if (action.payload.success === true) {
                temp.settingsValidation.addPhoneNumber = false
            } else {
                temp.settingsValidation.addPhoneNumber = true
            }
            break;

        case 'confirmNewPhone':
            console.log('PAYLOAD --->>>', action.payload)
            if (action.payload.success === true) {
                temp.settingsValidation.confirmAddPhone = false
            } else {
                temp.settingsValidation.confirmAddPhone = true
            }
            break;

        case 'editPhoneNumber':
            console.log('PAYLOAD --->>>', action.payload)
            if (action.payload.success === true) {
                temp.settingsValidation.editPhoneNumber = false
            } else {
                temp.settingsValidation.editPhoneNumber = true
            }
            break;

        case 'confirmEditPhoneNumber':
            console.log('PAYLOAD --->>>', action.payload)
            if (action.payload.success === true) {
                temp.settingsValidation.confirmEditPhone = false
            } else {
                temp.settingsValidation.confirmEditPhone = true
            }
            break;

        case 'editUsername':
            console.log('PAYLOAD --->>>', action.payload)
            if (action.payload.success === true) {
                temp.settingsValidation.changeUserName = false
            } else {
                temp.settingsValidation.changeUserName = true
            }
            break;

        case 'editPassword':
            console.log('PAYLOAD --->>>', action.payload)
            if (action.payload.success === true) {
                temp.settingsValidation.editPasswords = false
            } else {
                temp.settingsValidation.editPasswords = true
            }
            break;

        case 'editEmailAddress':
            console.log('PAYLOAD --->>>', action.payload)
            if (action.payload.success === true) {
                temp.settingsValidation.confirmEmailAddress = false
            } else {
                temp.settingsValidation.confirmEmailAddress = true
            }
            break;

        case 'confirmEditEmailAddress':
            console.log('PAYLOAD --->>>', action.payload)
            if (action.payload.success === true) {
                temp.settingsValidation.confirmEmailAddressCode = false
            } else {
                temp.settingsValidation.confirmEmailAddressCode = true
            }
            break;

        case 'hideProf':
            console.log('PAYLOAD --->>>', action.payload)
            if (action.payload.success === true) {
                temp.settingsValidation.hideProfile = false
            } else {
                temp.settingsValidation.hideProfile = true
            }
            break;

        case 'deleteProf':
            console.log('PAYLOAD --->>>', action.payload)
            if (action.payload.success === true) {
                temp.settingsValidation.deleteProfile = false
            } else {
                temp.settingsValidation.deleteProfile = true
            }
            break;

        default:
            break;
    }
    return temp;
} 
