import './style.css';
import Context from '../../components/context/context';
import { ChatRightSide } from '../../components/chatRightSide';
import { ChatLeftSide } from '../../components/chatLeftSide';
import { CircleLoader } from 'react-spinners'
import { useContext } from 'react';
import { useSelector } from 'react-redux';
import Pusher from 'pusher-js';


export const Chat = () => {

    const loading = useSelector(st => st.loginRegister.loading)
    const context = useContext(Context);
    const tablet = context.tablet

    if (!tablet && document.querySelector('.footer')) {
        document.querySelector('.footer').style.display = 'flex'
    }
    if (tablet && document.querySelector('.footer') && window.location.pathname.startsWith('/chat')) {
        document.querySelector('.footer').style.display = 'none'
    }
    if (!tablet && document.getElementById('ccc')) {
        document.getElementById('ccc').style.display = 'flex'
    }
    if (tablet && document.getElementById('ccc') && window.location.pathname.startsWith('/chat')) {
        document.getElementById('ccc').style.display = 'none'
    }

    let userId = localStorage.getItem('userId')
    const chatMemberId = useSelector(st => st.chatReducer.user.chatMemberId)

    const messageToMe = document.createElement('div')
    const pToMe = document.createElement('p')
    messageToMe.className = 'messageToMe'
    messageToMe.append(pToMe)

    Pusher.logToConsole = true;
    var pusher = new Pusher('82c41d8c09d510fed195', {
        cluster: 'ap2',
        encrypted: true

    });

    var channel = pusher.subscribe('chat');

    channel.bind("App\\Events\\ChatNotification", function (chaguch) {

        if (chaguch.chat.receiver_id === userId && chaguch.chat.sender_id === chatMemberId) {
            document.querySelector('.messages').append(messageToMe)
            pToMe.innerText = chaguch.chat.messages

        }

    });


    // if (loading) {
    //     return (
    //         <div className='loading'>
    //             <CircleLoader color="rgb(4, 142, 57)" />
    //         </div>
    //     )
    // } else {
    return (
        <div className='chatPage'>
            <ChatLeftSide />

            <div className='l' style={{ width: '100%' }}>
                <ChatRightSide />
            </div>

        </div>
    )
}
// }
